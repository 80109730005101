import { Injectable } from '@angular/core';
import { HttpProService } from './base/http-pro.service';
import { Observable } from 'rxjs';
import { of } from 'rxjs/observable/of';
import { ENDPOINTS } from 'app/variables/endpoints';
import { ExportExcelFilter } from 'app/shared/models/filters/report/export-excel-filter';

@Injectable()
export class ReportService {

    constructor(
        private httpProService: HttpProService
    ) { }

    public getInvoiceStatsMonthly(filters?: ExportExcelFilter): Observable<any> {
        const endpoint = ENDPOINTS.REPORT.INVOICE.GET_STATS_MONTHLY;
        const queries = this.httpProService.getQueryString({ filters });
        return this.httpProService.get(endpoint + '?' + queries, true);
    }

    public getInvoiceReport(filters?: ExportExcelFilter): Observable<any> {
        const endpoint = ENDPOINTS.REPORT.INVOICE.GET_REPORT;
        const queries = this.httpProService.getQueryString({ filters });
        return this.httpProService.get(endpoint + '?' + queries, true);
    }
}
