<div class="monthPickerSelector">
    <div class="card">
        <div class="card-header">
            <div class="d-flex align-items-center">

                <button class="btn mr-auto" (click)="decrement()">
                    <i class="fa fa-chevron-left"></i>
                </button>

                <button class="btn" (click)="toggleShowYears()">
                    {{model.selectedYearText + 543}}
                </button>

                <button class="btn ml-auto" (click)="increment()">
                    <i class="fa fa-chevron-right"></i>
                </button>

            </div>
        </div>

        <div class="card-body">
            <div class="monthPickerSelectorBody">

                <div class="row" *ngIf="!isShowYears">
                    <div class="col col-4" *ngFor="let month of model.months; let i = index">
                        <div class="monthPickerSelectorCell">
                            <button 
                            class="btn btn-block" 
                            [ngClass]="{'btn--orange': isSelectedMonth(i)}"
                            [disabled]="isDisabled(i)" 
                            (click)="selectMonth(i)">
                            <small>{{month}}</small>
                            </button>
                        </div>
                    </div>
                </div>


                <div class="row" *ngIf="isShowYears">
                    <div class="col col-4" *ngFor="let year of years; let i = index">
                        <div class="monthPickerSelectorCell">
                            <button 
                            class="btn btn-block" 
                            [ngClass]="{'btn--orange': isSelectedYear(year)}"
                            [disabled]="isDisabledYear(year)" 
                            (click)="selectYear(year)">
                            <small>{{year + 543}}</small>
                            </button>
                        </div>
                    </div>
                </div>

            </div>
        </div>

    </div>
    
    <div class="btnOptions">
        <button class="btn btn-primary" (click)="submit()">ยืนยัน</button>
        <button class="btn btn-grey" (click)="closeModal()">ยกเลิก</button>
    </div>
</div>