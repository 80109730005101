import { Deserializable } from './deserializable';

export class Airline implements Deserializable {

  public id: number;
  public code: string;
  public image_path: string;
  public name: string;

  public get dropdownOptionTH(): any {
    return {
      text: this.name + ' (' + this.code + ')',
      value: this.id,
      id: this.id,
      selected: false
    }
  }

  public deserialize(input: any): any {
    Object.assign(this, input);
    return this;
  }
}
