export const REGISTER_STATUS = {
    PENDING_APPROVE: 'pending_approve',
    AVAILABLE: 'available',
    REJECTED: 'rejected'
};

export const REGISTER_STATUS_LIST = [
    { value: 'รออนุมัติ', key: REGISTER_STATUS.PENDING_APPROVE, color: '#5e5e5f' },
    { value: 'เปิดใช้งาน', key: REGISTER_STATUS.AVAILABLE, color: '#3a9c25' },
    { value: 'ปิดใช้งาน', key: REGISTER_STATUS.REJECTED, color: '#b7b1b4' }
];
