import { Deserializable } from './deserializable';

export class Country implements Deserializable {

  public id: number;
  public name_th: string;
  public name_en: string;
  public image_path: string;
  public country_code: string;

  public get dropdownOptionTH(): any {
    return {
      text: this.name_th,
      value: this.id,
      id: this.id,
      selected: false
    }
  }

  public deserialize(input: any): any {
    Object.assign(this, input);
    return this;
  }
}
