import { Injectable } from '@angular/core';

import { ENDPOINTS } from 'app/variables/endpoints';

import { Observable } from 'rxjs/Observable';
import { HttpProService } from './base/http-pro.service';

@Injectable()
export class ProductActionService {

  constructor(
    private _httpProService: HttpProService  
  ) {}

  put(id: number, action: any): Observable<any> {
    let route = ENDPOINTS.PRODUCT.ACTIONS.PUT.replace('{product_id}', id).replace('{action}', action);
    return this._httpProService.put(route, {});
  }

}
