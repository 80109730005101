<div class="productSearchWrapper">
    <div class="searchLabel">
        <strong>ตัวช่วยค้นหา</strong>
        <span>{{settings?.searchLabelName}}</span>
    </div>
    <div class="searchOptions">
        <span class="moveLeft" (click)="arrowClick('left')" [ngClass]="{'hide': !isShowArrow, 'disabled': isLeftArrowDisable}">
            <i class="fa fa-chevron-left" aria-hidden="true"></i>
        </span>

        <ul #countriesBox>
            <li *ngFor="let country of countries">
                <button class="btn" [ngClass]="{'active': country.id === selectedCountry}" (click)="countryFilter($event, country)">{{country.name}} <i class="fa fa-caret-down" aria-hidden="true"></i></button>
            </li>
        </ul>

        <span class="moveRight" (click)="arrowClick('right')" [ngClass]="{'hide': !isShowArrow, 'disabled': isRightArrowDisable}">
            <i class="fa fa-chevron-right" aria-hidden="true"></i>
        </span>
    </div>

</div>

<div class="productSearchModalWrapper" #programsBox [hidden]="!isShowModal">
    <div class="outer">
        <div class="inner">
            <span *ngIf="isRequestingPrograms">Loading...</span>
            <ul>
                <li *ngFor="let program of programs"><span (click)="programFilter(program)">{{program.name}}</span></li>
            </ul>
        </div>
    </div>
</div>