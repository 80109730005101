import { Injectable } from '@angular/core';
import { HttpProService } from 'app/services/base/http-pro.service';
import { Observable } from 'rxjs';
import { ENDPOINTS } from 'app/variables/endpoints';

@Injectable()
export class RoomPassengerService {

  constructor(
    private http: HttpProService
  ) { }
  
  delete(periodId: number, roomId: number, passengerId: number): Observable<any> {
    return this.http.delete(ENDPOINTS.PROGRAM.PERIOD.ROOM.PASSENGER.DELETE.replace('{period_id}', periodId).replace('{room_id}', roomId).replace('{passenger_id}', passengerId));
  } 

}
