import { Injectable } from "@angular/core";
import { HttpService } from "app/services/base/http.service";
import { ENDPOINTS } from "app/variables/endpoints";

import { Observable } from "rxjs/Observable";

@Injectable()
export class TransportationService {
  constructor(private httpService: HttpService) {}

  getTransports(id): Observable<any> {
    let url = `${ENDPOINTS.TRANSPORTATIONS.GET_ALL}/${id}`;
    return this.httpService
      .get(url, null);
  }
}
