import { ENDPOINTS } from 'app/variables/endpoints';
import { HttpProService } from 'app/services/base/http-pro.service';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { Contact } from 'app/shared/models/agency-contact';
import { ResponseViewModel } from 'app/shared/models/viewmodels/base/response-viewmodel';
import { IApiResponse } from 'app/modules/agency/models/api-response.interface';
import { IAgencyAdminEdit } from 'app/modules/agency/models/agency-admin-edit.interface';

@Injectable()
export class AgencyService {
    constructor(private httpService: HttpProService) {}

    getAgency(agency_id): Observable<any> {
        let url = ENDPOINTS.AGENCY.GET.replace('{agency_id}', agency_id);
        return this.httpService.get(url, true);
    }

    addAgency(body): Observable<any> {
        let url = ENDPOINTS.AGENCY.POST;
        return this.httpService.post(url, body, true);
    }

    updateAgency(agency_id, body): Observable<any> {
        let url = ENDPOINTS.AGENCY.PUT.replace('{agency_id}', agency_id);
        return this.httpService.put(url, body, true);
    }

    public getAllAgencies(queries?): Observable<IApiResponse> {
        let url = '';
        if (queries) {
            let queryString = this.httpService.getQueryString(queries);
            url = ENDPOINTS.AGENCY.GET_ALL + '?' + queryString;
        } else {
            url = ENDPOINTS.AGENCY.GET_ALL;
        }

        return this.httpService.get(url, true);
    }

    getListing(queries): Observable<any> {
        let queryString = this.httpService.getQueryString(queries);
        let url = ENDPOINTS.AGENCY.GET_LISTING + '?' + queryString;
        return this.httpService.get(url, true);
    }

    getAgenciesByPeriods(period_ids): Observable<any> {
        let url = ENDPOINTS.AGENCY.GET_BY_PERIODS.replace('{period_ids}', period_ids);
        return this.httpService.get(url, true);
    }

    getListingAgencyMember(queries?: any): Observable<ResponseViewModel<any>> {
        let queryString = this.httpService.getQueryString(queries);
        let url = ENDPOINTS.AGENCY.MEMBER.GET_LISTING + '?' + queryString;
        return this.httpService.get(url, true);
    }

    postAgencyMember(agencyId: number, body: Contact): Observable<any> {
        let url = ENDPOINTS.AGENCY.MEMBER.POST.replace('{agency_id}', agencyId);
        return this.httpService.post(url, body, true);
    }

    putAgencyMember(id: number, agencyId: number, body: Contact): Observable<any> {
        let url = ENDPOINTS.AGENCY.MEMBER.PUT.replace('{agency_id}', agencyId).replace('{member_id}', id);
        return this.httpService.put(url, body, true);
    }

    postSignupToken(agencyId: number): Observable<any> {
        let url = ENDPOINTS.AGENCY.MEMBER.SIGNUP_TOKEN.POST.replace('{agency_id}', agencyId);
        return this.httpService.post(url, null, true);
    }

    public getAgenciesFromTWM(page: number = 1, pageEntries: number = 30): Observable<any> {
        let queryString = this.httpService.getQueryString({ page: page, page_entries: pageEntries });
        let url = ENDPOINTS.AGENCY.GET_ALL_TWM + '?' + queryString;
        return this.httpService.get(url, true);
    }

    public putAgencyAdmin(agencyId: number, memberId: number, payload: IAgencyAdminEdit): Observable<IApiResponse> {
        let url = ENDPOINTS.AGENCY.UDPATE_ADMIN.replace('{agency_id}', agencyId.toString()).replace(
            '{member_id}',
            memberId.toString()
        );
        return this.httpService.put(url, payload, true);
    }
}
