import { Injectable } from '@angular/core';
import { HttpProService } from 'app/services/base/http-pro.service';
import { Observable } from 'rxjs';
import { ENDPOINTS } from 'app/variables/endpoints';

@Injectable()
export class OrderPassengerService {

  constructor(
    private http: HttpProService
  ) { }

  getListing(filters?: any): Observable<any> {
    const queries = this.http.getQueryString(filters);
    return this.http.get(ENDPOINTS.ORDER.PASSENGER.GET_LISTING + '?' + queries);
  }

}
