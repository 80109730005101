import { Injectable } from '@angular/core';

@Injectable()
export class SharedUtil {
  public setParseInt(data: any): any {
      return (data ? parseInt(data) : null);
  }

  public setParseFloat(data: any): any {
      return (data ? parseFloat(data).toFixed(2) : null);
  }

  public removeEmpty(obj): any {
    return Object.keys(obj).filter(f => {

        return obj[f] != null;
    }).reduce((r, i) =>
            typeof obj[i] === 'object' ? 
            { ...r, [i]: this.removeEmpty(obj[i]) } : 
            { ...r, [i]: obj[i] }, {});
  }

}
