import { Injectable } from '@angular/core';
import { HttpProService } from 'app/services/base/http-pro.service';
import { Observable } from 'rxjs/Observable';
import { ENDPOINTS } from 'app/variables/endpoints';

@Injectable()
export class AgencyAvailableStatusService {

  constructor(
    private http: HttpProService
  ) { }

  put(agencyId: number, body: any): Observable<any> {
    return this.http.put(ENDPOINTS.AGENCY.AVAILABLE_STATUS.PUT.replace('{agency_id}', agencyId), body);
  }

}
