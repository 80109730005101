import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Component({
  selector: 'app-image-preview-popup',
  templateUrl: './image-preview-popup.component.html',
  styleUrls: ['./image-preview-popup.component.css']
})
export class ImagePreviewPopupComponent implements OnInit {

  public imageUrl: string;

  constructor(
    private bsModalRef: BsModalRef
  ) { }

  ngOnInit() {
  }

  closeModal(): void {
    this.bsModalRef.hide();
  }

}
