import { Injectable } from '@angular/core';
import { HttpService } from './base/http.service';
import { Observable } from 'rxjs/Observable';
import { ENDPOINTS } from 'app/variables/endpoints';

@Injectable()
export class CurrencyService {

    constructor(private httpService: HttpService) {}

    getCurrency(): Observable<any> {
      return this.httpService.get(ENDPOINTS.CURRENCY.GET_ALL);
    }
}
