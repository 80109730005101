import { AuthService } from 'app/services/auth.service';

export function setPrefixUrl(authService: AuthService): string {
    const userProfile = authService.getUserProfile();
    let page = document.location.href.split('/').slice(-1)[0];

    if (page == 'series-update' || page == '') {
        return '';
    }

    return '/' + (userProfile ? userProfile.supplier.product_twp_slug : '');
}
