import { Injectable } from '@angular/core';
import { HttpProService } from 'app/services/base/http-pro.service';
import { ENDPOINTS } from 'app/variables/endpoints';
import { Observable } from 'rxjs/Observable';

@Injectable()
export class PassengerService {

  constructor(
    private httpService: HttpProService
  ) { }

  getListing(filters?: any): Observable<any> {
    const queries = this.httpService.getQueryString(filters);
    return this.httpService.get(ENDPOINTS.PASSENGER.GET_LISTING + '?' + queries);
  }

  getAll(filters?: any): Observable<any> {
    const queries = this.httpService.getQueryString(filters);
    return this.httpService.get(ENDPOINTS.PASSENGER.GET_ALL + '?' + queries);
  }

  get(id: number): Observable<any> {
    return this.httpService.get(ENDPOINTS.PASSENGER.GET.replace('{passenger_id}', id));
  }

  post(body: any): Observable<any> {
    return this.httpService.post(ENDPOINTS.PASSENGER.POST, body);
  }

  put(id: number, body: any): Observable<any> {
    return this.httpService.put(ENDPOINTS.PASSENGER.PUT.replace('{passenger_id}', id), body);
  }

}
