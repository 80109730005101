import * as moment from 'moment';
import { NativeDateAdapter } from '@angular/material';


const thaiMonths = [
  'มกราคม',
  'กุมภาพันธ์',
  'มีนาคม',
  'เมษายน',
  'พฤษภาคม',
  'มิถุนายน',
  'กรกฎาคม',
  'สิงหาคม',
  'กันยายน',
  'ตุลาคม',
  'พฤศจิกายน',
  'ธันวาคม'
];


/**
 * Use for md-calendar custom format.
 * Example:
 * providers: [
 *      {provide: DateAdapter, useClass: ThaiDateAdapter},
 *      {provide: MD_DATE_FORMATS, useValue: {
 *        parse: {
 *            dateInput: { month: 'short', year: 'numeric', day: 'numeric' },
 *        },
 *        display: {
 *            dateInput: 'shortdate',
 *            monthYearLabel: { year: 'numeric', month: 'numeric' },
 *            dateA11yLabel: { year: 'numeric', month: 'long', day: 'numeric' },
 *            monthYearA11yLabel: { year: 'numeric', month: 'long' },
 *        }
 *      }}
 *    ]
 */
export class ThaiDateAdapter extends NativeDateAdapter {
  parse(value: any): Date | null {
    if (typeof value === 'string' && value.indexOf('/') > -1) {
      const str = value.split('/');

      const year = Number(str[2]) - 543;
      const month = Number(str[1]) - 1;
      const date = Number(str[0]);

      return new Date(year, month, date);
    }

    const timestamp = typeof value === 'number' ? value : Date.parse(value);
    return isNaN(timestamp) ? null : new Date(timestamp);
  }

  format(value: Date, displayFormat: Object): string {
    const date = moment(value);
    const thaiDate = date.add(543, 'y');

    if (!date.isValid()) {
      return '';
    }

    if (displayFormat === 'shortdate') {
      return value.getDate() + '/' + thaiDate.format('MM/YYYY');
    }

    const dateAsArray = thaiDate.format('DD/MM/YYYY').split('/');
    const monthIndex = dateAsArray['1'] - 1;
    const monthSelected = thaiMonths[monthIndex];

    dateAsArray[0] = value.getDate().toString();
    dateAsArray[1] = monthSelected;
    const result = dateAsArray.join(' ');
    return result;
  }
}
