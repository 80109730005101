import { Injectable } from '@angular/core';
import { HttpProService } from 'app/services/base/http-pro.service';
import { Observable } from 'rxjs/Observable';
import { ENDPOINTS } from 'app/variables/endpoints';

@Injectable()
export class OrderReportService {

  constructor(
    private http: HttpProService
  ) { }

  getAll(filters: any): Observable<any> {
    const queries = this.http.getQueryString(filters);
    return this.http.get(ENDPOINTS.ORDER.REPORT.GET_ALL + '?' + queries);
  } 

}
