import { AdditionProduct } from './addition-product';
import { AgencyContact } from './agency-contact';
import { AirlineTicketService } from './airline-ticket-service';
import { Deserializable } from './deserializable';
import { IncentiveTourProgram } from './incentive-tour-program';
import { JoinTourProgram } from './join-tour-program';
import { OtherProduct } from './other-product';
import { PRODUCT_CODE } from 'app/constants/product.constant';
import { PackageTourProgram } from './package-tour-program';
import { Product } from './product';
import { ServiceProduct } from './service-product';
import { User } from './user';

export class Order implements Deserializable {
    public id: number;
    public code: string;
    public customer?: AgencyContact;
    public sales?: User;
    public products: Product[];
    public created_at: Date;
    public created_by_email: string;
    public updated_at: Date;
    public updated_by: string;
    public note: string;

    public order_status_code: number;
    public order_status_key: string;
    public order_status_text: string;
    public order_status_reason: string;
    public ws_order_status_key: string;
    public ws_order_status_text: string;

    public summary_items?: any;
    public isUpdatedInvoice: boolean;
    public files: OrderFile[];
    public invoice_last_pdf?: any;

    public channel_slug: string;
    public channel_text: string;

    public has_example_period: boolean;
    public start_at: Date;
    public additional_request_note: string | null;

    public constructor() {
        this.products = [];
    }

    public get totalQuantity(): number {
        if (this.products === undefined) {
            return 0;
        }

        return this.products.reduce((p, c) => {
            if (c.status === 'delete') {
                return p;
            }

            return p + (c.totalQuantity || 0);
        }, 0);
    }

    public get numberOfPassenger(): number {
        if (this.products === undefined) {
            return 0;
        }

        return this.products.reduce((p, c) => {
            if (c.status === 'delete') {
                return p;
            }

            return p + (c.numberOfPassenger || 0);
        }, 0);
    }

    public get totalPrice(): number {
        if (this.products === undefined) {
            return 0;
        }

        return this.products.reduce((p, c) => {
            if (c.status === 'delete') {
                return p;
            }

            return p + (c.totalPrice || 0);
        }, 0);
    }

    public deserialize(input: any): any {
        Object.assign(this, input);
        this.customer = new AgencyContact().deserialize(this.customer);
        this.sales = new User().deserialize(this.sales);

        this.reDeserializeProduct();

        return this;
    }

    private reDeserializeProduct(): void {
        for (let index = 0; index < this.products.length; index++) {
            const product = this.products[index];

            if (
                product.pro_category_products_id === PRODUCT_CODE.JOIN_TOUR.ID &&
                product.pro_category_sub_products_id === PRODUCT_CODE.JOIN_TOUR.SUB
            ) {
                this.products[index] = new JoinTourProgram().deserialize(product);
            } else if (
                product.pro_category_products_id === PRODUCT_CODE.PACKAGE_TOUR.ID &&
                product.pro_category_sub_products_id === PRODUCT_CODE.PACKAGE_TOUR.SUB
            ) {
                this.products[index] = new PackageTourProgram().deserialize(product);
            } else if (
                product.pro_category_products_id === PRODUCT_CODE.INCENTIVE_TOUR.ID &&
                product.pro_category_sub_products_id === PRODUCT_CODE.INCENTIVE_TOUR.SUB
            ) {
                this.products[index] = new IncentiveTourProgram().deserialize(product);
            } else if (
                product.pro_category_products_id === PRODUCT_CODE.AIRLINE_TICKET.ID &&
                product.pro_category_sub_products_id === PRODUCT_CODE.AIRLINE_TICKET.SUB
            ) {
                this.products[index] = new AirlineTicketService().deserialize(product);
            } else if (product.pro_category_products_id === PRODUCT_CODE.SERVICE.ID) {
                this.products[index] = new ServiceProduct().deserialize(product);
            } else if (product.pro_category_products_id === PRODUCT_CODE.OTHER.ID) {
                this.products[index] = new OtherProduct().deserialize(product);
            } else if (product.pro_category_products_id === PRODUCT_CODE.ADDITION.ID) {
                this.products[index] = new AdditionProduct().deserialize(product);
            }
        }
    }
}

export class OrderFile implements Deserializable {
    public id: number;

    public deserialize(input: any): any {
        Object.assign(this, input);
        return this;
    }
}
