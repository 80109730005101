import 'hammerjs';

import { APP_ID, Inject, PLATFORM_ID } from '@angular/core';
import { Http, HttpModule } from '@angular/http';
import { PerfectScrollbarConfigInterface, PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { TranslateLoader, TranslateModule, TranslateStaticLoader } from 'ng2-translate/ng2-translate';

import { APP_BASE_HREF } from '@angular/common';
import { AngularMultiSelectModule } from 'angular2-multiselect-dropdown/angular2-multiselect-dropdown';
// components
import { AppComponent } from './app.component';
import { AuthModule } from 'app/modules/auth.module';
// services
import { AuthService } from './services/auth.service';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { CoreModule } from './core/core.module';
import { FeatureToggleService } from 'app/services/feature-toggle.service';

// adapters
import { ThaiDateAdapter } from 'app/adapters/custom-date.adapter';
import { DateAdapter } from '@angular/material';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule } from '@angular/forms';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { HttpErrorInterceptor } from 'app/core/interceptors/http-error.interceptor';
import { MainComponent } from 'app/theme/main/main.component';
import { MenuItems } from 'app/theme/core/menu/menu-items/menu-items';
// theme
import { MenuToggleModule } from 'app/theme/core/menu/menu-toggle.module';
import { NgModule } from '@angular/core';
/* ถูกใช้ที่ไหน? */
import { PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { PageTitleService } from 'app/theme/core/page-title/page-title.service';
import { PrebootModule } from 'preboot';
// modules
import { RoutingModule } from 'app/app-routing.module';
import { SharedModule } from 'app/modules/shared.module';
import { isPlatformBrowser } from '@angular/common';
import { setPrefixUrl } from 'app/utils/url.util';

import { SidenavComponent } from './theme/sidenav/sidenav.component';

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
    suppressScrollX: true,
};

export function createTranslateLoader(http: Http) {
    return new TranslateStaticLoader(http, 'assets/i18n', '.json');
}

@NgModule({
    declarations: [AppComponent, MainComponent, SidenavComponent],
    imports: [
        BrowserModule.withServerTransition({ appId: 'tourwow-pro' }),
        PrebootModule.withConfig({ appRoot: 'app-root' }),

        RoutingModule,
        BrowserAnimationsModule,
        FormsModule,
        AngularMultiSelectModule,
        FlexLayoutModule,
        PerfectScrollbarModule,
        MenuToggleModule,
        TranslateModule.forRoot({ provide: TranslateLoader, deps: [Http] }),

        CoreModule,
        SharedModule,
        AuthModule,
    ],
    providers: [
        MenuItems,
        PageTitleService,
        { provide: HTTP_INTERCEPTORS, useClass: HttpErrorInterceptor, multi: true },
        { provide: APP_BASE_HREF, useFactory: setPrefixUrl, deps: [AuthService] },
        { provide: DateAdapter, useClass: ThaiDateAdapter },
        {
            provide: PERFECT_SCROLLBAR_CONFIG,
            useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG,
        },
        FeatureToggleService,
    ],
    bootstrap: [AppComponent],
})
export class AppModule {
    constructor(@Inject(PLATFORM_ID) private platformId: Object, @Inject(APP_ID) private appId: string) {
        const platform = isPlatformBrowser(platformId) ? 'in the browser' : 'on the server';
        console.log(`Running ${platform} with appId=${appId}`);
    }
}
