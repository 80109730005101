import { Component, OnInit, ViewChild } from '@angular/core';
import { NotifyPopupComponent } from 'app/components/shared/popup/notify-popup/notify-popup.component';
import { AuthService } from 'app/services/auth.service';
import { MemberAuthService } from 'app/services/member-auth.service';
import { environment } from 'environments/environment';
import { FeatureToggleService } from 'app/services/feature-toggle.service';

@Component({
    selector: 'app-sidenav',
    templateUrl: './sidenav.component.html',
    styleUrls: ['./sidenav.component.css'],
})
export class SidenavComponent implements OnInit {
    public showTwmOrderMenu: boolean = false;

    constructor(
        private authService: AuthService,
        private memberAuthService: MemberAuthService,
        private featureToggleService: FeatureToggleService
    ) {}

    ngOnInit() {
        this.showTwmOrderMenu = this.featureToggleService.isFeatureEnabled('side_menu_twm_order');
    }

    public redirectToV1(url: string): string {
        const supplierSlug = this.authService.getUserProfile().supplier.product_twp_slug;
        return '/' + supplierSlug + '/' + url;
    }

    public redirectToV2(url: string): string {
        const supplierSlug = this.authService.getUserProfile().supplier.product_twp_slug;
        return environment.tourwowProBackofficeV2Url + supplierSlug + '/' + url;
    }

    logout(): void {
        this.memberAuthService.logout();
        location.href = environment.tourwowProBackofficeV2Url + 'logout';
    }
}
