import {Directive, ElementRef, Input} from '@angular/core';
import * as Inputmask from 'inputmask';

@Directive({
  selector: '[app-restrict-input]',
})
export class RestrictInputDirective {

  private regexMap = {
    integer: '^[0-9]*$',
    float: '^([0-9]*[.])?[0-9]+$',
    words: '([A-z]*\\s)*'
  };

  constructor(private el: ElementRef) {
  }

  @Input('app-restrict-input')
  public set defineInputType(type: string) {
    Inputmask({regex: this.regexMap[type], placeholder: ''})
      .mask(this.el.nativeElement);
  }

}
