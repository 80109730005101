export const ROLE = {
    GROUP: {
        OPT:[
            'owner',
            'director',
            'ghost',
            'sale_mngr',
            'sale_sr',
            'sale',
            'op'
        ],
        SALE: [
            'owner',
            'director',
            'ghost',
            'sale_mngr',
            'sale_sr',
            'sale',
        ],
        FINANCE: [
            'owner',
            'director',
            'ghost',
            'fin_mngr',
            'fin'
        ],
        MANAGER: [
            'owner',
            'director',
            'ghost',
            'fin_mngr',
            'sale_mngr'
        ],
        DIRECTOR: [
            'owner',
            'director',
            'ghost'
        ],
        ALL: [
            'owner',
            'director',
            'ghost',
            'fin_mngr',
            'fin',
            'sale_mngr',
            'sale_sr',
            'sale',
            'op'
        ]
    }
};