import { ENDPOINTS } from 'app/variables/endpoints';
import { HttpProService } from 'app/services/base/http-pro.service';
import { HttpService } from 'app/services/base/http.service';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { filter } from 'rxjs/operators';

@Injectable()
export class LocationService {
  constructor(
    private httpService: HttpService,
    private httpProService: HttpProService
  ) {}

  getAllCountries(queries?: any): Observable<any> {
    const queryString = this.httpService.getQueryString(queries);
    return this.httpService.get(ENDPOINTS.COUNTRY.GET_ALL + '?' + queryString, null);
  }

  getBySupplier(supplier: string, queries?: any): Observable<any> {
    const queryString = this.httpService.getQueryString(queries);
    return this.httpProService.get(
      ENDPOINTS.COUNTRY.SUPPLIER.GET_ALL.replace('{supplier_slug}', supplier) + '?' + queryString
    );
  }

  getBySupplierActive(supplier: string, queries?: any): Observable<any> {
    const queryString = this.httpService.getQueryString(queries);
    return this.httpProService.get(
      ENDPOINTS.COUNTRY.SUPPLIER.GET_ALL_ACTIVE.replace(
        '{supplier_slug}',
        supplier
      ) + '?' + queryString,
      false
    );
  }

  getBySupplierDraft(supplier: string, queries?: any): Observable<any> {
    const queryString = this.httpService.getQueryString(queries);
    return this.httpProService.get(
      ENDPOINTS.COUNTRY.SUPPLIER.GET_ALL_DRAFT.replace(
        '{supplier_slug}',
        supplier
      ) + '?' + queryString,
      false
    );
  }

  getCountriesBySupplier(supplierSlug: string, filters?: any): Observable<any> {
    const queryString = this.httpService.getQueryString(filters);
    return this.httpProService
               .get(ENDPOINTS.COUNTRY.SUPPLIER.V3.GET_ALL.replace('{supplier_slug}', supplierSlug) + '?' + queryString);
  }
}
