<div class="productSelectWrapper">
  <div class="productSelectHeader">
      <div class="pull-left">
        <span class="productSelectTitle">{{ title }}</span>
      </div>
  </div>
  <div class="productSelectButtons">
     <span *ngFor="let link of links">
         <a class="btn"
          *ngIf="link.url"
          routerLinkActive="active"
          [routerLink]="link.url">{{link.name}}</a>
     </span>
  </div>
</div>
