export const COMPANY_TYPE = {
    AGENCY: 1,
    NON_AGENCY: 2
};

export const COMPANY_TYPE_LIST = [
    { name: 'นิติบุคคล', value: COMPANY_TYPE.AGENCY },
    { name: 'บุคคลธรรมดา', value: COMPANY_TYPE.NON_AGENCY }
];

export const COMPANY_TYPE_DROPDOWN = [
    { name: 'ทั้งหมด', value: '' },
    { name: 'นิติบุคคล', value: COMPANY_TYPE.AGENCY },
    { name: 'บุคคลธรรมดา', value: COMPANY_TYPE.NON_AGENCY }
];
