import { Product, ProductType } from './product';

import { PRODUCT_CODE } from 'app/constants/product.constant';
import { HelperUtil } from 'app/utils/helper.util';

export class AdditionProduct extends Product {

  public id: number;
  public detail: string;
  public quantity: number;
  public price: number;
  public type: string;
  public is_delete: boolean;

  constructor() {
    super();
    this.id = null;
    this.pro_category_products_id = PRODUCT_CODE.ADDITION.ID;
    this.pro_category_sub_products_id = PRODUCT_CODE.ADDITION.SUB;
    this.detail = null;
    this.quantity = null;
    this.price = null;
    this.is_delete = false;

    this.product_type = new ProductType();
    this.product_type.id = 99;
    this.product_type.name = 'Addition Items';
    this.product_type.slug = 'addition';

    this.pro_category_products_id = 99;
  }

  public get totalQuantity(): number {
    return this.quantity;
  }

  public get numberOfPassenger(): number {
    return this.quantity;
  }

  public get totalPrice(): number {
    const rawValue = this.quantity * this.price;
    const price = Math.round(rawValue * 100) / 100;
    return price;
  }

  public serialize(): any {
    return {
      id: this.id,
      order_detail: HelperUtil.n2br(this.detail),
      quantity: this.quantity,
      price: this.price,
      is_delete: this.is_delete
    }
  }

  public deserialize(input: any): any {
    Object.assign(this, input);
    return this;
  }


}
