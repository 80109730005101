import { Injectable } from '@angular/core';

import { HttpService } from 'app/services/base/http.service';
import { ENDPOINTS } from 'app/variables/endpoints';

import { Observable } from 'rxjs/Observable';
import { HttpProService } from './base/http-pro.service';

@Injectable()
export class ProductService {

  constructor(
    private _httpService: HttpService,
    private _httpProService: HttpProService  
  ) {}

  getSubProducts(product): Observable<any> {
    let url = ENDPOINTS.SUB_PRODUCT.GET_ALL.replace('{product}', product);
    return this._httpService
      .get(url, true);
  }

  post(payload: any): Observable<any> {
    let route = ENDPOINTS.PRODUCT.POST;
    return this._httpProService.post(route, payload);
  }

  put(id: number, payload: any): Observable<any> {
    let route = ENDPOINTS.PRODUCT.PUT.replace('{product_id}', id);
    return this._httpProService.put(route, payload);
  }

  updateConfigChannel(body: any):  Observable<any> {
    const url = ENDPOINTS.PRODUCT.CONFIG_CHANNELS.PUT;
    return this._httpProService.put(url, body);
  }

}
