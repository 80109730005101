<div class="row justify-content-between full-wid force-header-toolbar">

    <div class="d-flex justify-content-start align-items-center left">
        <div class="force-ham-icon ml-2">
        <a (click)="sidenav.toggle()" class="cursor-pointer">
            <i class="fa fa-bars" aria-hidden="true"></i>
        </a>
        </div>
        <span class="navbar-brand">{{ displayText }}</span>
    </div>

    <div class="right">
        <div *ngIf="isShowCart" class="cartDetail d-flex justify-content-start align-items-center">
            <span>
                <i class="cart-icon fa fa-shopping-basket" aria-hidden="true"></i>
            </span>
            <span>
                <span class="cart-title">ตะกร้าสินค้า</span>
            </span>
            <span>
                <a [routerLink]="['/sale/cart']" class="text-link">มีสินค้า {{ productQuantity }} ชิ้น</a>
            </span>
        </div>
    </div>

</div>
