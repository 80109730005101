import { Injectable } from '@angular/core';
import { HttpProService } from 'app/services/base/http-pro.service';
import { Observable } from 'rxjs';
import { ENDPOINTS } from 'app/variables/endpoints';

@Injectable()
export class OrderSendEmailService {

  constructor(
    private http: HttpProService
  ) { }

  post(orderId: any, body?: any): Observable<any> {
    return this.http.post(ENDPOINTS.ORDER.SEND_EMAIL.POST.replace('{order_id}', orderId), body);
  }

}
