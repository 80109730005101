import { Component, Input, OnInit, EventEmitter, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-refund-payment-order-modal',
  templateUrl: './refund-payment-order-modal.component.html',
  styleUrls: ['./refund-payment-order-modal.component.css']
})
export class RefundPaymentOrderModalComponent implements OnInit {

  refundPaymentOrderForm: FormGroup;

  public title: string;
  
  @Output() afterCloseModal = new EventEmitter();

  constructor(
    private fb: FormBuilder,
    private bsModalRef: BsModalRef
  ) { }

  ngOnInit() {
    this.refundPaymentOrderForm = this.fb.group({
      total: ['', [Validators.required, Validators.min(1)]],
      reason: ['', [Validators.required, Validators.max(500)]]
    });
  }

  submit(): void {
    if (this.refundPaymentOrderForm.invalid)
      return;

    const payload = {
      refunded_total: this.refundPaymentOrderForm.controls['total'].value,
      refunded_reason: this.refundPaymentOrderForm.controls['reason'].value,
    }
    this.afterCloseModal.emit(payload);
    this.closeModal();
  }

  closeModal(): void {
    this.bsModalRef.hide();
  }

}
