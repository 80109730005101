import { Injectable } from '@angular/core';
import { Feature_TOGGLE } from 'environments/environment';

@Injectable()
export class FeatureToggleService {

    constructor() {}

    public isFeatureEnabled(feautureName): boolean {
        return Feature_TOGGLE[feautureName];
    }
}
