import * as moment from 'moment';

import { Deserializable } from './deserializable';

export class Period implements Deserializable {

  public id: number;
  public deposit: number;
  public start_at: Date;
  public end_at: Date;
  public tour_code: string;

  public commission_company?: number;
  public commission_seller?: number;

  public quantity_remain?: number;

  private thaiMonthsAbbreviation = ['ม.ค.', 'ก.พ.', 'มี.ค.', 'เม.ย.', 'พ.ค.', 'มิ.ย.', 'ก.ค.', 'ส.ค.', 'ก.ย.', 'ต.ค.', 'พ.ย.', 'ธ.ค.'];

  public get periodDate(): string {
    let result = '';
    const date1Obj = moment(this.start_at);
    const date2Obj = moment(this.end_at);
    if(!date1Obj.isValid() || !date2Obj.isValid()) {
      return result;
    }

    if(date1Obj.date() === date2Obj.date() &&  date1Obj.month() === date2Obj.month() && date1Obj.year() === date2Obj.year()) {
      result = `${date2Obj.format('DD')} ${this.thaiMonthsAbbreviation[date1Obj.month()]} ${this.convertToBuddhistYear(date1Obj)}`;
    } else if(date1Obj.month() === date2Obj.month() && date1Obj.year() === date2Obj.year()) {
      result = `${date1Obj.format('DD')} - ${date2Obj.format('DD')} ${this.thaiMonthsAbbreviation[date1Obj.month()]} ${this.convertToBuddhistYear(date1Obj)}`;
    } else if (date1Obj.month() !== date2Obj.month() && date1Obj.year() !== date2Obj.year()) {
      result = `${date1Obj.format('DD')} ${this.thaiMonthsAbbreviation[date1Obj.month()]} ${this.convertToBuddhistYear(date1Obj)} - ${date2Obj.format('DD')} ${this.thaiMonthsAbbreviation[date2Obj.month()]} ${this.convertToBuddhistYear(date2Obj)}`;
    } else {
      result = `${date1Obj.format('DD')} ${this.thaiMonthsAbbreviation[date1Obj.month()]} - ${date2Obj.format('DD')} ${this.thaiMonthsAbbreviation[date2Obj.month()]} ${this.convertToBuddhistYear(date2Obj)}`;
    }
    return result;
  }

  public deserialize(input: any): any {
    Object.assign(this, input);
    return this;
  }

  private convertToBuddhistYear(moment: moment.Moment): string {
    return (+moment.format('YYYY') + 543).toString().slice(2);
  }
}
