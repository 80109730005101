import { Injectable } from '@angular/core';
import { HttpProService } from 'app/services/base/http-pro.service';
import { Observable } from 'rxjs';
import { ENDPOINTS_PAYMENT_v3 } from 'app/variables/endpoints-order';

@Injectable()
export class PaymentService {
    constructor(private http: HttpProService) {}

    getPaymentInfo(orderId:  number): Observable<any> {
        return this.http.get(ENDPOINTS_PAYMENT_v3.GET_INFO.replace('{order_id}', orderId));
    }

    getPaymentList(filters?: any): Observable<any> {
        const queries = this.http.getQueryString(filters);
        return this.http.get(ENDPOINTS_PAYMENT_v3.GET_LIST + '?' + queries);
    }

    getTransectionListing(filters?: any): Observable<any> {
        const queries = this.http.getQueryString(filters);
        return this.http.get(ENDPOINTS_PAYMENT_v3.TRANSACTION.LISTING + '?' + queries);
    }

    createPaymentTransaction(orderId,body): Observable<any>{
        return this.http.post(ENDPOINTS_PAYMENT_v3.TRANSACTION.POST.replace('{order_id}', orderId),body);
    }

    deletePaymentTransaction(orderId,paymentId){
        return this.http.delete(ENDPOINTS_PAYMENT_v3.TRANSACTION.DELETE.replace('{order_id}', orderId).replace('{payment_id}', paymentId));
    }


    updatePaymentActionToWaitingForPayment(orderId) {
        return this.http.put(ENDPOINTS_PAYMENT_v3.TRANSACTION.ACTION.WAITING_FOR_PAYMENT.replace('{order_id}', orderId),{});
    }

    updatePaymentActionToWaitingForConfirmationDeposit(orderId) {
        return this.http.put(ENDPOINTS_PAYMENT_v3.TRANSACTION.ACTION.WAITING_FOR_CONFIRMATION_DEPOSIT.replace('{order_id}', orderId),{});
    }

    approvePaymentPaid(orderId){
        return this.http.put(ENDPOINTS_PAYMENT_v3.TRANSACTION.ACTION.PAID.replace('{order_id}', orderId),{});
    }

    approvePaymentPaidFull(orderId){
        return this.http.put(ENDPOINTS_PAYMENT_v3.TRANSACTION.ACTION.PAID_FULL.replace('{order_id}', orderId),{});
    }


    approvePaymentTransaction(orderId,paymentId){
        return this.http.put(ENDPOINTS_PAYMENT_v3.TRANSACTION.APPROVE.replace('{order_id}', orderId).replace('{payment_id}', paymentId),{});
    }

    pendingPaymentTransaction(orderId,paymentId){
        return this.http.put(ENDPOINTS_PAYMENT_v3.TRANSACTION.PENDING.replace('{order_id}', orderId).replace('{payment_id}', paymentId),{});
    }
}
