import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from 'app/modules/shared.module';

import { AuthService } from 'app/services/auth.service';
import { AuthGuardService } from 'app/services/auth-guard.service';
import { MemberAuthService } from 'app/services/member-auth.service';

import { UnauthorizedPageComponent } from 'app/components/auth/unauthorized-page/unauthorized-page.component';
import { PageNotFoundComponent } from 'app/components/auth/page-not-found/page-not-found.component';
import { ServerErrorPageComponent } from 'app/components/auth/server-error-page/server-error-page.component';

@NgModule({
    imports: [CommonModule, SharedModule],
    declarations: [UnauthorizedPageComponent, PageNotFoundComponent, ServerErrorPageComponent],
    providers: [AuthService, AuthGuardService, MemberAuthService],
})
export class AuthModule {}
