<md-nav-list menuToggleDirective class="navigation">
    <md-list-item menuToggleLink>
        <a class="force-relative" [href]="redirectToV2('dashboard')" menuToggle routerLinkActive="active-link">
            <i><img src="assets/img/icon-dashboard-white.png" style="width: 1rem" /></i>
            <span>หน้าแรก</span>
        </a>
    </md-list-item>
    <md-list-item menuToggleLink>
        <a class="force-relative" [href]="redirectToV2('product/management')" menuToggle routerLinkActive="active-link">
            <i class="fa fa-suitcase"></i>
            <span>จัดการข้อมูลสินค้า</span>
        </a>
    </md-list-item>
    <md-list-item menuToggleLink>
        <a [href]="redirectToV1('agency/send-link-register')" class="force-relative" menuToggle>
            <i class="fa fa-address-card"></i>
            <span>ส่ง Link สมัคร Online Booking</span>
        </a>
    </md-list-item>
    <md-list-item menuToggleLink>
        <a menuToggle class="force-relative">
            <i class="fa fa-handshake-o"></i>
            <span>งานขาย</span>
            <md-icon class="menu-caret"> chevron_right </md-icon>
        </a>
        <md-nav-list class="sub-menu">
            <md-list-item routerLinkActive="open">
                <a [routerLink]="['/order/new-list']">Order จาก Online Booking</a>
            </md-list-item>
            <md-list-item routerLinkActive="open">
                <a [routerLink]="['/order/overdue-list']">Order เกินกำหนดชำระเงิน</a>
            </md-list-item>
            <md-list-item routerLinkActive="open" *ngIf="showTwmOrderMenu">
                <a [href]="redirectToV2('/order/program-list')">Order จาก Tourwowmall</a>
            </md-list-item>
        </md-nav-list>
    </md-list-item>
    <md-list-item menuToggleLink>
        <a menuToggle class="force-relative">
            <i class="fa fa-money"></i>
            <span>งานบัญชี-การเงิน</span>
            <md-icon class="menu-caret"> chevron_right </md-icon>
        </a>
        <md-nav-list class="sub-menu">
            <md-list-item routerLinkActive="open">
                <a [routerLink]="['/order/payment-list']">ข้อมูลงวดชำระเงิน Online Booking</a>
            </md-list-item>
            <md-list-item routerLinkActive="open">
                <a [routerLink]="['/order/transaction-list']">ข้อมูลแจ้งชำระเงิน Online Booking</a>
            </md-list-item>
        </md-nav-list>
    </md-list-item>
    <md-list-item menuToggleLink>
        <a menuToggle class="force-relative">
            <i class="fa fa-users"></i>
            <span>ข้อมูล Agency</span>
            <md-icon class="menu-caret"> chevron_right </md-icon>
        </a>
        <md-nav-list class="sub-menu">
            <md-list-item routerLinkActive="open">
                <a [routerLink]="['/agency/list']">Agency ทั้งหมด</a>
                <a [routerLink]="['/agency/member/list']">สมาชิก Online Booking</a>
            </md-list-item>
        </md-nav-list>
    </md-list-item>
    <md-list-item menuToggleLink>
        <a menuToggle class="force-relative">
            <i class="fa fa-table"></i>
            <span>ดาวน์โหลดข้อมูล</span>
            <md-icon class="menu-caret"> chevron_right </md-icon>
        </a>
        <md-nav-list class="sub-menu">
            <md-list-item routerLinkActive="open">
                <a [routerLink]="['/report/order/download']">ข้อมูล Order</a>
                <a [routerLink]="['/report/transaction/download']">ข้อมูลการชำระเงิน</a>
            </md-list-item>
        </md-nav-list>
    </md-list-item>
    <md-divider></md-divider>
    <md-list-item>
        <a (click)="logout()" class="force-relative" md-ripple>
            <i class="fa fa-sign-out" aria-hidden="true"></i>
            <span>ออกจากระบบ</span>
        </a>
    </md-list-item>
</md-nav-list>
