import { Injectable } from "@angular/core";
import { HttpService } from "app/services/base/http.service";
import { ENDPOINTS } from "app/variables/endpoints";

import { Observable } from "rxjs/Observable";

@Injectable()
export class CaterogyTransportService {
  constructor(private httpService: HttpService) {}

  getTransports(filter): Observable<any> {
    let filterString = JSON.stringify(filter);
    let url = `${ENDPOINTS.CATEGORY_TRANSPORT.GET_ALL}?filters=${filterString}`;
    return this.httpService
      .get(url, null);
  }
}
