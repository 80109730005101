import { AgencyContact } from 'app/shared/models/agency-contact';
import { Injectable } from '@angular/core';
import { JoinTourProgram } from 'app/shared/models/join-tour-program';
import { Order } from 'app/shared/models/order';
import { PRODUCT_CODE } from 'app/constants/product.constant';
import { Product } from 'app/shared/models/product';
import { User } from 'app/shared/models/user';

@Injectable()
export class CartService {

  private order: Order;
  private cartKeyLocalStorage = 'cart';
  private cartOrderKey = 'cart_order';
  private salesKey = 'cart_sales';
  private agencyContactSelectedKeyLocalStorage = 'cart_agency_contact';

  constructor() {
    if (this.order === undefined) {
      this.order = new Order();
    }
  }

  public addProduct(product: Product, updateItem: boolean = false): boolean {
    if (this.order.products === undefined) {
      this.order.products = [];
    }

    if (this.order.products.length > 0 && updateItem) {
      const productInCart: Product = this.findProductInCart(product);
      if (productInCart !== null) {

        switch (productInCart.product_type.slug) {
          case 'join':
            const joinTourProduct = productInCart as JoinTourProgram;
            const newProduct = product as JoinTourProgram;
            Object.assign(joinTourProduct.skus, newProduct.skus);
            break;
        }

      } else {
        this.order.products.push(product);
      }
    } else {
      this.order.products.push(product);
    }

    this.save();
    return true;
  }

  public removeProduct(product: Product): boolean {

    if (product.status === 'edit') {
      const productInCart = this.order.products.find(elm => elm === product);
      productInCart.status = 'delete';
    } else {
      this.order.products.splice(this.order.products.indexOf(product), 1);
    }

    this.save();

    return true;
  }

  public clearCart(): void {
    this.order = new Order();
    localStorage.removeItem(this.cartKeyLocalStorage);
    localStorage.removeItem(this.cartOrderKey);
    localStorage.removeItem(this.salesKey);
    localStorage.removeItem(this.agencyContactSelectedKeyLocalStorage);
  }

  public getOrder(): Order {
    this.load();
    return this.order;
  }

  public setSelectedAgencyContact(selectedAgencyContact: AgencyContact): void {
    localStorage.setItem(this.agencyContactSelectedKeyLocalStorage, JSON.stringify(selectedAgencyContact));
  }

  public getSelectedAgencyContact(): AgencyContact {
    const agencyContactString = JSON.parse(localStorage.getItem(this.agencyContactSelectedKeyLocalStorage));
    if (agencyContactString === null) {
      return null;
    }

    return new AgencyContact().deserialize(agencyContactString);
  }

  public setSelectedSales(selectedSales: User): void {
    localStorage.setItem(this.salesKey, JSON.stringify(selectedSales));
  }

  public getSelectedSales(): User {
    const sales = JSON.parse(localStorage.getItem(this.salesKey));
    if (sales === null) {
      return null;
    }

    return new User().deserialize(sales);
  }

  public setCartOrder(cartOrder: any): void {
    localStorage.setItem(this.cartOrderKey, JSON.stringify(cartOrder));
  }

  public getCartOrder(): any {
    const cartOrder = JSON.parse(localStorage.getItem(this.cartOrderKey));
    if (cartOrder === null) {
      return null;
    }

    return cartOrder;
  }

  public get totalQuantity(): number {
    this.load();
    return this.order.totalQuantity;
  }

  private findProductInCart(product: Product): Product {
    const productInCart = null;
    if (product.pro_category_products_id === PRODUCT_CODE.JOIN_TOUR.ID
        && product.pro_category_sub_products_id === PRODUCT_CODE.JOIN_TOUR.SUB) {
      for (let i = 0; i < this.order.products.length; i++) {
        // Find product JoinTourProgram.
        if (this.order.products[i].pro_category_products_id === PRODUCT_CODE.JOIN_TOUR.ID
            && this.order.products[i].pro_category_sub_products_id === PRODUCT_CODE.JOIN_TOUR.SUB) {
          // Casting for check period id.
          const oldJoinTour = this.order.products[i] as JoinTourProgram;
          const newJoinTour = product as JoinTourProgram;
          // Compare period id.
          if (oldJoinTour.period.id === newJoinTour.period.id && oldJoinTour.status !== 'edit') {
            return oldJoinTour;
          }
        }
      }
    }
    return productInCart;
  }

  private save(): void {
    localStorage.setItem(this.cartKeyLocalStorage, JSON.stringify(this.order));
  }

  private load(): void {
    const orderString = localStorage.getItem(this.cartKeyLocalStorage);
    if (orderString === null) {
      this.order = new Order();
      return;
    }

    this.order = new Order().deserialize(JSON.parse(orderString));
  }

}
