import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router, CanActivateChild } from '@angular/router';
import { Observable } from 'rxjs/Observable';
import { AuthService } from 'app/services/auth.service';
import { tap } from 'rxjs/operators';

@Injectable()
export class AuthGuardService implements CanActivate, CanActivateChild {
    constructor(private router: Router, private authService: AuthService) {}

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        const isAuthen = this.authService.isAuthenticated();
        if (isAuthen) {
            return true;
        }

        return false;
    }

    canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        let hasAuthorized = this.authService.hasAuthorized(childRoute.data.expectedRole);
        if (!hasAuthorized) this.router.navigate(['/401']);

        return this.canActivate(childRoute, state);
    }
}
