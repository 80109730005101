import { Deserializable } from './deserializable';

export class User implements Deserializable {

  public id: number;
  public first_name?: string;
  public last_name?: string;
  public nick_name?: string;
  public mobile?: string;
  public email?: string;

  public name?: string;

  public get fullname(): string {
    const nickName = this.nick_name ? `(${this.nick_name})` : '';
    if (this.name !== undefined) {
      return this.name;
    }

    return `${this.first_name || ''} ${this.last_name || ''} ${nickName}`;
  }

  public deserialize(input: any): any {
    Object.assign(this, input);
    return this;
  }
}
