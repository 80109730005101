import { ENDPOINTS_ORDER_v3, ENDPOINTS_PAYMENT_v3 } from 'app/variables/endpoints-order';

export const ENDPOINTS: any = {
    AUTH: {
        LOGIN: 'professional/auth/login',
    },
    SUPPLIER: {
        INFO: 'professional/{supplier_slug}/info',
        GET_CONFIG_ORDER: 'professional/suppliers/configs/orders',
    },
    MEMBER: {
        TOKEN: {
            POST: 'professional/token',
            DELETE: 'token',
        },
        GET_ALL: 'professional/members',
    },
    COUNTRY: {
        GET_ALL: 'countries',
        GET_REGIONS: 'countries/{country_id}/regions',
        GET_PROVINCES: 'regions/{region_id}/provinces',
        GET_AREAS: 'provinces/{province_id}/areas',
        GET_SUB_AREAS: 'areas/{area_id}/sub_areas',
        GET_ADDRESS: 'address/{postal_code_id}',
        SUPPLIER: {
            GET_ALL: 'professional/{supplier_slug}/countries',
            GET_ALL_ACTIVE: 'professional/{supplier_slug}/countries/active',
            GET_ALL_DRAFT: 'professional/{supplier_slug}/countries/draft',
            V3: {
                GET_ALL: 'professional/{supplier_slug}/countries/products',
            },
        },
    },
    CURRENCY: {
        GET_ALL: 'currencies',
    },
    TRANSPORT: {
        GET_ALL: 'category_transports',
    },
    TRANSPORTATIONS: {
        GET_ALL: 'transportations',
    },
    CATEGORY_TRANSPORT: {
        GET_ALL: 'category_transports',
    },
    PRODUCT: {
        POST: 'v3/professional/products',
        PUT: 'v3/professional/products/{product_id}',
        ACTIONS: {
            PUT: 'v3/professional/products/{product_id}/actions/{action}',
        },
        CONFIG_CHANNELS: {
            PUT: 'v3/professional/products/config_channels',
        },
    },
    SUB_PRODUCT: {
        GET_ALL: 'team/products/{product}/sub_products',
    },
    PROGRAM: {
        LIST: 'v2/professional/programs/listing',
        DRAFT_LIST: 'v2/professional/programs/draft/listing',
        ACTIVE_LIST: 'v2/professional/programs/active/listing',
        REPORT: 'v2/professional/programs/report',
        GET_DETAIL: 'professional/programs/{program_id}/detail',
        GET_ALL: 'professional/programs',
        GET_ALL_DRAFT: 'v2/professional/programs/draft',
        GET_ALL_ACTIVE: 'v2/professional/programs/active',
        GET_ALL_ACTIVE_NO_AUTH: '/professional/{supplier_slug}/programs',
        GET: 'professional/programs/{program_id}',
        POST: 'professional/programs',
        PUT: 'professional/programs/{program_id}',
        DELETE: 'v2/professional/programs/{program_id}',
        UPDATE_SELL_STATUS: 'professional/programs/{program_id}/sell_status',
        SUPPLIER: {
            GET_ALL: 'professional/{supplier_slug}/programs',
            LIST: 'v2/professional/{supplier_slug}/programs/listing',
        },
        V3: {
            GET_PRODUCT_LIST: 'v3/professional/products/listing',
            GET_PRODUCT_DETAIL: 'v3/professional/products/{product_id}',
            GET_PRODUCT_ALL: 'v3/professional/products',
        },
        PERIOD: {
            GET_ALL: 'professional/programs/{program_id}/periods',
            GET_LAST: 'professional/programs/{program_id}/periods/last_id',
            GET_DETAIL: 'professional/programs/{program_id}/periods/{period_id}/detail',
            GET: 'professional/programs/{program_id}/periods/{period_id}',
            GET_OFFICERS_ALL: 'v2/professional/programs/periods/{period_id}/tour_officers/listing',
            POST_OFFICER: 'v2/professional/programs/periods/{period_id}/tour_officers',
            PUT_OFFICER: 'v2/professional/programs/periods/{period_id}/tour_officers/{officers_id}',
            DELETE_OFFICER: 'v2/professional/periods/{period_id}/tour_officers/{officers_id}',
            POST: 'professional/programs/{program_id}/periods',
            PUT: 'professional/programs/{program_id}/periods/{period_id}',
            DELETE: 'v2/professional/programs/{program_id}/periods/{period_id}',
            UPDATE_SELL_STATUS: 'professional/programs/{program_id}/periods/{period_id}/sell_status',
            LOGO: {
                PUT: 'professional/programs/{program_id}/periods/{period_id}/logo',
            },
            ROOM: {
                GET_LISTING: 'v2/professional/programs/periods/{period_id}/rooms',
                POST: 'professional/programs/periods/{period_id}/rooms',
                PASSENGER: {
                    DELETE: 'professional/programs/periods/{period_id}/rooms/{room_id}/passengers/{passenger_id}',
                },
            },
            CONFIG_CHANNELS: {
                PUT: 'v2/professional/programs/{program_id}/periods/config_channels',
            },
        },
        UPLOAD_TEMP_FILE: 'professional/files/temporary/upload',
    },
    AGENCY: {
        GET: 'professional/agencies/{agency_id}',
        POST: 'professional/agencies',
        PUT: 'professional/agencies/{agency_id}',
        GET_ALL: 'professional/agencies',
        GET_BY_PERIODS: 'professional/agencies/periods/{period_ids}',
        GET_LISTING: 'professional/agencies/listing',
        AVAILABLE_STATUS: {
            PUT: 'professional/agencies/{agency_id}/available',
        },
        MEMBER: {
            GET_LISTING: 'professional/agencies/ob_members/listing',
            POST: 'professional/agencies/{agency_id}/members',
            PUT: 'professional/agencies/{agency_id}/members/{member_id}',
            SIGNUP_TOKEN: {
                POST: 'professional/agencies/{agency_id}/members/signup_token',
            },
        },
        GET_ALL_TWM: 'professional/agcy/agencies/listing',
        UDPATE_ADMIN: 'professional/agencies/{agency_id}/admin_members/{member_id}',
    },
    PASSENGER: {
        GET_LISTING: 'professional/passengers/listing',
        GET_ALL: 'professional/passengers',
        GET: 'professional/passengers/{passenger_id}',
        POST: 'professional/passengers',
        PUT: 'professional/passengers/{passenger_id}',
        AVAILABLE_STATUS: {
            PUT: 'professional/passengers/{passenger_id}/available',
        },
    },
    ORDER: {
        V3: ENDPOINTS_ORDER_v3,
        GET_LISTING: 'professional/orders/listing',
        GET_ALL: 'professional/orders',
        GET: 'professional/orders/{order_id}',
        POST: 'professional/orders',
        SEND_MAIL: 'professional/orders/{order_id}/send_email',
        STATUS: {
            PUT: 'professional/orders/{order_id}/order_status',
        },
        PASSENGER: {
            GET_LISTING: 'professional/orders/passengers',
            GET: 'professional/orders/{order_id}/passengers',
            POST: 'professional/orders/{order_id}/passengers/{passenger_id}',
            DELETE: 'professional/orders/{order_id}/passengers/{passenger_id}',
        },
        REPORT: {
            GET_ALL: 'professional/orders/report',
        },
        SEND_EMAIL: {
            POST: 'professional/orders/{order_id}/send_email',
        },
        SELLER_STAT: {
            GET: 'professional/orders/seller/stat',
        },
        ANALYTICS: {
            MONTHLY: 'professional/orders/analytics/monthly',
            SELLER: 'professional/orders/analytics/seller',
            COUNTRIES: 'professional/orders/analytics/countries',
        },
        NOTE: {
            PUT: 'professional/orders/{order_id}/note',
        },
    },
    STAT: {
        ORDER: {
            GET_SUMMARY: 'professional/orders/stat/summary',
        },
        SELLER: {
            GET_SUMMARY: 'professional/sellers/stat/summary',
        },
        AGENCY: {
            GET_SUMMARY: 'professional/agencies/stat/summary',
            GET_OB_MEMBER_SUMMARY: 'professional/agencies/ob_members/summary',
        },
        PERIOD: {
            GET_SUMMARY: 'professional/programs/periods/stat/summary',
            GET_WARNING: 'professional/programs/periods/stat/warning',
        },
        PAYMENT: {
            GET_INSTALLMENTS: 'professional/payments/installments/stat',
        },
    },
    PAYMENT: {
        V3: ENDPOINTS_PAYMENT_v3,
    },
    BANK: {
        GET: 'professional/suppliers/bank_accounts',
    },
    CATEGORY: {
        SALE: {
            GET: 'categories/sales',
        },
    },
    DASHBOARD: {
        GROUP_PLAN: {
            GET: 'v2/professional/programs/travel_plans',
        },
        ORDER_TASKS: {
            GET: 'v3/professional/orders/analytics/tasks',
            GET_SELLERS: 'v3/professional/orders/analytics/tasks/sellers',
        },
        ORDER_WEEKLY: {
            GET: 'v3/professional/orders/analytics/sales/channels/weekly',
        },
        ORDER_MONTHLY: {
            GET: 'v3/professional/orders/analytics/sales/channels/monthly',
        },
        ORDER_SELLERS: {
            GET: 'v3/professional/orders/analytics/sales/sellers',
        },
        ORDER_AGENCIES: {
            GET: 'v3/professional/orders/analytics/sales/agencies',
        },
        ORDER_CATEGORY_PRODUCTS: {
            GET: 'v3/professional/orders/analytics/sales/category_products',
        },
        ORDER_CATEGORY_PRODUCTS_MONTHLY: {
            GET: 'v3/professional/orders/analytics/sales/category_products/monthly',
        },
        ORDER_COUNTRIES_MONTHLY: {
            GET: 'v3/professional/orders/analytics/sales/countries/monthly',
        },
    },
    LOG: {
        ACCESS_LOG: 'professional/access_logs',
    },
    REPORT: {
        INVOICE: {
            GET_STATS_MONTHLY: 'v3/professional/orders/invoice/stat/monthly',
            GET_REPORT: 'v3/professional/orders/invoice/report',
        },
    },
    PROMOTION: {
        GET: 'professional/promotions/deals/{id}',
        POST: 'professional/promotions/deals',
        PUT: {
            UPDATE: 'professional/promotions/deals/{id}',
            ACTIVE: 'professional/promotions/deals/{id}/actions/active',
            INACTIVE: 'professional/promotions/deals/{id}/actions/inactive',
        },
    },
};
