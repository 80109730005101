
export const PAYMENT_STATUS = {
    WAITING_PAID: 3,
    PENDING_APPROVE_PAID_1: 11,
    APPROVE_PAID_1: 31,
    APPROVE_PAID_FULL: 32,
}

export const PAYMENT_STATUS_LIST = [
    { id: PAYMENT_STATUS.WAITING_PAID, status_code: 'waiting_paid', text_th: 'รอชำระเงิน' },
    { id: PAYMENT_STATUS.PENDING_APPROVE_PAID_1, status_code: 'pending_approve_paid_1', text_th: 'รอยืนยันมัดจำ' },
    { id: PAYMENT_STATUS.APPROVE_PAID_1, status_code: 'approved_paid_1', text_th: 'ยืนยันรับเงินมัดจำ' },
    { id: PAYMENT_STATUS.APPROVE_PAID_FULL, status_code: 'approved_paid_full', text_th: 'ยืนยันรับเงินครบ' },
    
];

export const INVOICE_STATUS = {
    booking: 'จองแล้ว',
    waiting_approve: 'Invoice ยังไม่อนุมัติ',
    waiting_send: 'อนุมัติแล้วรอส่ง',
    sended: 'ส่ง Invoice แล้ว',
    rejected: 'ยกเลิก Booking'
};

export const INVOICE_STATUS_CODE = {
    DRAFT: 'draft',
    BOOKING: 'booking',
    WAITING_APPROVE: 'pending_approve',
    WAITING_SEND: 'pending_send',
    SENDED: 'sended',
    REJECTED: 'rejected'
};

export const INVOICE_STATUS_OPTION = [
    {status_code: INVOICE_STATUS_CODE.WAITING_APPROVE, text_th: 'Invoice ยังไม่อนุมัติ'},
    {status_code: INVOICE_STATUS_CODE.WAITING_SEND, text_th: 'อนุมัติแล้วรอส่ง'},
    {status_code: INVOICE_STATUS_CODE.SENDED, text_th: 'ส่ง Invoice แล้ว'},
    {status_code: INVOICE_STATUS_CODE.REJECTED, text_th: 'ยกเลิก Booking'}
];

export const INVOICE_STATUS_CODE_NUMBER = {
  DRAFT: 2,
  PENDING_APPROVE: 11,
  PENDING_SEND: 14,
  SENDED: 31,
  COMPLETED: 32,
  REJECTED: 24
}

export const INVOICE_ACTION = {
  REQUEST_APPROVE: 'request_approve',
  APPROVE: 'approve',
  REJECT: 'reject',
  SEND_EMAIL: 'send_email'
}

export const PAYMENT_TRANSACTION_STATUS = {
    PENDING_APPROVE: 11,
    APPROVED: 31
}

export const PAYMENT_DEFAULT_SLIP = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAASwAAAH0CAIAAAAIcodgAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAU6SURBVHhe7dMxAQAADMOg+bdVY5ORBzxwA1ISQkxCiEkIMQkhJiHEJISYhBCTEGISQkxCiEkIMQkhJiHEJISYhBCTEGISQkxCiEkIMQkhJiHEJISYhBCTEGISQkxCiEkIMQkhJiHEJISYhBCTEGISQkxCiEkIMQkhJiHEJISYhBCTEGISQkxCiEkIMQkhJiHEJISYhBCTEGISQkxCiEkIMQkhJiHEJISYhBCTEGISQkxCiEkIMQkhJiHEJISYhBCTEGISQkxCiEkIMQkhJiHEJISYhBCTEGISQkxCiEkIMQkhJiHEJISYhBCTEGISQkxCiEkIMQkhJiHEJISYhBCTEGISQkxCiEkIMQkhJiHEJISYhBCTEGISQkxCiEkIMQkhJiHEJISYhBCTEGISQkxCiEkIMQkhJiHEJISYhBCTEGISQkxCiEkIMQkhJiHEJISYhBCTEGISQkxCiEkIMQkhJiHEJISYhBCTEGISQkxCiEkIMQkhJiHEJISYhBCTEGISQkxCiEkIMQkhJiHEJISYhBCTEGISQkxCiEkIMQkhJiHEJISYhBCTEGISQkxCiEkIMQkhJiHEJISYhBCTEGISQkxCiEkIMQkhJiHEJISYhBCTEGISQkxCiEkIMQkhJiHEJISYhBCTEGISQkxCiEkIMQkhJiHEJISYhBCTEGISQkxCiEkIMQkhJiHEJISYhBCTEGISQkxCiEkIMQkhJiHEJISYhBCTEGISQkxCiEkIMQkhJiHEJISYhBCTEGISQkxCiEkIMQkhJiHEJISYhBCTEGISQkxCiEkIMQkhJiHEJISYhBCTEGISQkxCiEkIMQkhJiHEJISYhBCTEGISQkxCiEkIMQkhJiHEJISYhBCTEGISQkxCiEkIMQkhJiHEJISYhBCTEGISQkxCiEkIMQkhJiHEJISYhBCTEGISQkxCiEkIMQkhJiHEJISYhBCTEGISQkxCiEkIMQkhJiHEJISYhBCTEGISQkxCiEkIMQkhJiHEJISYhBCTEGISQkxCiEkIMQkhJiHEJISYhBCTEGISQkxCiEkIMQkhJiHEJISYhBCTEGISQkxCiEkIMQkhJiHEJISYhBCTEGISQkxCiEkIMQkhJiHEJISYhBCTEGISQkxCiEkIMQkhJiHEJISYhBCTEGISQkxCiEkIMQkhJiHEJISYhBCTEGISQkxCiEkIMQkhJiHEJISYhBCTEGISQkxCiEkIMQkhJiHEJISYhBCTEGISQkxCiEkIMQkhJiHEJISYhBCTEGISQkxCiEkIMQkhJiHEJISYhBCTEGISQkxCiEkIMQkhJiHEJISYhBCTEGISQkxCiEkIMQkhJiHEJISYhBCTEGISQkxCiEkIMQkhJiHEJISYhBCTEGISQkxCiEkIMQkhJiHEJISYhBCTEGISQkxCiEkIMQkhJiHEJISYhBCTEGISQkxCiEkIMQkhJiHEJISYhBCTEGISQkxCiEkIMQkhJiHEJISYhBCTEGISQkxCiEkIMQkhJiHEJISYhBCTEGISQkxCiEkIMQkhJiHEJISYhBCTEGISQkxCiEkIMQkhJiHEJISYhBCTEGISQkxCiEkIMQkhJiHEJISYhBCTEGISQkxCiEkIMQkhJiHEJISYhBCTEGISQkxCiEkIMQkhJiHEJISYhBCTEGISQkxCiEkIMQkhJiHEJISYhBCTEGISQkxCiEkIMQkhJiHEJISYhBCTEGISQkxCiEkIMQkhJiHEJISYhBCTEGISQkxCiEkIMQkhJiHEJISYhBCTEGISQkxCiEkIqe0ByG9D5qYO6f8AAAAASUVORK5CYII=';