import { Deserializable } from './deserializable';
import { REGISTER_STATUS_LIST, REGISTER_STATUS } from 'app/constants/agency-online-booking.constant';
import { COMPANY_TYPE, COMPANY_TYPE_LIST } from 'app/constants/agency-company-type.constant';
import { IContact, IAgency } from './agency';

export class ContactUserInfo {
  id: number;
  email: string;
}

export class ContactAgencyInfo {
  id: number;
  company_type: string;
  company_name: string;
}

export class Contact implements IContact, Deserializable {
  id: number;
  pro_agencies_id: number;
  first_name: string;
  last_name: string;
  nick_name: string;
  name: string;
  tel: string;
  email: string;
  address: string;
  note: string;
  line_id: string;
  is_channel_ob: number;
  channel_ob_status: string;
  users_id: number;
  user_name: string;
  channel_ob_status_code: string;
  created_at: string;
  user: ContactUserInfo;
  agency_info: ContactAgencyInfo;

  public selected?: boolean;
  // public name?: string;

  public get fullname(): string {
    return this.name;
  }

  public get onlineBookingStatus(): string {
    if (!this.is_channel_ob) {
      return '-';
    }

    const status = REGISTER_STATUS_LIST.find(x => x.key === this.channel_ob_status_code);
    return status.value;
  }

    public get onlineBookingStatusColor(): string {
    if (!this.is_channel_ob) {
      return '#000';
    }

    const status = REGISTER_STATUS_LIST.find(x => x.key === this.channel_ob_status_code);
    return status.color;
  }

  public deserialize(input: any): any {
    Object.assign(this, input);
    return this;
  }
}


export class AgencyContact implements IAgency, Deserializable {

  public id: number;
  public suppliers_id: number;
  public company_name: string;
  public company_type: number;
  public alias_name: string;
  public company_license: string;
  public company_license_document_file_name: string;
  public company_license_document_path: string;
  public tourism_license: string;
  public tourism_license_document_file_name: string;
  public tourism_license_document_path: string;
  public tel: string;
  public email: string;
  public line_id: string;
  public address: string;
  public note: string;
  public available_status_code: number;
  public available_status_reason: string;
  public created_at: string;
  public created_by: number;
  public updated_at: string;
  public updated_by: number;
  public is_verified: number;
  public contacts: Contact[];

  // These 2 properties are still use it?
  // As i tried to GET from Api. It doesn't exist anymore
  public tax_id: string;
  public contact: string;

  public get getTelephoneNumber(): string {
    let telephoneNumber: string;
    if (this.company_type === COMPANY_TYPE.AGENCY) {
      const contact: Contact = this.contacts.find(elm => elm.selected === true);
      if (contact === undefined) {
        return '-';
      }
      telephoneNumber = contact.tel;
    } else {
      telephoneNumber = this.contacts.length > 0 ? this.contacts[0].tel : null;
    }

    return telephoneNumber;
  }

  public get getEmail(): string {
    let email: string;
    if (this.company_type === COMPANY_TYPE.AGENCY) {
      const contact: Contact = this.contacts.find(elm => elm.selected === true);
      if (contact === undefined) {
        return '-';
      }
      email = contact.email;
    } else {
      email = this.contacts.length > 0 ? this.contacts[0].email : null;
    }

    return email;
  }

  public get getContactSelected(): Contact {
    let contact: Contact;
    if (this.company_type == COMPANY_TYPE.AGENCY) {
      contact = this.contacts.find(elm => elm.selected === true);
    } else {
      contact = this.contacts[0];
    }

    return contact;
  }

  public get fullname(): string {
    let fullname: string;
    if (this.company_type === COMPANY_TYPE.AGENCY) {
      const contact: Contact = this.contacts.find(elm => elm.selected === true);
      if (contact === undefined) {
        return '-';
      }
      fullname = contact.fullname;
    } else {
      fullname = this.contacts.length > 0 ? this.contacts[0].fullname : null;
    }

    return fullname;
  }

  public get dropdownOptionTH(): any {
    return {
      text: this.company_name,
      value: this.id,
      id: this.id,
      selected: false
    }
  }

  public get totalContact(): number {
    return this.contacts.length;
  }

  public get totalContactAsAvailableStatus(): number {
    return this.contacts.filter(x => x.channel_ob_status_code === REGISTER_STATUS.AVAILABLE).length;
  }

  public get totalContactAsPendingStatus(): number {
    return this.contacts.filter(x => x.channel_ob_status_code === REGISTER_STATUS.PENDING_APPROVE).length;
  }

  public get companyTypeName(): string {
    return COMPANY_TYPE_LIST.find(x => x.value === this.company_type).name;
  }

  public get isAgency(): boolean {
    return this.company_type === COMPANY_TYPE.AGENCY;
  }

  public get isNonAgency(): boolean {
      return this.company_type === COMPANY_TYPE.NON_AGENCY;
  }

  public deserialize(input: any): any {
    Object.assign(this, input);

    if (this.contacts) {
      this.contacts = this.contacts.map((contact) => new Contact().deserialize(contact));
    }

    return this;
  }
}

export class AgencyValidate {
  invalidTourismLicenseDuplicate: boolean;
  agencyTourismLicenseDuplicateId: number;
}
