import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';

export const ProductLinks = [
  '/product/blank',
  '/product/join-tour',
  '/product/package-tour'
];

export const ConfigChannelLinks = [
  '/sale/config-channel',
  '/sale/config-channel/join-tour',
  '/sale/config-channel/package-tour'  
];

export const ConfigShareLinks = [
  '/program/share-link'
];

export const PromotionLinks = [
  '/promotion/join-tour'
];

@Component({
  selector: 'app-header-product-select',
  templateUrl: './header-product-select.component.html',
  styleUrls: ['./header-product-select.component.css']
})
export class HeaderProductSelectComponent implements OnInit {

  public title: string = 'กรุณาเลือกสินค้า';
  links: IProductSelectionLinkViewModel[] = [];

  constructor(
    private _router: Router
  ) { }

  ngOnInit() {
    this.setLinks(this._router.url);
    this._router.events.subscribe(response => {
      if(response instanceof NavigationEnd) {
        this.setLinks(response.url);
      }
    });
  }

  setLinks(url: string): void {
    if (ProductLinks.indexOf(url) !== -1)
      this.productLinks();
    else if (ConfigChannelLinks.indexOf(url) !== -1)
      this.configChannelLinks();
    else if (PromotionLinks.indexOf(url) !== -1)
      this.promotionLinks();
    else if (ConfigShareLinks.indexOf(url) !== -1)
      this.configShareLinks();
    else
      this.saleLinks();
  }

  saleLinks(): void {
    this.links = [
      {
        name: '+ Join Tour',
        url: ['/sale/booking/join-tour']
      },
      {
        name: '+ Package Tour',
        url: ['/sale/booking/package-tour']
      },
      {
        name: '+ Incentive Tour',
        url: ['/sale/booking/incentive-tour']
      },
      {
        name: '+ Airline Ticket',
        url: ['/sale/booking/airline-ticket']
      },
      {
        name: '+ Service',
        url: ['/sale/booking/service']
      },
      {
        name: '+ Other',
        url: ['/sale/booking/other']
      }
    ];
  }

  productLinks(): void {
    this.links = [
      {
        name: '+ Join Tour',
        url: ['/product/join-tour']
      },
      // {
      //   name: '+ Package Tour',
      //   url: ['/product/package-tour']
      // }
    ];
  }

  configChannelLinks(): void {
    this.title = 'จัดการช่องทางการเปิดขายสินค้า';
    this.links = [
      { name: '+ Join Tour', url: ['/sale/config-channel/join-tour'] },
      // { name: '+ Package Tour', url: ['/sale/config-channel/package-tour'] }
    ];
  }

  promotionLinks(): void {
    this.title = 'จัดการโปรโมชั่น';
    this.links = [
      { name: '+ Join Tour', url: ['/promotion/join-tour'] },
      // { name: '+ Package Tour', url: ['/promotion/package-tour'] }
    ];
  }

  configShareLinks(): void {
    this.title = 'ส่งลิงค์ Online Booking';
    this.links = [
      { name: '+ Join Tour', url: ['/program/share-link'] },
    ];
  }

}

export interface IProductSelectionLinkViewModel {
  name: string;
  url: string[];
}
