import { Deserializable } from "../../deserializable";

export class ResponseViewModel<T> implements IResponseViewModel<T>, Deserializable {

    status: string;    
    data: T;

    public get isOk(): boolean {
        return this.status === 'success';
    }

    deserialize(input: any) {
        Object.assign(this, input);
        return this;
    }
}

export interface IResponseViewModel<T> {
    status: string;
    data: T;
}
