import { Injectable } from '@angular/core';
import { HttpProService } from 'app/services/base/http-pro.service';
import { ENDPOINTS } from 'app/variables/endpoints';

import { Observable } from 'rxjs/Observable';

@Injectable()
export class PeriodService {
  constructor(private httpService: HttpProService) { }

  getAllPeriod(program_id, queries?): Observable<any> {
    let queryString = this.httpService.getQueryString(queries);
    let url = ENDPOINTS.PROGRAM.PERIOD.GET_ALL.replace(
      '{program_id}',
      program_id
    );

    url = url + '?' + queryString;
    return this.httpService.get(url, true);
  }

  getLastPeriod(program_id): Observable<any> {
    let url = ENDPOINTS.PROGRAM.PERIOD.GET_LAST.replace(
      '{program_id}',
      program_id
    );
    return this.httpService.get(url, true);
  }

  getPeriodDetail(program_id, period_id): Observable<any> {
    let url = ENDPOINTS.PROGRAM.PERIOD.GET_DETAIL.replace(
      '{program_id}',
      program_id
    ).replace('{period_id}', period_id);
    return this.httpService.get(url, true);
  }

  addPeriod(program_id, period): Observable<any> {
    let url = ENDPOINTS.PROGRAM.PERIOD.POST.replace('{program_id}', program_id);
    return this.httpService.post(url, period, true);
  }

  updatePeriod(program_id, period_id, period): Observable<any> {
    let url = ENDPOINTS.PROGRAM.PERIOD.GET.replace(
      '{program_id}',
      program_id
    ).replace('{period_id}', period_id);
    return this.httpService.put(url, period, true);
  }

  updateSellStatusPeriod(program_id, period_id, status): Observable<any> {
    const url = ENDPOINTS.PROGRAM.PERIOD.UPDATE_SELL_STATUS
      .replace('{program_id}', program_id)
      .replace('{period_id}', period_id);
    return this.httpService.put(url, status, true);
  }

  updatePeriodLogo(program_id, period_id, imgBase64): Observable<any> {
    let url = ENDPOINTS.PROGRAM.PERIOD.LOGO.PUT.replace(
      '{program_id}',
      program_id
    ).replace('{period_id}', period_id);
    return this.httpService.put(url, {
      logo_file : imgBase64
    }, true);
  }

  deletePeriod(program_id, period_id): Observable<any> {
    let url = ENDPOINTS.PROGRAM.PERIOD.DELETE.replace(
      '{program_id}',
      program_id
    ).replace('{period_id}', period_id);

    return this.httpService.delete(url, true);
  }

  getOfficers(periodId):  Observable<any> {
    const url = ENDPOINTS.PROGRAM.PERIOD.GET_OFFICERS_ALL.replace(
      '{period_id}',
      periodId
    );
    return this.httpService.get(url, true);
  }

  newOfficer(periodId, passenger):  Observable<any> {
    const url = ENDPOINTS.PROGRAM.PERIOD.POST_OFFICER.replace(
      '{period_id}',
      periodId
    );
    return this.httpService.post(url, passenger, true);
  }

  updateOfficer(periodId, passengerId, passenger):  Observable<any> {
    const url = ENDPOINTS.PROGRAM.PERIOD.PUT_OFFICER.replace(
      '{period_id}',
      periodId
    ).replace('{officers_id}', passengerId);
    return this.httpService.put(url, passenger, true);
  }

  deleteOfficer(periodId, passengerId): Observable<any> {
    const url = ENDPOINTS.PROGRAM.PERIOD.DELETE.replace(
      '{period_id}',
      periodId
    ).replace('{officers_id}', passengerId);

    return this.httpService.delete(url, true);
  }

  updateConfigChannel(programId: number, body: any):  Observable<any> {
    const url = ENDPOINTS.PROGRAM.PERIOD.CONFIG_CHANNELS.PUT.replace('{program_id}', programId);
    return this.httpService.put(url, body);
  }

}
