
export const PAGE_NAME = {
    DASHBOARD: { menu_name: '', page_name: 'Dashboard' },
    SALE: { menu_name: 'ขายสินค้า', page_name: 'Product Select', page_slug: 'catalog_page' },
    SALE_JOIN_TOUR: { menu_name: 'ขายสินค้า', page_name: 'Product Join Tour List', page_slug: 'catalog_page' },
    SALE_PACKAGE_TOUR: { menu_name: 'ขายสินค้า', page_name: 'Product Package Tour List', page_slug: 'catalog_page' },
    ORDER_JOIN_TOUR: { menu_name: 'ขายสินค้า', page_name: 'Order Join Tour', page_slug: 'catalog_page' },
    ORDER_PACKAGE_TOUR: { menu_name: 'ขายสินค้า', page_name: 'Order Package Tour', page_slug: 'catalog_page' },
    ORDER_INCENTIVE_TOUR: { menu_name: 'ขายสินค้า', page_name: 'Order Incentive Tour', page_slug: 'catalog_page' },
    ORDER_AIRLINE_TICKET: { menu_name: 'ขายสินค้า', page_name: 'Order Airline Ticket', page_slug: 'catalog_page' },
    ORDER_SERVICE: { menu_name: 'ขายสินค้า', page_name: 'Order Service', page_slug: 'catalog_page' },
    ORDER_OTHER: { menu_name: 'ขายสินค้า', page_name: 'Order Other', page_slug: 'catalog_page' },
    ORDER_LIST: { menu_name: 'ข้อมูล Order', page_name: 'Order List', page_slug: null },
    ORDER_OVERDUE_LIST: { menu_name: 'ข้อมูล Order ที่เกินกำหนดชำระเงิน', page_name: 'Order Overdue List', page_slug: null },

    CART: { menu_name: '', page_name: 'Cart', page_slug: 'cart_page' },
    ORDER_PAGE: { menu_name: '', page_name: 'Order Page', page_slug: 'order_page' },
    PAYMENT_PAGE: { menu_name: '', page_name: 'Payment Page', page_slug: 'payment_page' },
    INVOICE_PAGE: { menu_name: '', page_name: 'Invoice Page', page_slug: 'invoice_page' },
    NOTE_PAGE: { menu_name: '', page_name: 'Note/Document Page', page_slug: null },

    SERIES_UPDATE: { menu_name: 'ส่งซีรีย์อัพเดท', page_name: 'Series Update', page_slug: null },
    SHARE_LINK: { menu_name: 'ส่งลิงค์ Online Booking', page_name: 'Share Link', page_slug: null },

    INVOICE_LIST: { menu_name: 'ข้อมูล Invoice', page_name: 'Invoice List', page_slug: null },
    APPROVE_INVOICE_LIST: { menu_name: 'อนุมัติ Invoice', page_name: 'Approve Invoice List', page_slug: null },
    SEND_EMAIL_INVOICE_LIST: { menu_name: 'ส่งอีเมล Invoice', page_name: 'Send Email Invoice List', page_slug: null },
    PRODUCT_MANAGEMENT: { menu_name: 'จัดการข้อมูลสินค้า', page_name: 'Product Management Select', page_slug: null },
    PRODUCT_JOIN_TOUR_LIST: { menu_name: 'ข้อมูล Join Tour', page_name: 'Product Join Tour List', page_slug: null },

    PAYMENT_LIST: { menu_name: 'งานบัญชี-การเงิน', page_name: 'Payment List', page_slug: null },

    PRODUCT_JOIN_TOUR_NEW: { menu_name: '', page_name: 'New Product Join Tour', page_slug: null },
    PRODUCT_JOIN_TOUR_EDIT: { menu_name: '', page_name: 'Edit Product Join Tour', page_slug: null },
    PRODUCT_JOIN_TOUR_VIEW: { menu_name: '', page_name: 'Product Join Tour View', page_slug: null },

    PRODUCT_JOIN_TOUR_PERIOD_NEW: { menu_name: '', page_name: 'Product Join Tour - New Period', page_slug: null },
    PRODUCT_JOIN_TOUR_PERIOD_EDIT: { menu_name: '', page_name: 'Product Join Tour - Edit Period', page_slug: null },
    PRODUCT_JOIN_TOUR_PERIOD_VIEW: { menu_name: '', page_name: 'Product Join Tour - Period View', page_slug: null },

    PRODUCT_JOIN_TOUR_APPROVE_LIST: { menu_name: 'อนุมัติ Join Tour', page_name: 'Product Join Tour List Waiting Approve', page_slug: null },
    PRODUCT_PACKAGE_TOUR_LIST: { menu_name: 'ข้อมูล Package Tour', page_name: 'Product Package Tour List', page_slug: null },
    PRODUCT_PACKAGE_TOUR_NEW: { menu_name: 'ข้อมูล Package Tour', page_name: 'New Product Package Tour', page_slug: null },
    PRODUCT_PACKAGE_TOUR_EDIT: { menu_name: 'ข้อมูล Package Tour', page_name: 'Edit Product Package Tour', page_slug: null },
    AGENCY_LIST: { menu_name: 'ข้อมูลลูกค้า/Agency', page_name: 'Customer/Agency List', page_slug: null },
    AGENCY_VIEW: { menu_name: '', page_name: 'Customer/Agency View', page_slug: null },
    AGENCY_ADD: { menu_name: 'เพิ่ม Agency', page_name: 'Add Agency', page_slug: null },
    AGENCY_MEMBER_LIST: { menu_name: 'สมาชิก Online Booking', page_name: 'Online Booking Member', page_slug: null },
    JOIN_TOUR_DATA: { menu_name: 'ข้อมูล Join Tour', page_name: 'All Join Tour', page_slug: null },

    REPORT_DOWNLOAD_ORDER: { menu_name: 'ดาวน์โหลดข้อมูล', page_name: 'ข้อมูล Order', page_slug: null },
    REPORT_DOWNLOAD_INVOICE: { menu_name: 'ดาวน์โหลดข้อมูล', page_name: 'ข้อมูล Invoice', page_slug: null },
    REPORT_DOWNLOAD_TRANSACTION: { menu_name: 'ดาวน์โหลดข้อมูลการชำระเงิน', page_name: 'ข้อมูล Transaction', page_slug: null },

    PROMOTION_JOIN_TOUR: { menu_name: 'จัดการโปรโมชั่น', page_name: 'Promotion Join Tour', page_slug: null },

    ERROR_PAGE_401: { menu_name: 'error page', page_name: '401', page_slug: null },
    ERROR_PAGE_404: { menu_name: 'error page', page_name: '404', page_slug: null },
    ERROR_PAGE_500: { menu_name: 'error page', page_name: '500', page_slug: null },

    LOGIN: { menu_name: 'LOGIN', page_name: 'LOGIN', page_slug: null },
}
