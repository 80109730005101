import { Injectable } from '@angular/core';
import { ENDPOINTS_ORDER_v3 } from 'app/variables/endpoints-order';
import { Observable } from 'rxjs';
import { HttpProService } from './base/http-pro.service';

@Injectable()
export class OrderPdfService {
    constructor(private http: HttpProService) {}

    public getPdfInvoiceBeforeUpdate(orderId: number, body: any): Observable<any> {
        let url: string = ENDPOINTS_ORDER_v3.GET_PDF_BEFORE_UPDATE.replace('{order_id}', orderId);
        return this.http.post(url, body);
    }
}