import { Injectable, Inject } from '@angular/core';
import { HttpProService } from './base/http-pro.service';
import { ENDPOINTS } from 'app/variables/endpoints';

import { Observable } from 'rxjs/Observable';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { filter } from 'rxjs/operators';
import { Location } from '@angular/common';
import { DOCUMENT } from '@angular/platform-browser';
import { AuthService } from './auth.service';


export interface AccessLog {
    menu_name: string;
    page_name: string;
    page_slug: string;
    url: string;
    reference_url: string;
}

@Injectable()
export class LogService {

    private history = [];

    constructor(
        private httpService: HttpProService,
        private authenService: AuthService,
        private router: Router,
        private activeteRouteService: ActivatedRoute,
        private location: Location,
        @Inject(DOCUMENT) private document: Document
    ) {
    }

    private postAccessLog(accessLog: AccessLog, isAuthen: boolean): Observable<any> {
        if (isAuthen) {
          return this.httpService.post(ENDPOINTS.LOG.ACCESS_LOG + '/auth', accessLog, isAuthen);
        }

        return this.httpService.post(ENDPOINTS.LOG.ACCESS_LOG, accessLog, isAuthen);
    }

    public loadRouting(): void {
         this.router.events
            .pipe(filter(event => event instanceof NavigationEnd))
            .filter(e => e instanceof NavigationEnd)
            .map(() => {
                let route = this.activeteRouteService.firstChild;
                let child = route;

                while (child) {
                    if (child.firstChild) {
                        child = child.firstChild;
                        route = child;
                    } else {
                        child = null;
                    }
                }

                return route;
            })
            .mergeMap(route => route.data)
            .subscribe((pageData: any) => {
                const url = this.location.path();
                this.history = [...this.history, url];

                const accessLog: AccessLog = {
                  menu_name: pageData.page ? pageData.page.menu_name : null,
                  page_name: pageData.page ? pageData.page.page_name : null,
                  page_slug: pageData.page ? pageData.page.page_slug : null,
                  reference_url: this.getPreviousUrl() ? this.getPreviousUrl() : null,
                  url: url
                }

                const userProfile = this.authenService.getUserProfile();
                if (userProfile === null || url.startsWith('/public')) {
                  this.postAccessLog(accessLog, false).subscribe();
                } else {
                  // Login page.
                  this.postAccessLog(accessLog, true).subscribe();
                }
        });
    }

    private getHistory(): string[] {
        return this.history;
    }

    private getPreviousUrl(): string {
        return this.history[this.history.length - 2];
    }

}
