import { ENDPOINTS } from 'app/variables/endpoints';
import { HttpProService } from 'app/services/base/http-pro.service';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';

@Injectable()
export class ProgramService {
  constructor(private httpService: HttpProService) {}

  getAllProgramBySupplier(
    supplier_slug: string,
    queries?: any
  ): Observable<any> {
    let queryString = this.httpService.getQueryString(queries);
    let url =
      ENDPOINTS.PROGRAM.SUPPLIER.GET_ALL.replace(
        '{supplier_slug}',
        supplier_slug
      ) +
      '?' +
      queryString;
    return this.httpService.get(url, false);
  }

  getProgramListBySupplier(
    supplier_slug: string,
    queries?: any
  ): Observable<any> {
    let queryString = this.httpService.getQueryString(queries);
    let url =
      ENDPOINTS.PROGRAM.SUPPLIER.LIST.replace(
        '{supplier_slug}',
        supplier_slug
      ) +
      '?' +
      queryString;
    return this.httpService.get(url, false);
  }

  getProgramList(queries?: any): Observable<any> {
    const queryString = this.httpService.getQueryString(queries);
    const url = ENDPOINTS.PROGRAM.LIST + '?' + queryString;
    return this.httpService.get(url, true);
  }

  getProgramActiveList(queries?: any): Observable<any> {
    const queryString = this.httpService.getQueryString(queries);
    const url = ENDPOINTS.PROGRAM.ACTIVE_LIST + '?' + queryString;
    return this.httpService.get(url, true);
  }
  getProgramDraftList(queries?: any): Observable<any> {
    const queryString = this.httpService.getQueryString(queries);
    const url = ENDPOINTS.PROGRAM.DRAFT_LIST + '?' + queryString;
    return this.httpService.get(url, true);
  }

  getProgramReport(queries?: any): Observable<any> {
    const queryString = this.httpService.getQueryString(queries);
    const url = ENDPOINTS.PROGRAM.REPORT + '?' + queryString;
    return this.httpService.get(url, true);
  }

  getAllProgram(queries?: any): Observable<any> {
    let queryString = this.httpService.getQueryString(queries);
    let url = ENDPOINTS.PROGRAM.GET_ALL + '?' + queryString;
    return this.httpService.get(url, true);
  }

  getAllProgramActive(queries?: any): Observable<any> {
    let queryString = this.httpService.getQueryString(queries);
    let url = ENDPOINTS.PROGRAM.GET_ALL_ACTIVE + '?' + queryString;
    return this.httpService.get(url, true);
  }

  getAllProgramDraft(queries?: any): Observable<any> {
    let queryString = this.httpService.getQueryString(queries);
    let url = ENDPOINTS.PROGRAM.GET_ALL_DRAFT + '?' + queryString;
    return this.httpService.get(url, true);
  }

  getAllProgramActiveBySupplier(
    supplier: string,
    queries?: any
  ): Observable<any> {
    let queryString = this.httpService.getQueryString(queries);
    let url =
      ENDPOINTS.PROGRAM.GET_ALL_ACTIVE_NO_AUTH.replace(
        '{supplier_slug}',
        supplier
      ) +
      '?' +
      queryString;

    return this.httpService.get(url, false);
  }

  getProgramDetail(program_id): Observable<any> {
    let url = ENDPOINTS.PROGRAM.GET_DETAIL.replace('{program_id}', program_id);
    return this.httpService.get(url, true);
  }

  getProgram(program_id, queries?: any): Observable<any> {
    let url = ENDPOINTS.PROGRAM.GET.replace('{program_id}', program_id);
    if (queries) {
      const queryString = this.httpService.getQueryString(queries);
      url = url + '?' + queryString;
    }
    return this.httpService.get(url, true);
  }

  addProgram(body): Observable<any> {
    let url = ENDPOINTS.PROGRAM.POST;
    return this.httpService.post(url, body, true);
  }

  uploadFile(body): Observable<any> {
    return this.httpService.post(ENDPOINTS.PROGRAM.UPLOAD_TEMP_FILE, body, true);
  }

  updateProgram(program_id, body): Observable<any> {
    let url = ENDPOINTS.PROGRAM.PUT.replace('{program_id}', program_id);
    return this.httpService.put(url, body, true);
  }

  updateSellStatusProgram(program_id, body): Observable<any> {
    let url = ENDPOINTS.PROGRAM.UPDATE_SELL_STATUS.replace(
      '{program_id}',
      program_id
    );
    return this.httpService.put(url, body, true);
  }

  deleteProgram(program_id): Observable<any> {
    let url = ENDPOINTS.PROGRAM.DELETE.replace('{program_id}', program_id);

    return this.httpService.delete(url, true);
  }

  deleteProgramPeriod(program_id, period_id): Observable<any> {
    let url = ENDPOINTS.PROGRAM.PERIOD.DELETE.replace(
      '{program_id}',
      program_id
    );

    url = url.replace('{period_id}', period_id);

    return this.httpService.delete(url, true);
  }

  getProductList(filters?: any): Observable<any> {
    const queries = this.httpService.getQueryString(filters);
    return this.httpService.get(ENDPOINTS.PROGRAM.V3.GET_PRODUCT_LIST + '?' + queries);
  }

  getProductDetail(product_id: number): Observable<any> {
    return this.httpService.get(ENDPOINTS.PROGRAM.V3.GET_PRODUCT_DETAIL.replace('{product_id}', product_id));
  }

  getAllProduct(filters?: any): Observable<any> {
    const queries = this.httpService.getQueryString(filters);
    return this.httpService.get(ENDPOINTS.PROGRAM.V3.GET_PRODUCT_ALL + '?' + queries);
  }
}
