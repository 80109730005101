export const environment = {
    production: false,
    appVersion: require('../../package.json').version + '-dev',
    tourwowProBackofficeV2Url: 'http://v2-dev.tourwowpro.com/',

    apiProUrl: 'https://api-dev.tourwowpro.com/',
    apiUrl: 'https://api-dev.tourwow.com/',
    agencyLoginUrl: 'https://agency.dev.tourwowpro.com/login',
    partnerBackofficeLoginUrl: 'https://biz.dev.dealwow.com/login',
    bookingUrl: 'https://booking-dev.tourwowpro.com/{supplier_slug}',
    seriesUrl: 'https://series.dev.tourwowpro.com/{supplier_slug}',
    googleAnalytics: {
        trackingId: 'UA-142569163-2',
    },
    facebookAppId: '196412898408697',
    msClarityId: '5df1w6tn3j',
};

export const Feature_TOGGLE = {
    period_compare_price: true,
    period_remark: true,
    period_items: true,
    assign_channel: true,
    order_request_note: true,
    order_status_1: true,
    side_menu_twm_order: true,
};
