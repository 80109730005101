import { Injectable } from '@angular/core';
import { HttpProService } from 'app/services/base/http-pro.service';
import { Observable } from 'rxjs/Observable';
import { ENDPOINTS } from 'app/variables/endpoints';

@Injectable()
export class PassengerAvailableStatusService {

  constructor(
    private http: HttpProService
  ) { }

  put(passengerId: number, body: any): Observable<any> {
    return this.http.put(ENDPOINTS.PASSENGER.AVAILABLE_STATUS.PUT.replace('{passenger_id}', passengerId), body);
  }

}
