import { Component, OnInit, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { HeaderProductSearchService, IHeaderProductSearchTemplateSettings } from './header-product-search.service';
import { ListItem } from 'app/shared/models/list-item';
import { Router, NavigationEnd } from '@angular/router';

@Component({
    selector: 'app-header-product-search',
    templateUrl: './header-product-search.component.html',
    styleUrls: ['./header-product-search.component.scss'],
    host: {
        '(document:click)': 'onDocumentClick($event)',
    }
})
export class HeaderProductSearchComponent implements OnInit, AfterViewInit {

    @ViewChild('countriesBox') _countriesBox: ElementRef;
    @ViewChild('programsBox') _programsBox: ElementRef;

    public settings: IHeaderProductSearchTemplateSettings;

    public selectedCountry: number = null;
    public countries: ListItem[];

    public programs: ListItem[];
    public isRequestingPrograms: boolean;

    public isShowModal: boolean;

    private _isShowArrow: boolean;
    public get isShowArrow(): boolean {
        return this._isShowArrow;
    }

    private _isLeftArrowDisable: boolean;
    public get isLeftArrowDisable(): boolean {
        return this._isLeftArrowDisable;
    }

    private _isRightArrowDisable: boolean;
    public get isRightArrowDisable(): boolean {
        return this._isRightArrowDisable;
    }

    constructor(
        private _router: Router,
        private _service: HeaderProductSearchService
    ) { }

    ngOnInit() {
        this._router.events.subscribe(event => {
            if (event instanceof NavigationEnd) {
                this.selectedCountry = null;
                this.countries = [];
                this.programs = [];
                this._isShowArrow = false;
            }
        });

        this._service.countriesChanged$.subscribe(listView => {
            this.countries = listView;
            setTimeout(() => {
                this.decideToDisplayArrowButton();
                this.decideToDisableMoveLeftButton();
            }, 500);
        });
        
        this._service.programsChanged$.subscribe(listView => {
            this.isRequestingPrograms = false;
            this.programs = listView;
        });
        
    }

    ngAfterViewInit() {
        this._service.templateSettings$.delay(100).subscribe(settings => {
            this.settings = settings;
        });
    }

    public countryFilter(event: any, country: ListItem): void {
        this.programs = [];
        this.isRequestingPrograms = true;
        let isSidebarCollapsed = $('.sidebar-area').hasClass('mat-sidenav-closed');
        let sidebarWidth = !isSidebarCollapsed ? $('.sidebar-area').outerWidth() : 0;
        let arrowGap = 50;
        $(this._programsBox.nativeElement).css('left', `calc(${event.pageX}px - ${sidebarWidth}px - ${arrowGap}px)`);
        this.selectedCountry = country.id;
        this._service.broadcastCountryId(country);
        setTimeout(() => this.isShowModal = true, 100);
    }

    public programFilter(program: ListItem): void {
        this._service.broadcastProgramId(program);
    }

    public arrowClick(position: string): void {
        let box = $(this._countriesBox.nativeElement);
        let result = (box.width() / 2)
        if(position === 'left') {
            result = -(result - box.scrollLeft());
        }
        else {
            result = result + box.scrollLeft();
        }
        
        box.animate({ scrollLeft: result }, 299);

        setTimeout(() => {
            this.decideToDisableMoveLeftButton();
            this.decideToDisableMoveRightButton();
        }, 300);
    }

    private decideToDisableMoveLeftButton(): void {
        this._isLeftArrowDisable = this.getCountryScrollLeftPosition() === 0;
    }

    private decideToDisableMoveRightButton(): void {
        let scrollWidth = $(this._countriesBox.nativeElement).get(0).scrollWidth;
        let scrollPosition = this.getCountryScrollLeftPosition();
        this._isRightArrowDisable = (scrollWidth - scrollPosition) === this.getCountryWrapperWidth();
    }

    private decideToDisplayArrowButton(): void {
        this._isShowArrow = this.getTotalCountryWidth() > this.getCountryWrapperWidth();
    }

    private getCountryScrollLeftPosition(): number {
        let box = $(this._countriesBox.nativeElement);
        return box.scrollLeft();
    }

    private getCountryWrapperWidth(): number {
        let box = $(this._countriesBox.nativeElement);
        let wrapperWidth = box.outerWidth();

        return wrapperWidth;
    }

    private getTotalCountryWidth(): number {
        let box = $(this._countriesBox.nativeElement);
        let totalWidth  = 0;
        box.children().each((i, e)=> {
            totalWidth += $(e).outerWidth();
        });

        return totalWidth;
    }

    private onDocumentClick(event: any): void {
        if (this.isShowModal)
            this.isShowModal = false;
    }

}
