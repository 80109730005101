import { PRODUCT_CODE } from 'app/constants/product.constant';
import { Period } from './period';
import { SKU } from './sku';
import { ProgramTour } from './program-tour';

export class JoinTourProgram extends ProgramTour {

  public pro_products_id: number;
  public detail: string;
  public period: Period;
  public periods: Period[];
  public skus: SKU[];

  public program_picture: string;
  public program_picture_download: string;

  public pro_periods_id: number;

  public constructor() {
    super();
    this.id = null;
    this.name = null;
    this.price = 0;
    this.product_type = null;
    this.pro_category_products_id = PRODUCT_CODE.JOIN_TOUR.ID;
    this.pro_category_sub_products_id = PRODUCT_CODE.JOIN_TOUR.SUB;
    this.status = null;

    this.pro_products_id = null;
    this.detail = null;
    this.period = null;
    this.skus = null;

    this.program_picture = null;
    this.program_picture_download = null;
  }

  public get totalQuantity(): number {
    if (this.skus === null || this.skus === undefined) {
      return 0;
    }

    return this.skus.reduce((p, c) => p + (c.quantity || 0), 0);
  }

  public get numberOfPassenger(): number {
    if (this.skus === null || this.skus === undefined) {
      return 0;
    }

    return this.skus.reduce((p, c) => p + (c.quantity || 0), 0);
  }

  public get totalPrice(): number {
    if (this.skus === null || this.skus === undefined) {
      return 0;
    }

    return this.skus.reduce((p, c) => {
      const rawValue = c.quantity * c.price;
      const price = Math.round(rawValue * 100) / 100;
      return p + price;
    }, 0);
  }

  public getCountryName(prefix?: string): string {
      return '';
  }

  public getAirlineName(prefix?: string): string {
      return '';
  }

  public get dropdownOptionTH(): any {
    return {
      text: this.name,
      value: this.id,
      id: this.id,
      selected: false
    }
  }

  public serialize(): any {
    return {
      id: this.id,
      pro_category_products_id: this.pro_category_products_id,
      pro_category_sub_products_id: this.pro_category_sub_products_id,
      pro_products_id: this.pro_products_id,
      pro_periods_id: this.period.id || this.pro_periods_id,
      skus: this.skus
                .filter(sku => sku.quantity > 0)
                .map(sku => {
                  // sku.pro_products_sku_id is undefined on create new order.
                  sku.pro_products_sku_id = sku.pro_products_sku_id || sku.id;
                  return {
                    id: sku.id,
                    pro_products_sku_id: sku.pro_products_sku_id,
                    quantity: sku.quantity,
                    price: sku.price,
                    deposit: this.period.deposit,
                    // is_delete: this.status === 'delete' ? true : undefined
                  }
                }
      ),
      is_delete: this.status === 'delete' ? true : undefined
    }
  }

  public deserialize(input: any): any {
    // Fix attribute not get from the same endpont.
    Object.assign(this, input);
    // Re deserialize Period object
    this.period = new Period().deserialize(this.period);

    if (input.skus !== undefined) {
      this.skus = input.skus.map((sku: any) => new SKU().deserialize(sku));
    }

    return this;
  }

}
