import {Injectable} from '@angular/core';
import {HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpResponse, HttpErrorResponse} from '@angular/common/http';
import {Observable} from 'rxjs';
import { Router } from '@angular/router';

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {

  constructor(
    private router: Router
  ) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).do(event => {
      if ( event instanceof HttpResponse) {
        if (event.status === 200) {
          if (event.body.status === 'error') {
            if (event.body.error.code === 401) {
              this.router.navigate(['/401']);
            }
          }
        }
      }
    },
    err => {
      if (err instanceof HttpErrorResponse) {
        if (err.status === 500) {
          this.router.navigate(['/500']);
        }
      }
    });
  }
}
