import { Component, EventEmitter, OnInit, Output } from '@angular/core';

import { BsModalRef } from 'ngx-bootstrap/modal';
import { HelperUtil } from 'app/utils/helper.util';
import { OrderService } from 'app/services/order.service';

@Component({
  selector: 'app-note-modal',
  templateUrl: './note-modal.component.html',
  styleUrls: ['./note-modal.component.css']
})
export class NoteModalComponent implements OnInit {
  @Output() public onCloseModal = new EventEmitter();

  public note: string;
  public orderId: number;
  public isErrorMaximunLength = false;
  constructor(private bsModalRef: BsModalRef,
              private orderService: OrderService) { }

  ngOnInit() {
    this.note = HelperUtil.br2n(this.note);
  }

  /**
   * Update order note and return string note update onCloseModal emit.
   */
  public update() {
    this.orderService.updateNote(this.orderId, HelperUtil.n2br(this.note)).subscribe((result) => {
      if (result.status === 'success') {
        this.isErrorMaximunLength = false;
        this.onCloseModal.emit({note: this.note});
        this.bsModalRef.hide();
      } else if (result.status === 'error' && result.error.code === '[422]') {
        this.isErrorMaximunLength = true;
      }
    });
  }

  /**
   * Close this modal.
   */
  public closeModal() {
    this.bsModalRef.hide();
  }

}
