import { Injectable } from '@angular/core';
import { Request } from '@angular/http';
import { retry, timeout } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';

import { environment } from 'environments/environment';

import { ResponseViewModel } from 'app/shared/models/viewmodels/base/response-viewmodel';
import { AuthService } from '../auth.service';

@Injectable()
export class HttpProService {
    constructor(private http: HttpClient, private router: Router, private authService: AuthService) {}

    // get
    public get(url: string, hasAuthorization: boolean = true): Observable<any> {
        return Observable.create((observer) => {
            this.getRequestOptions(hasAuthorization).subscribe((headers) => {
                this.http
                    .get(environment.apiProUrl + url, headers)
                    .pipe(timeout(30000), retry(5))
                    .subscribe((result) => {
                        if (result['status'] === 'error' && result['error']['code']) {
                            this.redirectToErrorPage(observer, result);
                        } else {
                            let viewModel = new ResponseViewModel().deserialize(result);
                            observer.next(viewModel);
                            observer.complete();
                        }
                    }, this.onHttpRequestFail);
            }, this.onCannotCreateRequestHeader);
        });
    }

    // post
    public post(url: string, body: any, hasAuthorization: boolean = true): Observable<any> {
        return Observable.create((observer) => {
            this.getRequestOptions(hasAuthorization).subscribe((headers) => {
                this.http
                    .post(environment.apiProUrl + url, body, headers)
                    .pipe(retry(5))
                    .subscribe((result) => {
                        if (result['status'] === 'error') {
                            this.redirectToErrorPage(observer, result);
                        }

                        observer.next(result);
                    }, this.onHttpRequestFail);
            }, this.onCannotCreateRequestHeader);
        });
    }

    // put
    public put(url: string, body: any, hasAuthorization: boolean = true): Observable<any> {
        return Observable.create((observer) => {
            this.getRequestOptions(hasAuthorization).subscribe((headers) => {
                this.http
                    .put(environment.apiProUrl + url, body, headers)
                    .pipe(retry(5))
                    .subscribe((result) => {
                        if (result['status'] === 'error') {
                            this.redirectToErrorPage(observer, result);
                        }

                        observer.next(result);
                    }, this.onHttpRequestFail);
            }, this.onCannotCreateRequestHeader);
        });
    }

    // delete
    public delete(url: string, hasAuthorization: boolean = true): Observable<any> {
        return Observable.create((observer) => {
            this.getRequestOptions(hasAuthorization).subscribe((headers) => {
                this.http
                    .delete(environment.apiProUrl + url, headers)
                    .pipe(retry(5))
                    .subscribe((result) => {
                        if (result['status'] === 'error') {
                            this.redirectToErrorPage(observer, result);
                        } else {
                            observer.next(result);
                        }
                    }, this.onHttpRequestFail);
            }, this.onCannotCreateRequestHeader);
        });
    }

    private redirectToErrorPage(observer, result) {
        switch (result['error']['code']) {
            case 401:
                this.router.navigate(['401']);
                break;
            case 404:
                this.router.navigate(['404']);
                break;
            case 500:
                this.router.navigate(['505']);
                break;
        }
        observer.error(result);
        observer.complete();
    }

    // custom request
    public request(request: Request, headers: any): Observable<any> {
        request.url = environment.apiProUrl + request.url;
        headers.responseType = 'json';
        return this.http.request(request.method.toString(), request.url, headers);
    }

    public getRequestOptions(hasAuthorization: boolean): Observable<any> {
        return Observable.create((observer) => {
            let httpHeaders = new HttpHeaders({
                Accept: 'application/json',
                'Content-Type': 'application/json',
            });

            let requestOptions = {
                headers: httpHeaders,
                responseType: 'json',
            };

            if (!hasAuthorization) {
                observer.next(requestOptions);
                return;
            }

            const tokenKey = this.authService.getJWTToken();
            requestOptions.headers = requestOptions.headers.set('Authorization', tokenKey);
            observer.next(requestOptions);
        });
    }

    private onHttpRequestFail(reason: any): Observable<any> {
        return Observable.throw(reason);
    }

    private onCannotCreateRequestHeader(reason: any): Observable<any> {
        alert('something went wrong');
        return Observable.throw(reason);
    }

    getQueryString(obj: any): string {
        let params = new URLSearchParams();
        for (let key in obj) {
            let param = obj[key];
            if (typeof param === 'object') {
                param = JSON.stringify(param);
            }
            params.set(key, param);
        }
        return params.toString();
    }
}
