import { Injectable } from '@angular/core';
import { ENDPOINTS } from 'app/variables/endpoints';
import { Observable } from 'rxjs/Observable';
import { HttpProService } from './base/http-pro.service';
import {
    IDashboardFilters,
    IDashboardStaticOverviewFilters,
} from 'app/shared/models/filters/dashboard/dashboard-filter';
import { ResponseViewModel } from 'app/shared/models/viewmodels/base/response-viewmodel';
import {
    IDashboardOrderWeeklyViewModel,
    IDashboardOrderAgenciesViewModel,
    IDashboardOrderCategoryProductsViewModel,
} from 'app/shared/models/viewmodels/dashboard/dashboard-viewmodel';

@Injectable()
export class DashboardService {
    constructor(private _service: HttpProService) {}

    getGroupPlan(filters): Observable<ResponseViewModel<any>> {
        const qs = this._service.getQueryString(filters);
        return this._service.get(`${ENDPOINTS.DASHBOARD.GROUP_PLAN.GET}?${qs}`);
    }

    getOrderTasks(): Observable<ResponseViewModel<any>> {
        return this._service.get(ENDPOINTS.DASHBOARD.ORDER_TASKS.GET);
    }

    getOrderTaskSellers(filters?: IDashboardStaticOverviewFilters): Observable<ResponseViewModel<any[]>> {
        const qs = this._service.getQueryString(filters);
        return this._service.get(`${ENDPOINTS.DASHBOARD.ORDER_TASKS.GET_SELLERS}?${qs}`);
    }

    getOrderWeekly(filters?: IDashboardFilters): Observable<ResponseViewModel<IDashboardOrderWeeklyViewModel[]>> {
        const qs = this._service.getQueryString(filters);
        return this._service.get(`${ENDPOINTS.DASHBOARD.ORDER_WEEKLY.GET}?${qs}`);
    }

    getOrderMonthly(filters?: IDashboardFilters): Observable<ResponseViewModel<any[]>> {
        const qs = this._service.getQueryString(filters);
        return this._service.get(`${ENDPOINTS.DASHBOARD.ORDER_MONTHLY.GET}?${qs}`);
    }

    getOrderSellers(filters?: IDashboardFilters): Observable<ResponseViewModel<any[]>> {
        const qs = this._service.getQueryString(filters);
        return this._service.get(`${ENDPOINTS.DASHBOARD.ORDER_SELLERS.GET}?${qs}`);
    }

    getOrderAgencies(filters?: IDashboardFilters): Observable<ResponseViewModel<IDashboardOrderAgenciesViewModel[]>> {
        const qs = this._service.getQueryString(filters);
        return this._service.get(`${ENDPOINTS.DASHBOARD.ORDER_AGENCIES.GET}?${qs}`);
    }

    getOrderCategoryProducts(
        filters?: IDashboardFilters
    ): Observable<ResponseViewModel<IDashboardOrderCategoryProductsViewModel[]>> {
        const qs = this._service.getQueryString(filters);
        return this._service.get(`${ENDPOINTS.DASHBOARD.ORDER_CATEGORY_PRODUCTS.GET}?${qs}`);
    }

    getOrderCategoryProductsMonthly(filters?: IDashboardFilters): Observable<ResponseViewModel<any[]>> {
        const qs = this._service.getQueryString(filters);
        return this._service.get(`${ENDPOINTS.DASHBOARD.ORDER_CATEGORY_PRODUCTS_MONTHLY.GET}?${qs}`);
    }

    getOrderCountriesMonthly(filters?: IDashboardFilters): Observable<ResponseViewModel<any[]>> {
        const qs = this._service.getQueryString(filters);
        return this._service.get(`${ENDPOINTS.DASHBOARD.ORDER_COUNTRIES_MONTHLY.GET}?${qs}`);
    }
}
