

<div class="modal-header">
  <h4 class="modal-title pull-left mt-4">แก้ไขโน้ต (สำหรับใช้ภายในบริษัท)</h4>
  <button type="button" class="close pull-right" aria-label="Close" (click)="closeModal()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <label for="editNote" class="m-2">รายละเอียด :</label>
  <textarea name="note" id="note" [(ngModel)]="note" maxlength="500" cols="30" rows="10"></textarea>
  <span *ngIf="isErrorMaximunLength" class="text-danger">ไม่สามารถโน้ตได้มากกว่า 500 ตัวอักษร</span>
  <div class="text-center my-4">
    <button class="command-btn update" (click)="update()">บันทึก</button>
    <button class="command-btn" (click)="closeModal()">ยกเลิก</button>
  </div>
</div>
<app-loading [loading]="isLoading"></app-loading>
