import { Injectable } from "@angular/core";
import { HttpProService } from "app/services/base/http-pro.service";
import { ENDPOINTS } from "app/variables/endpoints";

import { Observable } from "rxjs/Observable";

@Injectable()
export class InfoService {
    constructor(private httpService: HttpProService) {}

    getInfo(supplierlug): Observable<any> {
        return this.httpService.get(
            ENDPOINTS.SUPPLIER.INFO.replace("{supplier_slug}", supplierlug),
            false
        );
    }

    public getConfigOrder(): Observable<any> {
        return this.httpService.get(
            ENDPOINTS.SUPPLIER.GET_CONFIG_ORDER,
            true
        );
    }
}
