import { Component, Input } from '@angular/core';
import { CartService } from 'app/core/services/cart.service';

@Component({
  selector: 'app-header-top-bar',
  templateUrl: './header-top-bar.component.html',
  styleUrls: ['./header-top-bar.component.scss']
})
export class HeaderTopBarComponent {

  @Input() sidenav: any;
  @Input() isShowCart: boolean;
  @Input() displayText: string;

  public get productQuantity(): number {
    return this._cartService.totalQuantity;
  }

  constructor(
    private _cartService: CartService
  ) {}

}
