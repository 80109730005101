import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';

@Injectable()
export class Base64Util {

    toArrayBuffer(base64) {
        const binaryString = atob(base64);
        const length = binaryString.length;
        const bytes = new Uint8Array(length);
        for (let i = 0; i < length; i++)        {
            bytes[i] = binaryString.charCodeAt(i);
        }
        return bytes.buffer;
    }

    toBase64String(file: File): Observable<string> {
        return Observable.create(observer => {
            if (file) {
                let reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = function () {
                    observer.next(reader.result);
                    observer.complete();
                };
                reader.onerror = function (error) {
                    observer.error(error);
                    observer.complete();
                };
            }
            else {
                observer.error();
                observer.complete();
            }
        })
     }

}