import { ENDPOINTS } from 'app/variables/endpoints';
import { ENDPOINTS_ORDER_v3 } from 'app/variables/endpoints-order';
import { HttpProService } from 'app/services/base/http-pro.service';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { UploadfileRequest } from 'app/shared/models/requests/base/uploadfile-request';

@Injectable()
export class OrderService {
    constructor(private http: HttpProService) {}

    getListing(filters?: any): Observable<any> {
        const queries = this.http.getQueryString(filters);
        return this.http.get(ENDPOINTS.ORDER.GET_LISTING + '?' + queries);
    }

    getAll(filters?: any): Observable<any> {
        const queries = this.http.getQueryString(filters);
        return this.http.get(ENDPOINTS.ORDER.GET_ALL + '?' + queries);
    }

    get(orderId: number): Observable<any> {
        return this.http.get(ENDPOINTS.ORDER.GET.replace('{order_id}', orderId));
    }

    getLastOrder(orderId: number): Observable<any> {
        return this.http.get(ENDPOINTS.ORDER.V3.GET_LAST.replace('{order_id}', orderId));
    }

    getActiveOrder(orderId: number): Observable<any> {
        return this.http.get(ENDPOINTS.ORDER.V3.GET_ACTIVE.replace('{order_id}', orderId));
    }

    getSummaryOrder(orderId: number): Observable<any> {
        return this.http.get(ENDPOINTS.ORDER.V3.GET_SUMMARY.replace('{order_id}', orderId));
    }

    getFiles(orderId: number): Observable<any> {
        return this.http.get(ENDPOINTS.ORDER.V3.GET_FILES.replace('{order_id}', orderId));
    }

    getInvoiceVersions(orderId: number): Observable<any> {
        return this.http.get(ENDPOINTS.ORDER.V3.GET_INVOICE_VERSIONS.replace('{order_id}', orderId));
    }

    getListingV3(filters?: any): Observable<any> {
        const queries = this.http.getQueryString(filters);
        return this.http.get(ENDPOINTS.ORDER.V3.GET_LISTING + '?' + queries);
    }

    getInvoiceListingV3(filters?: any): Observable<any> {
        const queries = this.http.getQueryString(filters);
        return this.http.get(ENDPOINTS.ORDER.V3.INVOICE.GET_LISTING + '?' + queries);
    }

    getPreviewInvoicePdf(orderId: number): Observable<any> {
        return this.http.get(ENDPOINTS.ORDER.V3.GET_PDF.replace('{order_id}', orderId));
    }

    getInvoicePdf(orderId: number, invoiceId: number): Observable<any> {
        return this.http.get(ENDPOINTS.ORDER.V3.INVOICE.GET_PDF.replace('{order_id}', orderId).replace('{invoice_id}', invoiceId));
    }

    getEmailForm(orderId: number): Observable<any> {
        return this.http.get(ENDPOINTS.ORDER.V3.INVOICE.EMAIL_FORM.replace('{order_id}', orderId));
    }

    post(body: any): Observable<any> {
        return this.http.post(ENDPOINTS.ORDER.POST, body);
    }

    newOrder(body: any): Observable<any> {
        return this.http.post(ENDPOINTS.ORDER.V3.POST, body);
    }

    uploadAttachmentFile(orderId: number, payload: UploadfileRequest): Observable<any> {
        return this.http.post(ENDPOINTS.ORDER.V3.POST_FILE.replace('{order_id}', orderId), payload, true);
    }

    updateNote(orderId: number, note: string): Observable<any> {
        return this.http.put(ENDPOINTS.ORDER.NOTE.PUT.replace('{order_id}', orderId), {note: note});
    }

    updateLastOrder(orderId: number, body: any): Observable<any> {
        return this.http.put(ENDPOINTS.ORDER.V3.PUT_LAST.replace('{order_id}', orderId), body);
    }

    updateInvoiceStatus(orderId: number, action: string, body: any = null): Observable<any> {
        return this.http.put(
        ENDPOINTS.ORDER.V3.PUT_ACTION.replace('{order_id}', orderId)
                                    .replace('{action}', action)
        , body, true);
    }

    updateNote1dot6(orderId: number, note: string): Observable<any> {
        return this.http.put(
        ENDPOINTS.ORDER.V3.PUT_NOTE.replace('{order_id}', orderId)
        , {order_note: note});
    }

    removeAttachmentFile(orderId: number, fileId: number): Observable<any> {
        return this.http.get(ENDPOINTS.ORDER.V3.DELETE_FILE.replace('{order_id}', orderId)
                                                        .replace('{file_id}', fileId), true);
    }

    sendMail(orderId: number, body): Observable<any> {
        return this.http.post(
        ENDPOINTS.ORDER.SEND_MAIL.replace('{order_id}', orderId),
        body
        );
    }

    cancelOrder(orderId: number, payload: any): Observable<any> {
        return this.http.put(ENDPOINTS.ORDER.V3.CANCEL_ORDER.replace('{order_id}', orderId), payload, true);
    }

    batchCancelAllOrder(filters: any): Observable<any> {
        const queries = this.http.getQueryString(filters);
        return this.http.get(ENDPOINTS_ORDER_v3.BATCH_CANCEL_ORDER_ALL + '?' + queries);
    }

    batchCancelOrder(orderId: any): Observable<any> {
        return this.http.put(ENDPOINTS_ORDER_v3.BATCH_CANCEL_ORDER, orderId, true);
    }

    getVerify(orderId: number): Observable<any> {
        return this.http.get(ENDPOINTS.ORDER.V3.GET_VERIFY.replace('{order_id}', orderId), true);
    }

    getEmailConfirmData(orderId: number): Observable<any> {
        return this.http.get(ENDPOINTS.ORDER.V3.EMAIL_CONFIRM.replace('{order_id}', orderId), true);
    }

    updateOrderStatus(orderId: number, action: string, body: any = null): Observable<any> {
        return this.http.put(
        ENDPOINTS.ORDER.V3.PUT_ORDER_ACTION.replace('{order_id}', orderId)
                                           .replace('{action}', action)
        , body, true);
    }








}
