import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { Observable } from 'rxjs/Observable';

@Injectable()
export class LoadingService {
    private _showLoading = new BehaviorSubject<boolean>(false);

    constructor() {}

    public show() {
        this._showLoading.next(true);
    }

    public hide() {
        this._showLoading.next(false);
    }

    public get loadingStatus(): Observable<boolean> {
        return this._showLoading.asObservable();
    }
}
