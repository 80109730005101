import { Injectable } from '@angular/core';

@Injectable()
export class OrderUtil {

  // constants
  ORDER_BOOKING_STATUS: number = 11;
  ORDER_CANCEL_STATUS: number = 24;

  PERIOD_OPEN_STATUS: number = 1;

  constructor() { }

  public getStatusText(order: any,newOrderListPage = false): any {
    let status = { text: '', backgroundColor: '' };
    switch (order.order_status_code) {
      case this.ORDER_BOOKING_STATUS:
         status.text = 'จองแล้ว';
         status.backgroundColor = (!newOrderListPage)?'#459e75':'#000';
         break;
      case this.ORDER_CANCEL_STATUS:
        status.text = 'ยกเลิก';
        status.backgroundColor = '#F40C0C';
        break;
    }
    return status;
  }

  public canBookingThisPeriod(period: any): boolean {
    return period.sell_status_code === this.PERIOD_OPEN_STATUS && period.quantity_remain > 0;
  }

  public isBookingAble(order: any): boolean {
    return order.order_status_code === this.ORDER_BOOKING_STATUS;
  }

}
