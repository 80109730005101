export const PRODUCT_CODE = {
    JOIN_TOUR: { ID: 1, SUB: 1, NAME: 'Join Tour' },
    PACKAGE_TOUR: { ID: 1, SUB: 2, NAME: 'Package Tour' },
    INCENTIVE_TOUR: { ID: 1, SUB: 3, NAME: 'Incentive Tour' },
    PRIVATE_TOUR: { ID: 1, SUB: 4, NAME: 'Private Tour' },
    AIRLINE_TICKET: { ID: 2, SUB: 5, NAME: 'Airline Ticket' },
    SERVICE: { ID: 3, SUB: null, NAME: 'Service' },
    OTHER: { ID: 4, SUB: null, NAME: 'Other' },
    ADDITION: { ID: 99, SUB: null, NAME: 'Addition' }
};

export const CATEGORY_PRODUCT = [
    {
        ID:     1,
        SUB:    1,
        NAME:   'Join Tour',
        KEY:    'JOIN_TOUR'
    },
    {
        ID:     1,
        SUB:    2,
        NAME:   'Package Tour',
        KEY:    'PACKAGE_TOUR'
    },
    {
        ID:     1,
        SUB:    3,
        NAME:   'Incentive Tour',
        KEY:    'INCENTIVE_TOUR'
    },
    {
        ID:     1,
        SUB:    4,
        NAME:   'Private Tour',
        KEY:    'PRIVATE_TOUR'
    },
    {
        ID:     2,
        SUB:    5,
        NAME:   'Airline Ticket',
        KEY:    'AIRLINE_TICKET'
    },
    {
        ID:     3,
        SUB:    null,
        NAME:   'Service',
        KEY:    'SERVICE'
    },
    {
        ID:     4,
        SUB:    null,
        NAME:   'Other',
        KEY:    'OTHER'
    },
    {
        ID:     99,
        SUB:    null,
        NAME:   'Addition',
        KEY:    'ADDITION'
    },
];
