import { Deserializable } from './deserializable';

export class ListItem implements Deserializable {

    public id: number;
    public name: string;

    deserialize(input: any) {
        Object.assign(this, input);
        return this;
    }
}
