
export class ProductType {
  public id: number;
  public name: string;
  public slug: string;
}

export abstract class Product {

  public id: number;
  public name: string;
  public price: number;
  public quantity: number;
  public detail: string;
  public product_type: ProductType;
  public pro_category_products_id: number;
  public pro_category_sub_products_id: number;
  public pro_products_id: number;
  public status: string;
  public is_delete: boolean;
  public start_at: Date;
  public tour_code: string;

  public abstract get totalQuantity(): number;
  public abstract get numberOfPassenger(): number;
  public abstract get totalPrice(): number;
  public abstract serialize(): any;
  public abstract deserialize(input: any): any;
}
