<div class="modal-header">
  <h4 class="modal-title pull-left">รูปภาพ</h4>
  <button type="button" class="close pull-right" aria-label="Close" (click)="closeModal()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <div class="row">
    <div class="col-12 text-center">
      <img [src]="imageUrl" alt="" style="width: 100%" >
    </div>
  </div>
  <div class="row mt-2">
    <div class="col-12 text-center">
      <button md-raised-button type="button" (click)="closeModal()">ปิด</button>
    </div>
  </div>
</div>