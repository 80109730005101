import { Injectable } from '@angular/core';
import { HttpProService } from 'app/services/base/http-pro.service';
import { Observable } from 'rxjs';
import { ENDPOINTS } from 'app/variables/endpoints';

@Injectable()
export class PaymentFileService {
  constructor(private http: HttpProService) {}

  getPdf(paymentId: any): Observable<any> {
    return this.http.get(
      ENDPOINTS.PAYMENT.FILE.PDF.GET.replace('{payment_id}', paymentId)
    );
  }

}
