import { PRODUCT_CODE } from 'app/constants/product.constant';
import { Product } from './product';
import { HelperUtil } from 'app/utils/helper.util';

export class OtherProduct extends Product {

  public detail: string;
  public quantity: number;
  public price: number;

  constructor() {
    super();
    this.id = null;
    this.name = null;
    this.price = null;
    this.product_type = null;
    this.pro_category_products_id = PRODUCT_CODE.OTHER.ID;
    this.pro_category_sub_products_id = PRODUCT_CODE.OTHER.SUB;
    this.status = null;

    this.detail = null;
    this.quantity = 0;
  }

  public get totalQuantity(): number {
    return this.quantity;
  }

  public get numberOfPassenger(): number {
    return this.quantity;
  }

  public get totalPrice(): number {
    const rawValue = this.quantity * this.price;
    const price = Math.round(rawValue * 100) / 100;
    return price;
  }

  public serialize(): any {
    return {
      id: this.id,
      pro_category_products_id: this.pro_category_products_id,
      pro_category_sub_products_id: this.pro_category_sub_products_id,
      order_detail: HelperUtil.n2br(this.detail),
      quantity: this.quantity,
      price: this.price,
      is_delete: this.status === 'delete' ? true : undefined
    }
  }

  public deserialize(input: any): any {
    Object.assign(this, input);
    return this;
  }

}
