import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { ToastrModule } from 'ngx-toastr';

import { CartService } from './services/cart.service';
import { HeaderProductSearchService } from 'app/shared/components/header/header-product-search/header-product-search.service';
import { ToastWrapperService } from './services/toast.service';
import { LoadingService } from './services/loading.service';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ToastrModule.forRoot({
      positionClass: 'toast-bottom-right',
      progressBar: true,
      timeOut: 10000
    })
  ],
  providers: [
    CartService,
    HeaderProductSearchService,
    ToastWrapperService,
    LoadingService
  ]
})
export class CoreModule { }
