import { Injectable } from '@angular/core';
import { HttpProService } from 'app/services/base/http-pro.service';
import { Observable } from 'rxjs';
import { ENDPOINTS } from 'app/variables/endpoints';

@Injectable()
export class RoomService {

  constructor(
    private http: HttpProService
  ) { }

  getListing(periodId: number): Observable<any> {
    return this.http.get(ENDPOINTS.PROGRAM.PERIOD.ROOM.GET_LISTING.replace('{period_id}', periodId));
  }

  post(periodId: number, body: any): Observable<any> {
    return this.http.post(ENDPOINTS.PROGRAM.PERIOD.ROOM.POST.replace('{period_id}', periodId), body);
  } 
  
}
