import 'chart.piecelabel.js';

import { BsModalRef, ModalModule } from 'ngx-bootstrap/modal';
import {
    CalendarTitlePipe,
    OrderThaiMonthDatetime2LinePipe,
    OrderThaiMonthDatetimePipe,
    ThaiFullMonthYearDatePipe,
    ThaiMonthDatePipe,
    ThaiMonthDatetimePipe,
    ThaidatePipe,
    TourWowProThaiDateRangePipe,
    TourWowProThaiMonthRangePipe,
    TourWowProThaiMonthAbbrRangePipe,
    DayOfWeekInThaiPipe,
} from 'app/pipes/thaidate.pipe';
import { CommonModule, DecimalPipe } from '@angular/common';
import {
    DisplayNullPipe,
    HideNanPipe,
    Nl2BrPipe,
    SortPipe,
    KeyValuePipe,
    CountArrayPipe,
    SumAmountArrayPipe,
} from 'app/pipes/util.pipe';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialModule, MdDatepickerModule, MdInputModule, MdNativeDateModule } from '@angular/material';
import {
    OrderStatusPipe,
    PaymentInstallmentStatusPipe,
    PaymentStatusPipe,
    PeriodStatusPipe,
    ProgramStatusPipe,
    RoomStatusPipe,
    AgencyObStatusPipe,
} from 'app/pipes/status.pipe';
import { HttpClientModule } from '@angular/common/http';
import { NgxCurrencyModule } from 'ngx-currency';

import { AgencyAvailableStatusService } from 'app/services/agency-available-status.service';
/**
 * Start Import Pipe
 */
import { AgencyCompanyTypePipe, SalesTypePipe, PaymentTypePipe, OrderStatusDisplayTypePipe } from 'app/pipes/type.pipe';
import { AgencyService } from 'app/services/agency.service';
import { AgencyStatService } from 'app/services/agency-stat.service';
import { AngularMultiSelectModule } from 'angular2-multiselect-dropdown/multiselect.component';
// import { AuthService } from "app/services/auth.service";
import { BankService } from 'app/services/bank.service';
/**
 * Start Import Util
 */
import { Base64Util } from 'app/utils/base64.util';
import { BookingSearchComponent } from 'app/components/shared/booking-search/booking-search.component';
import { BookingSearchIIIComponent } from 'app/components/shared/booking-search-III/booking-search-III.component';
import { BookingStatusInfoComponent } from 'app/components/shared/booking-status-info/booking-status-info.component';
import { BsDatepickerModule, TabsModule } from 'ngx-bootstrap';
import { CalendarModule } from 'angular-calendar';
import { CaterogyTransportService } from 'app/services/category-transport.service';
import { ChartsModule } from 'ng2-charts/ng2-charts';
import { ClipboardModule } from 'ngx-clipboard';
/**
 * Start Import Component
 */
import { ConfirmPopupComponent } from 'app/components/shared/popup/confirm-popup/confirm-popup.component';
import { CountryFilterComponent } from 'app/components/shared/country-filter/country-filter.component';
import { DetailProgramComponent } from 'app/components/shared/program/program-detail.component';
import { DisplayCountriesPipe } from 'app/pipes/country.pipe';
import { EngFullMonthPipe } from 'app/pipes/engdate.pipe';
import { ExcelService } from 'app/services/excel.service';
import { FullNamePipe } from 'app/pipes/fullname.pipe';
import { HeaderTopBarComponent } from 'app/shared/components/header/header-top-bar/header-top-bar.component';
import { HeaderProductSelectComponent } from 'app/shared/components/header/header-product-select/header-product-select.component';
import { HeaderProductSearchComponent } from 'app/shared/components/header/header-product-search/header-product-search.component';
import { ProductSearchResultLabelComponent } from 'app/shared/components/product/product-search-result-label/product-search-result-label.component';
import { ProgramPeriodTableListComponent } from 'app/components/shared/program-period-table-list/program-period-table-list.component';

import { DashboardWelcomeComponent } from 'app/shared/components/dashboard/dashboard-welcome/dashboard-welcome.component';
import { DashboardSalesmanSalesComponent } from 'app/shared/components/dashboard/dashboard-salesman-sales/dashboard-salesman-sales.component';
import { DashboardProductSalesComponent } from 'app/shared/components/dashboard/dashboard-product-sales/dashboard-product-sales.component';
import { DashboardCompanySalesComponent } from 'app/shared/components/dashboard/dashboard-company-sales/dashboard-company-sales.component';
import { DashboardAgencySalesComponent } from 'app/shared/components/dashboard/dashboard-agency-sales/dashboard-agency-sales.component';
import { DashboardProductSalesTableComponent } from 'app/shared/components/dashboard/dashboard-product-sales-table/dashboard-product-sales-table.component';
import { DashboardAgencySalesTableComponent } from 'app/shared/components/dashboard/dashboard-agency-sales-table/dashboard-agency-sales-table.component';
import { DashboardLastThreemonthSelectorComponent } from 'app/shared/components/dashboard/dashboard-last-threemonth-selector/dashboard-last-threemonth-selector.component';
import { DashboardGroupPlanComponent } from 'app/shared/components/dashboard/dashboard-group-plan/dashboard-group-plan.component';
import { DashboardStatisticOverviewComponent } from 'app/shared/components/dashboard/dashboard-statistic-overview/dashboard-statistic-overview.component';
import { DashboardTopSalesmanComponent } from 'app/shared/components/dashboard/dashboard-top-salesman/dashboard-top-salesman.component';
import { DashboardTotalSalesComponent } from 'app/shared/components/dashboard/dashboard-total-sales/dashboard-total-sales.component';

import { BreadcrumbComponent } from 'app/shared/components/breadcrumb/breadcrumb.component';

/**
 * Start Import Service
 */
import { HttpProService } from 'app/services/base/http-pro.service';
import { HttpService } from 'app/services/base/http.service';
import { ImagePreviewPopupComponent } from 'app/components/shared/popup/image-preview-popup/image-preview-popup.component';
import { InfoService } from 'app/services/info.service';
import { LoadingComponent } from 'app/components/shared/loading/loading.component';
import { LocationService } from 'app/services/location.service';
import { MemberService } from 'app/services/member.service';
import { CurrencyService } from 'app/services/currency.service';
import { LogService } from 'app/services/log.service';

import { NgModule } from '@angular/core';

import { MonthrangePickerComponent } from 'app/components/shared/monthrangepicker/monthrangepicker.component';
import { MonthPickerComponent } from 'app/components/shared/monthpicker/monthpicker.component';
import { MonthPickerSelectorComponent } from 'app/components/shared/monthpicker/dialog/monthpicker-selector.component';
import { TourwowDatePickerComponent } from 'app/components/shared/datepicker/datepicker.component';
import { TextareaAutoHeightComponent } from 'app/components/shared/textarea-auto-height/textarea-auto-height.component';
import { UploadFileComponent } from 'app/components/shared/upload-file/upload-file.component';
import { NoteModalComponent } from 'app/components/shared/note-modal/note-modal.component';
import { Note1dot6ModalComponent } from 'app/components/shared/note-modal-1dot6/note-modal.component';
import { NotifyPopupComponent } from 'app/components/shared/popup/notify-popup/notify-popup.component';
import { PaginationLoadMoreComponent } from 'app/components/shared/pagination-loadmore/pagination-loadmore.component';
import { ToggleSwitchComponent } from 'app/components/shared/toggle-switch/toggle-switch.component';

import { OrderListAsExcelService } from 'app/components/sale-management/order-list-page/order-list-export-as-excel.service';
import { OrderPassengerService } from 'app/services/order-passenger.service';
import { OrderPaymentService } from 'app/services/order-payment.service';
import { OrderPdfService } from 'app/services/order-pdf.service';
import { OrderReportService } from 'app/services/order-report.service';
import { OrderSellerStatService } from 'app/services/order-seller-stat.service';
import { OrderSendEmailService } from 'app/services/order-send-email.service';
import { OrderService } from 'app/services/order.service';
import { OrderStatService } from 'app/services/order-stat.service';
import { OrderStatusService } from 'app/services/order-status.service';
import { OrderTableListComponent } from 'app/components/shared/order-table-list/order-table-list.component';
import { OrderUtil } from 'app/utils/order.util';
import { PaginationComponent } from 'app/components/shared/pagination/pagination.component';
import { PassengerAvailableStatusService } from 'app/services/passenger-available-status.service';
import { PassengerService } from 'app/services/passenger.service';
import { PaymentFileService } from 'app/services/payment-file.service';
import { PaymentInstallmentsComponent } from 'app/components/shared/payment-installments/payment-installments.component';
import { PaymentInvoiceStatService } from 'app/services/payment-invoice-stat.service';
import {
    PaymentLineFilterDeletePipe,
    PaymentChannelPipe,
    InstallmentOrdinalPipe,
    CalculateOrverDueDatePipe,
} from 'app/pipes/payment.pipe';
import { PaymentService } from 'app/services/payment.service';
import { PaymentStatService } from 'app/services/payment-stat.service';
import { PeriodService } from 'app/services/period.service';
import { PeriodStatService } from 'app/services/period-stat.service';
import { DashboardService } from 'app/services/dashboard.service';
import { ReportService } from 'app/services/report.service';
import { PromotionService } from 'app/services/promotion.service';

// import { PaymentPreviewComponent } from 'app/components/orders/new-order/confirm-order-modal/payment-preview-modal/payment-preview-modal.component';
import { PeriodTableListComponent } from 'app/components/shared/period-table-list/period-table-list.component';
import { ProductService } from 'app/services/product.service';
import { ProductActionService } from 'app/services/product-action.service';
import { ProgramDetailComponent } from 'app/components/shared/program-detail/program-detail.component';
import { ProgramSaleBannerComponent } from 'app/components/program-management/shared/program-sale-banner/program-sale-banner.component';
import { ProgramSalePeriodTableListComponent } from 'app/components/program-management/shared/program-sale-period-table-list/program-sale-period-table-list.component';
import { ProgramSearchComponent } from 'app/components/shared/program-search/program-search.component';
import { ProgramService } from 'app/services/program.service';
import { RefundPaymentOrderModalComponent } from 'app/components/shared/refund-payment-order-modal/refund-payment-order-modal.component';
/**
 * Start Import Directive
 */
import { RestrictInputDirective } from 'app/directives/restrict-input-directive';
import { RoomPassengerService } from 'app/services/room-passenger.service';
import { RoomService } from 'app/services/room.service';
import { RouterModule } from '@angular/router';
import { SearchableDropdownAutoSelectorComponent } from 'app/components/shared/searchable-dropdown-auto-selector/searchable-dropdown-auto-selector.component';
import { SearchableDropdownComponent } from 'app/components/shared/searchable-dropdown/searchable-dropdown.component';
import { Select2Module } from 'ng2-select2';
import { SellerStatService } from 'app/services/seller-stat.service';
import { SharedUtil } from 'app/utils/shared.util';
import { SweetAlert2Module } from '@toverux/ngx-sweetalert2';
import { TelFormatDirective, NumberFormatDirective } from 'app/directives/input.directive';
import { ThaiBathPipe, EnglishExchangeWordPipe, CurrencyUnitPipe, CurrencyNumberPipe } from '../pipes/money.pipe';
import { TitleNamePipe } from 'app/pipes/titlename.pipe';
import { TransportIconDirective } from 'app/directives/transport-icon.directive';
import { TransportationService } from 'app/services/transportation.service';
import { UpperCase } from 'app/directives/uppercase.directive';
import { TourwowCurrencyMaskDirective } from 'app/directives/tourwow-currency-mask.directive';
import { DashboardStatisticOverviewDetailComponent } from 'app/shared/components/dashboard/dashboard-statistic-overview/order-detail/dashboard-statistic-overview-detail.component';
import { TableMobileComponent as DashboardGroupPlanTableMobileComponent } from 'app/shared/components/dashboard/dashboard-group-plan/table-mobile/table-mobile.component';
import { TableDesktopComponent as DashboardGroupPlanTableDesktopComponent } from 'app/shared/components/dashboard/dashboard-group-plan/table-desktop/table-desktop.component';
import { DashboardNextThreemonthSelectorComponent } from 'app/shared/components/dashboard/dashboard-next-threemonth-selector/dashboard-next-threemonth-selector.component';
import { PhoneNumberPipe } from 'app/pipes/phone-number.pipe';

/**
 * End Import Service
 */

/**
 * End Import Component
 */

/**
 * End Import Directive
 */

/**
 * End Import Pipe
 */

/**
 * End Import Util
 */

// Hacks for NgxCurrencyModule to allow user to input minus value at the first time
//  import { InputService } from "../../../node_modules/ngx-currency/src/input.service";
//  InputService.prototype.changeToNegative = function() {
//    if (this.options.allowNegative && this.rawValue.charAt(0) != "-") {
//      this.rawValue = "-" + (this.rawValue || 0);
//    }
//  };

@NgModule({
    exports: [
        AngularMultiSelectModule,
        BsDatepickerModule,
        ClipboardModule,
        ChartsModule,
        CalendarModule,
        FormsModule,
        MaterialModule,
        MdDatepickerModule,
        MdInputModule,
        MdNativeDateModule,
        ModalModule,
        TabsModule,
        ReactiveFormsModule,
        RouterModule,
        SweetAlert2Module,
        Select2Module,
        NgxCurrencyModule,

        ConfirmPopupComponent,
        CountryFilterComponent,
        DetailProgramComponent,
        LoadingComponent,
        NotifyPopupComponent,
        OrderTableListComponent,
        PaginationComponent,
        PaymentInstallmentsComponent,
        PeriodTableListComponent,
        ProgramDetailComponent,
        ProgramSearchComponent,
        SearchableDropdownComponent,
        SearchableDropdownAutoSelectorComponent,
        BookingSearchComponent,
        BookingSearchIIIComponent,
        BookingStatusInfoComponent,
        MonthrangePickerComponent,
        MonthPickerComponent,
        MonthPickerSelectorComponent,
        TourwowDatePickerComponent,
        TextareaAutoHeightComponent,
        UploadFileComponent,
        HeaderTopBarComponent,
        HeaderProductSelectComponent,
        HeaderProductSearchComponent,
        ProductSearchResultLabelComponent,
        ProgramPeriodTableListComponent,
        PaginationLoadMoreComponent,
        ToggleSwitchComponent,
        BreadcrumbComponent,

        AgencyCompanyTypePipe,
        PaymentTypePipe,
        OrderStatusDisplayTypePipe,
        CalendarTitlePipe,
        EngFullMonthPipe,
        FullNamePipe,
        HideNanPipe,
        DisplayNullPipe,
        OrderThaiMonthDatetimePipe,
        OrderThaiMonthDatetime2LinePipe,
        OrderStatusPipe,
        PaymentStatusPipe,
        PaymentInstallmentStatusPipe,
        AgencyObStatusPipe,
        PeriodStatusPipe,
        PaymentLineFilterDeletePipe,
        PaymentChannelPipe,
        InstallmentOrdinalPipe,
        CalculateOrverDueDatePipe,
        ProgramStatusPipe,
        RoomStatusPipe,
        ThaiBathPipe,
        EnglishExchangeWordPipe,
        CurrencyUnitPipe,
        CurrencyNumberPipe,
        ThaidatePipe,
        ThaiFullMonthYearDatePipe,
        ThaiMonthDatePipe,
        ThaiMonthDatetimePipe,
        DayOfWeekInThaiPipe,
        TitleNamePipe,
        TourWowProThaiDateRangePipe,
        TourWowProThaiMonthRangePipe,
        TourWowProThaiMonthAbbrRangePipe,
        DisplayCountriesPipe,
        SalesTypePipe,
        Nl2BrPipe,
        SortPipe,
        KeyValuePipe,
        CountArrayPipe,
        SumAmountArrayPipe,
        PhoneNumberPipe,
        DashboardWelcomeComponent,
        DashboardSalesmanSalesComponent,
        DashboardProductSalesComponent,
        DashboardCompanySalesComponent,
        DashboardAgencySalesComponent,
        DashboardProductSalesTableComponent,
        DashboardAgencySalesTableComponent,
        DashboardLastThreemonthSelectorComponent,
        DashboardNextThreemonthSelectorComponent,
        DashboardGroupPlanComponent,
        DashboardStatisticOverviewComponent,
        DashboardStatisticOverviewDetailComponent,
        DashboardTopSalesmanComponent,
        DashboardTotalSalesComponent,
        DashboardGroupPlanTableDesktopComponent,
        DashboardGroupPlanTableMobileComponent,
        RestrictInputDirective,
        TelFormatDirective,
        NumberFormatDirective,
        TransportIconDirective,
        TourwowCurrencyMaskDirective,
        UpperCase,
        ProgramSaleBannerComponent,
        ProgramSalePeriodTableListComponent,
    ],
    imports: [
        AngularMultiSelectModule,
        BsDatepickerModule.forRoot(),
        ChartsModule,
        ClipboardModule,
        CalendarModule.forRoot(),
        CommonModule,
        FormsModule,
        HttpClientModule,
        MaterialModule,
        MdDatepickerModule,
        MdInputModule,
        MdNativeDateModule,
        ModalModule.forRoot(),
        TabsModule.forRoot(),
        RouterModule,
        ReactiveFormsModule,
        SweetAlert2Module.forRoot(),
        Select2Module,
        NgxCurrencyModule,
    ],
    declarations: [
        ConfirmPopupComponent,
        CountryFilterComponent,
        DetailProgramComponent,
        LoadingComponent,
        ImagePreviewPopupComponent,
        NotifyPopupComponent,
        OrderTableListComponent,
        PaginationComponent,
        PaymentInstallmentsComponent,
        // PaymentPreviewComponent,
        PeriodTableListComponent,
        ProgramDetailComponent,
        ProgramSearchComponent,
        RefundPaymentOrderModalComponent,
        SearchableDropdownComponent,
        SearchableDropdownAutoSelectorComponent,
        BookingSearchComponent,
        BookingSearchIIIComponent,
        BookingStatusInfoComponent,
        HeaderTopBarComponent,
        HeaderProductSelectComponent,
        HeaderProductSearchComponent,
        ProductSearchResultLabelComponent,
        ProgramPeriodTableListComponent,
        PaginationLoadMoreComponent,
        ToggleSwitchComponent,
        BreadcrumbComponent,

        MonthrangePickerComponent,
        MonthPickerComponent,
        MonthPickerSelectorComponent,
        TourwowDatePickerComponent,
        TextareaAutoHeightComponent,
        UploadFileComponent,
        NoteModalComponent,
        Note1dot6ModalComponent,

        AgencyCompanyTypePipe,
        PaymentTypePipe,
        OrderStatusDisplayTypePipe,
        CalendarTitlePipe,
        EngFullMonthPipe,
        FullNamePipe,
        HideNanPipe,
        DisplayNullPipe,
        OrderStatusPipe,
        OrderThaiMonthDatetimePipe,
        OrderThaiMonthDatetime2LinePipe,
        PaymentStatusPipe,
        PaymentInstallmentStatusPipe,
        AgencyObStatusPipe,
        PeriodStatusPipe,
        PaymentLineFilterDeletePipe,
        PaymentChannelPipe,
        InstallmentOrdinalPipe,
        CalculateOrverDueDatePipe,
        ProgramStatusPipe,
        RoomStatusPipe,
        ThaiBathPipe,
        EnglishExchangeWordPipe,
        CurrencyUnitPipe,
        CurrencyNumberPipe,
        ThaidatePipe,
        ThaiFullMonthYearDatePipe,
        ThaiMonthDatePipe,
        ThaiMonthDatetimePipe,
        DayOfWeekInThaiPipe,
        TitleNamePipe,
        TourWowProThaiDateRangePipe,
        TourWowProThaiMonthRangePipe,
        TourWowProThaiMonthAbbrRangePipe,
        DisplayCountriesPipe,
        SalesTypePipe,
        Nl2BrPipe,
        SortPipe,
        KeyValuePipe,
        CountArrayPipe,
        SumAmountArrayPipe,
        PhoneNumberPipe,
        ProgramSaleBannerComponent,
        ProgramSalePeriodTableListComponent,

        DashboardWelcomeComponent,
        DashboardSalesmanSalesComponent,
        DashboardProductSalesComponent,
        DashboardCompanySalesComponent,
        DashboardAgencySalesComponent,
        DashboardProductSalesTableComponent,
        DashboardAgencySalesTableComponent,
        DashboardLastThreemonthSelectorComponent,
        DashboardNextThreemonthSelectorComponent,
        DashboardGroupPlanComponent,
        DashboardStatisticOverviewComponent,
        DashboardStatisticOverviewDetailComponent,
        DashboardTopSalesmanComponent,
        DashboardTotalSalesComponent,
        DashboardGroupPlanTableDesktopComponent,
        DashboardGroupPlanTableMobileComponent,
        TelFormatDirective,
        NumberFormatDirective,
        TransportIconDirective,
        RestrictInputDirective,
        TourwowCurrencyMaskDirective,

        UpperCase,
    ],
    entryComponents: [
        ImagePreviewPopupComponent,
        RefundPaymentOrderModalComponent,
        NoteModalComponent,
        Note1dot6ModalComponent,
        MonthPickerSelectorComponent,
    ],
    providers: [
        AgencyAvailableStatusService,
        AgencyService,
        AgencyStatService,
        // AuthService,
        BankService,
        BsModalRef,
        CaterogyTransportService,
        ExcelService,
        HttpService,
        HttpProService,
        InfoService,
        LocationService,
        MemberService,
        OrderPassengerService,
        OrderPaymentService,
        OrderPdfService,
        OrderReportService,
        OrderService,
        OrderStatService,
        OrderStatusService,
        OrderSendEmailService,
        PassengerService,
        PassengerAvailableStatusService,
        PaymentService,
        PaymentFileService,
        PaymentStatService,
        PeriodService,
        PeriodStatService,
        ProductService,
        ProductActionService,
        ProgramService,
        ReportService,
        RoomService,
        RoomPassengerService,
        TransportationService,
        SellerStatService,
        OrderSellerStatService,
        PaymentInvoiceStatService,
        OrderListAsExcelService,
        DashboardService,
        CurrencyService,
        LogService,
        PromotionService,
        DecimalPipe,

        Base64Util,
        SharedUtil,
        OrderUtil,
    ],
})
export class SharedModule {}
