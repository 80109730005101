import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { ENDPOINTS } from 'app/variables/endpoints';
import { LoginResponse } from 'app/models/auth/login-response.interface';
import { HttpProService } from './base/http-pro.service';
import { AuthService } from './auth.service';
import { environment } from 'environments/environment';

@Injectable()
export class MemberAuthService {
    constructor(private httpService: HttpProService, private authService: AuthService) {}

    public login(username: string, password: string): Observable<LoginResponse> | null {
        const url = ENDPOINTS.AUTH.LOGIN;
        return this.httpService.post(url, { username, password }, false);
    }

    public logout(): void {
        this.authService.deleteJWTToken();
    }
}
