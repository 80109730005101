<div class="main-content add-passenge">

  <h2 class="title text-center">
    {{title}}
    <button (click)="closeModal()">
      <img src="assets/img/icon-cross.png" alt="">
    </button>
  </h2>

  <div class="main-content__wrap">
    <form [formGroup]="refundPaymentOrderForm" (ngSubmit)="submit()" novalidate #confirmPaymentNgForm="ngForm">
      <div class="form-group row">
        <label for="total" class="col-sm-3 col-form-label">จำนวนเงินที่คืน <span class="red">*</span> :</label>
        <div class="col-sm-6">
          <input formControlName="total" type='text' class="input" maxlength="80" app-restrict-input="float">
          <span class="error-message" *ngIf="confirmPaymentNgForm.submitted && refundPaymentOrderForm.get('total').invalid">กรุณากรอกข้อมูล</span>
        </div>
      </div>
      <div class="form-group row">
        <label for="note" class="col-sm-3 col-form-label">เหตุผลการคืนเงิน <span class="red">*</span>:</label>
        <div class="col-sm-9">
          <textarea formControlName="reason" name="" id="note" cols="30" rows="10" class="textarea" maxlength="500"></textarea>
          <span class="error-message" *ngIf="confirmPaymentNgForm.submitted && refundPaymentOrderForm.get('reason').invalid">กรุณากรอกข้อมูล</span>
        </div>
      </div>
      <div class="form-group row">
        <label for="note" class="col-sm-3 col-form-label"></label>
        <div class="col-sm-9">
            <button class="btn btn--primary btn--half" type="submit">ยืนยัน</button>
            <button class="btn btn--half" type="button" (click)="closeModal()">ยกเลิก</button>
        </div>
      </div>
    </form>
  </div>

</div>
