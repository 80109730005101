import * as moment from 'moment';

import { Pipe, PipeTransform } from '@angular/core';

const thaiMonths = [
  'มกราคม',
  'กุมภาพันธ์',
  'มีนาคม',
  'เมษายน',
  'พฤษภาคม',
  'มิถุนายน',
  'กรกฎาคม',
  'สิงหาคม',
  'กันยายน',
  'ตุลาคม',
  'พฤศจิกายน',
  'ธันวาคม'
];

const thaiMonthsAbbreviation = [
  'ม.ค.',
  'ก.พ.',
  'มี.ค.',
  'เม.ย.',
  'พ.ค.',
  'มิ.ย.',
  'ก.ค.',
  'ส.ค.',
  'ก.ย.',
  'ต.ค.',
  'พ.ย.',
  'ธ.ค.'
];

const dayOfWeekInThai = {
  'Mon': 'จันทร์',
  'Tue': 'อังคาร',
  'Wed': 'พุธ',
  'Thu': 'พฤหัสบดี',
  'Fri': 'ศุกร์',
  'Sat': 'เสาร์',
  'Sun': 'อาทิตย์'
};

@Pipe({
  name: 'thaidate'
})
export class ThaidatePipe implements PipeTransform {
  transform(value: any, args?: any): any {
    if (!value) {
      return (args[0] ? args[0] : '');
    } else {
      return (
        moment(value).date() +
        '/' +
        (moment(value).month() + 1) +
        '/' +
        (moment(value).year() + 543)
      );
    }
  }
}

@Pipe({
  name: 'thaiFullMonthYearDate'
})
export class ThaiFullMonthYearDatePipe implements PipeTransform {
  transform(value: any): any {
    if (!value) {
      return '';
    }
    const date = moment(value);
    if (!date.isValid()) {
      return '';
    }

    const thaiDate = date.add(543, 'y');
    let dateFormat = 'DD/MM/YYYY';
    const dateAsString = thaiDate.format(dateFormat);

    const dateAsArray = dateAsString.split('/');
    const monthIndex = dateAsArray['1'] - 1;
    const monthSelected = thaiMonths[monthIndex];
    dateAsArray['1'] = monthSelected;
    const result = dateAsArray.join(' ');
    return result;
  }
}

@Pipe({
  name: 'thaiMonthDate'
})
export class ThaiMonthDatePipe implements PipeTransform {
  transform(
    value: any,
    useThaiMonth?: boolean,
    includeTime?: boolean,
    includeYear?: boolean
  ): any {
    if (!value) {
      return '';
    }
    const date = moment(value);
    if (!date.isValid()) {
      return '';
    }

    const thaiDate = date.add(543, 'y');
    const day = new Date(Date.parse(value)).getDate();
    let dateFormat = includeTime ? 'DD/MM/YY เวลา HH:mm' : 'DD/MM/YY';
    dateFormat = includeYear ? dateFormat : 'DD/MM';
    const dateAsString = thaiDate.format(dateFormat.replace('DD', day.toString()));

    if (useThaiMonth) {
      const dateAsArray = dateAsString.split('/');
      const monthIndex = dateAsArray['1'] - 1;
      const monthSelected = thaiMonthsAbbreviation[monthIndex];
      
      dateAsArray[0] = day.toString();
      dateAsArray[1] = monthSelected;
      const result = dateAsArray.join(' ');
      return result;
    }

    return dateAsString;
  }
}

@Pipe({
  name: 'thaiMonthDateTime'
})
export class ThaiMonthDatetimePipe implements PipeTransform {
  transform(value: any, useAbbr: boolean = false): any {
    if (!value) {
      return;
    }
    const date = moment(value);
    if (!date.isValid()) {
      return;
    }

    const thaiDate = date.add(543, 'y');
    const dateAsString = thaiDate.format('DD/MM/YY เวลา HH:mm');

    const dateAsArray = dateAsString.split('/');
    const monthIndex = dateAsArray['1'] - 1;
    const monthSelected = useAbbr
      ? thaiMonthsAbbreviation[monthIndex]
      : thaiMonths[monthIndex];
    dateAsArray['1'] = monthSelected;
    const result = dateAsArray.join(' ');
    return result;
  }
}

@Pipe({
  name: 'orderThaiMonthDateTime'
})
export class OrderThaiMonthDatetimePipe implements PipeTransform {

  transform(value: any, useAbbr: boolean = false): any {
    if (!value) {
      return;
    }
    const date = moment(value);
    if (!date.isValid()) {
      return;
    }

    const thaiDate = date.add(543, 'y');
    const dateAsString = thaiDate.format('DD/MM/YY HH:mm');

    const dateAsArray = dateAsString.split('/');
    const monthIndex = dateAsArray['1'] - 1;
    const monthSelected = (useAbbr) ? thaiMonthsAbbreviation[monthIndex] : thaiMonths[monthIndex];
    dateAsArray['1'] = monthSelected;
    const result = dateAsArray.join(' ');
    return result;
  }

}
@Pipe({
  name: 'orderThaiMonthDateTime2Line'
})
export class OrderThaiMonthDatetime2LinePipe implements PipeTransform {

  transform(value: any, useAbbr: boolean = false): any {
    if (!value) {
      return;
    }
    const date = moment(value);
    if (!date.isValid()) {
      return;
    }

    const thaiDate = date.add(543, 'y');
    const dateAsString = thaiDate.format('DD/MM/YY');
    const time = thaiDate.format('HH:mm');

    const dateAsArray = dateAsString.split('/');
    const monthIndex = dateAsArray['1'] - 1;
    const monthSelected = (useAbbr) ? thaiMonthsAbbreviation[monthIndex] : thaiMonths[monthIndex];
    dateAsArray['1'] = monthSelected;
    const result = dateAsArray.join(' ');
    return `${result}<br>${time}`;
  }

}

@Pipe({
  name: 'thaidaterange'
})
export class TourWowProThaiDateRangePipe implements PipeTransform {

  transform(date1: string, date2: string): any {
    let result = '';
    let date1Obj = moment(date1);
    let date2Obj = moment(date2);
    if(!date1Obj.isValid() || !date2Obj.isValid()) {
      return result;
    }

    if(date1Obj.date() == date2Obj.date() &&  date1Obj.month() == date2Obj.month() && date1Obj.year() == date2Obj.year()) {
      result = `${date2Obj.format('DD')} ${thaiMonthsAbbreviation[date1Obj.month()]} ${this.convertToBuddhistYear(date1Obj)}`;
    } else if(date1Obj.month() == date2Obj.month() && date1Obj.year() == date2Obj.year()) {
      result = `${date1Obj.format('DD')} - ${date2Obj.format('DD')} ${thaiMonthsAbbreviation[date1Obj.month()]} ${this.convertToBuddhistYear(date1Obj)}`;
    } else if (date1Obj.month() != date2Obj.month() && date1Obj.year() != date2Obj.year()) {
      result = `${date1Obj.format('DD')} ${thaiMonthsAbbreviation[date1Obj.month()]} ${this.convertToBuddhistYear(date1Obj)} - ${date2Obj.format('DD')} ${thaiMonthsAbbreviation[date2Obj.month()]} ${this.convertToBuddhistYear(date2Obj)}`;
    } else {
      result = `${date1Obj.format('DD')} ${thaiMonthsAbbreviation[date1Obj.month()]} - ${date2Obj.format('DD')} ${thaiMonthsAbbreviation[date2Obj.month()]} ${this.convertToBuddhistYear(date2Obj)}`;
    }
    return result;
  }

  private convertToBuddhistYear(moment: moment.Moment): string {
    return (+moment.format('YYYY') + 543).toString().slice(2);
  }

}

@Pipe({
  name: 'thaimonthrange'
})
export class TourWowProThaiMonthRangePipe implements PipeTransform {

  transform(date1: string, date2: string): any {
    let result = '';
    let date1Obj = moment(date1);
    let date2Obj = moment(date2);
    if(!date1Obj.isValid() || !date2Obj.isValid()) {
      return result;
    }

    if(date1Obj.month() == date2Obj.month() && date1Obj.year() == date2Obj.year()) {
      result = `${thaiMonths[date1Obj.month()]} ${this.convertToBuddhistYear(date1Obj)}`;
    } else if (date1Obj.month() != date2Obj.month() && date1Obj.year() != date2Obj.year()) {
      result = `${thaiMonths[date1Obj.month()]} ${this.convertToBuddhistYear(date1Obj)} - ${thaiMonths[date2Obj.month()]} ${this.convertToBuddhistYear(date2Obj)}`;
    } else {
      result = `${thaiMonths[date1Obj.month()]} - ${thaiMonths[date2Obj.month()]} ${this.convertToBuddhistYear(date2Obj)}`;
    }
    return result;
  }

  private convertToBuddhistYear(moment: moment.Moment): string {
    return (+moment.format('YYYY') + 543).toString().slice(2);
  }

}

@Pipe({
  name: 'thaiMonthAbbrRange'
})
export class TourWowProThaiMonthAbbrRangePipe implements PipeTransform {

  transform(date1: any, date2: any): any {
    let result = '';
    let date1Obj = moment(date1);
    let date2Obj = moment(date2);
    if(!date1Obj.isValid() || !date2Obj.isValid()) {
      return result;
    }

    if(date1Obj.month() == date2Obj.month() && date1Obj.year() == date2Obj.year()) {
      result = `${thaiMonthsAbbreviation[date1Obj.month()]} ${this.convertToBuddhistYear(date1Obj)}`;
    } else if (date1Obj.month() != date2Obj.month() && date1Obj.year() != date2Obj.year()) {
      result = `${thaiMonthsAbbreviation[date1Obj.month()]} ${this.convertToBuddhistYear(date1Obj)} - ${thaiMonthsAbbreviation[date2Obj.month()]} ${this.convertToBuddhistYear(date2Obj)}`;
    } else {
      result = `${thaiMonthsAbbreviation[date1Obj.month()]} - ${thaiMonthsAbbreviation[date2Obj.month()]} ${this.convertToBuddhistYear(date2Obj)}`;
    }
    return result;
  }

  private convertToBuddhistYear(moment: moment.Moment): string {
    return (+moment.format('YYYY') + 543).toString().slice(2);
  }

}

@Pipe({
  name: 'CalandarTitle'
})
export class CalendarTitlePipe implements PipeTransform {
  transform(value: string, useAbbr: boolean = false): any {
    if (!value) {
      return '';
    }
    const date = moment(value);
    if (!date.isValid()) {
      return '';
    }

    const thaiDate = date.add(543, 'y');
    const dateAsString = thaiDate.format('MM YY');
    const dateAsArray = dateAsString.split(' ');
    const monthIndex = +dateAsArray[0] - 1;
    const monthSelected = useAbbr
      ? thaiMonthsAbbreviation[monthIndex]
      : thaiMonths[monthIndex];
    dateAsArray[0] = monthSelected;
    const result = dateAsArray.join(' ');
    return result;
  }
}

@Pipe({
  name: 'thaidayOfWeek'
})
export class DayOfWeekInThaiPipe implements PipeTransform {
  transform(value: string): any {
    if (!value) {
      return '';
    }

    return dayOfWeekInThai[value] || '';
  }
}