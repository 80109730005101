<div
    class="app"
    #root="$implicit"
    dir="ltr"
    [ngClass]="{'app-dark': dark, 'boxed': boxed, 'collapsed-sidebar': collapseSidebar, 'compact-sidebar': compactSidebar, 'customizer-in': customizerIn}"
>
    <md-sidenav-container class="force-container">
        <md-sidenav #sidenav [mode]="sidenavMode" [opened]="sidenavOpen" class="sidebar-area">
            <div class="sidebar-panel force-sidenav">
                <md-toolbar class="sidebar-logo">
                    <div class="force-logo">
                        <div class="logo-text inline-block">
                            <img
                                src="assets/img/logoTourwowPro_white.png"
                                width="100%"
                                alt="Tourwow Pro Backoffice {{version}}"
                            />
                        </div>
                    </div>
                </md-toolbar>
                <div class="sidebar-container">
                    <div class="force-user-thumb text-center">
                        <span class="logo"></span>
                        <span class="force-user-name force-block">{{ username }}</span>
                    </div>
                    <div class="sidenav-scrollbar-container">
                        <perfect-scrollbar>
                            <app-sidenav></app-sidenav>
                        </perfect-scrollbar>
                    </div>
                </div>
            </div>
        </md-sidenav>

        <div>
            <app-header-top-bar
                [isShowCart]="isShowCart"
                [sidenav]="sidenav"
                [displayText]="username"
            ></app-header-top-bar>
            <app-header-product-select *ngIf="isShowProductSelect"></app-header-product-select>
            <app-header-product-search *ngIf="isShowProductSearch"></app-header-product-search>
        </div>

        <div class="force-base-container" #scrollContainer>
            <div class="inner-container">
                <router-outlet (activate)="onActivate($event)"></router-outlet>
            </div>
        </div>
    </md-sidenav-container>
</div>

<!-- ห้ามลบหรือ comment นะครับ ให้ใช้ LoadingService ในการเรียกแทน -->
<app-loading></app-loading>
<!-- ห้ามลบหรือ comment นะครับ ให้ใช้ LoadingService ในการเรียกแทน -->
