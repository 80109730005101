import { Injectable } from "@angular/core";
import { ToastrService } from "ngx-toastr";

@Injectable()
export class ToastWrapperService {

    constructor(
        private _toastrService: ToastrService
    ) { }

    public saveSuccess() {
        this._toastrService.success('บันทึกสำเร็จ');
    }

    public saveError() {
        this._toastrService.error('บันทึกล้มเหลว');
    }

}