import { Injectable } from '@angular/core';
import { HttpProService } from 'app/services/base/http-pro.service';
import { Observable } from 'rxjs';
import { ENDPOINTS } from 'app/variables/endpoints';

@Injectable()
export class PaymentStatService {
  constructor(private http: HttpProService) {}

  getInstallments(): Observable<any> {
    return this.http.get(ENDPOINTS.STAT.PAYMENT.GET_INSTALLMENTS);
  }

}
