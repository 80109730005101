import { Injectable } from '@angular/core';
import { HttpProService } from './base/http-pro.service';
import { Observable } from 'rxjs';
import { ENDPOINTS } from 'app/variables/endpoints';

@Injectable()
export class BankService {
    constructor(private httpService: HttpProService) {}

    getBank(): Observable<any> {
        let url = ENDPOINTS.BANK.GET;
        return this.httpService.get(url, true);
    }
}
