import { Injectable } from '@angular/core';
import { HttpProService } from 'app/services/base/http-pro.service';
import { Observable } from 'rxjs';
import { ENDPOINTS } from 'app/variables/endpoints';

@Injectable()
export class OrderPaymentService {

  constructor(
    private http: HttpProService
  ) { }

  get(orderId: number): Observable<any> {
    return this.http.get(ENDPOINTS.PAYMENT.ORDER.GET.replace('{order_id}', orderId));
  }
  put(paymentId: number, payload: any): Observable<any> {
    return this.http.put(ENDPOINTS.PAYMENT.STATUS.PUT.replace('{payment_id}', paymentId), payload);
  }
  putRefund(paymentId: number, payload: any): Observable<any> {
    return this.http.put(ENDPOINTS.PAYMENT.REFUND.PUT.replace('{payment_id}', paymentId), payload);
  }

}
