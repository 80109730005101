import { OnInit, Component, Input, Output, EventEmitter } from "@angular/core";
import * as moment from 'moment';
import { BsModalRef } from "ngx-bootstrap";

@Component({
    selector: 'app-monthpicker-selector',
    templateUrl: './monthpicker-selector.component.html',
    styleUrls: ['./monthpicker-selector.component.scss']
})
export class MonthPickerSelectorComponent implements OnInit {


    @Input() locale: string;
    @Input() year: number;
    @Input() month: number;
    @Input() min: Date;
    @Input() max: Date;

    @Input() multiple: boolean; // TODO

    @Output() change = new EventEmitter<{ monthIndex: number, year: number }>();

    model: MonthPickerModel;
    minDate: Date;
    maxDate: Date;

    constructor(
        private _modal: BsModalRef
    ) { }

    ngOnInit() {
        
        this.minDate = (this.min ? new  Date (this.min) : null);
        this.maxDate = (this.max ? new  Date (this.max) : null);

        if(this.maxDate)
        {
            console.log(this.maxDate.getFullYear());
            
        }
        if (this.locale) {
            moment.locale(this.locale);
        } else {
            moment.locale('th');
        }

        this.model = new MonthPickerModel();

        if (this.year) {
            this.model.selectedYearMoment = moment().year(this.year);
            this.model.updateYearText();
        }

        if (this.month > -1) {
            this.model.selectedMonthIndex = this.month;
            this.model.selectedMonthMoment = moment().month(this.month);
            if (this.year) this.model.selectedMonthYear = this.year;
        }

        this.onChange(this.model.selectedMonthIndex, this.model.selectedMonthYear);
    }

    decrement() {
        this.model.decrementYear();
        if (this.isShowYears) {
            this.renderYears();
        }
    }

    increment() {
        this.model.incrementYear();
        if (this.isShowYears) {
            this.renderYears();
        }
    }

    selectMonth(index: number) {
        this.model.selectMonth(index);
        //this.onChange(this.model.selectedMonthIndex, this.model.selectedMonthYear);
    }

    isSelectedMonth(monthIndex: number) {
        return this.model.selectedMonthIndex == monthIndex && this.model.selectedMonthYear == this.model.selectedYearMoment.year();
    }

    onChange(monthIndex: number, year: number) {
        this.change.emit({monthIndex: monthIndex, year: year});
    }

    isDisabled(index: number) {
        return (
            (
                (this.minDate && index < this.minDate.getMonth()) 
                && (this.minDate && this.model.selectedYearMoment.year() <= this.minDate.getFullYear())
            )
            || (
                (this.maxDate && index > this.maxDate.getMonth()) 
                && (this.maxDate && this.model.selectedYearMoment.year() >= this.maxDate.getFullYear()) 
            )
            || (this.minDate && this.model.selectedYearMoment.year() < this.minDate.getFullYear())
            || (this.maxDate && this.model.selectedYearMoment.year() > this.maxDate.getFullYear()) 
        );
    }

    isShowYears: boolean;
    years: Array<number> = [];
    toggleShowYears() {
        this.isShowYears = !this.isShowYears;
        this.renderYears();
    }

    renderYears() {
        this.years = [];
        for (let i = 5; i > 0; i--) {
            this.years.push(this.model.selectedYearMoment.year() - i);
        }
        for (let i = 0; i <= 6; i++) {
            this.years.push(this.model.selectedYearMoment.year() + i);
        }
    }

    selectYear(year: number) {
        this.isShowYears = false;
        this.model.selectedYearMoment = moment().year(year);
        this.model.updateYearText(); // in set get aendern
    }

    isSelectedYear(year: number) {
        return this.model.selectedYearMoment.year() === year;
    }

    isDisabledYear(year: number) {
        return (
            (this.maxDate && year > this.maxDate.getFullYear()) 
            || (this.minDate && year < this.minDate.getFullYear())
        ) ;
    }

    submit(): void {
        this.onChange(this.model.selectedMonthIndex, this.model.selectedMonthYear);
        this.closeModal();
    }

    closeModal(): void {
        this._modal.hide();
    }

}

export class MonthPickerModel {
    constructor() {
        this.selectedYearMoment = moment();
        this.updateYearText();

        this.selectedMonthMoment = moment();

        this.months = moment.months();

        this.selectedMonthIndex = this.selectedMonthMoment.month();
        this.selectedMonthYear = this.selectedYearMoment.year();
    }

    selectedYearMoment: moment.Moment;
    selectedYearText: number;

    selectedMonthMoment: moment.Moment;
    selectedMonthIndex: number;
    selectedMonthYear: number;

    months: Array<string> = [];

    updateYearText() {
        this.selectedYearText = +moment(this.selectedYearMoment).format('YYYY');
    }

    selectMonth(index: number) {
        this.selectedMonthMoment = moment().month(index);
        this.selectedMonthIndex = this.selectedMonthMoment.month();
        this.selectedMonthYear = this.selectedYearMoment.year();
    }

    incrementYear() {
        this.selectedYearMoment = this.selectedYearMoment.add(1, "year")
        this.updateYearText();
    }

    decrementYear() {
        this.selectedYearMoment = this.selectedYearMoment.subtract(1, "year")
        this.updateYearText();
    }
}
