import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PAGE_NAME } from 'app/constants/access_log.constant';

import { AuthGuardService as AuthGuard } from 'app/services/auth-guard.service';
import { LoadingComponent } from 'app/components/shared/loading/loading.component';
import { MainComponent } from 'app/theme/main/main.component';
import { PageNotFoundComponent } from 'app/components/auth/page-not-found/page-not-found.component';
import { ServerErrorPageComponent } from 'app/components/auth/server-error-page/server-error-page.component';
import { UnauthorizedPageComponent } from 'app/components/auth/unauthorized-page/unauthorized-page.component';

const appRoutes: Routes = [
    { path: '', loadChildren: 'app/modules/public/public.module#PublicModule' },
    { path: '401', component: UnauthorizedPageComponent, data: { page: PAGE_NAME.ERROR_PAGE_401 } },
    { path: '404', component: PageNotFoundComponent, data: { page: PAGE_NAME.ERROR_PAGE_404 } },
    { path: '500', component: ServerErrorPageComponent, data: { page: PAGE_NAME.ERROR_PAGE_500 } },
    {
        path: '',
        canActivate: [AuthGuard],
        component: MainComponent,
        children: [
            {
                path: 'agency',
                loadChildren: 'app/modules/agency/agency.module#AgencyModule',
            },
            {
                path: 'order',
                loadChildren: 'app/modules/order/order.module#OrderModule',
            },
            {
                path: 'product',
                loadChildren: 'app/modules/product/product.module#ProductModule',
            },
            {
                path: 'report',
                loadChildren: 'app/modules/report/report.module#ReportModule',
            },
        ],
    },
    { path: '**', component: LoadingComponent },
];

@NgModule({
    imports: [RouterModule.forRoot(appRoutes)],
    exports: [RouterModule],
    providers: [],
})
export class RoutingModule {}
