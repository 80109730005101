import * as moment from 'moment';

import { Airline } from './airline';
import { Country } from './country';
import { PRODUCT_CODE } from 'app/constants/product.constant';
import { HelperUtil } from 'app/utils/helper.util';
import { ProgramTour } from './program-tour';

export class PackageTourProgram extends ProgramTour {

  public pro_products_id: number;
  public detail: string;
  public description: string;
  public country?: Country;
  public airline?: Airline;
  public start_at: Date;
  public end_at: Date;

  public commission_company?: number;
  public commission_seller?: number;

  public productFiles?: any;
  public banner_path: string;

  constructor() {
    super();
    this.id = null;
    this.name = null;
    this.price = null;
    this.quantity = 0;
    this.product_type = null;
    this.pro_category_products_id = PRODUCT_CODE.PACKAGE_TOUR.ID;
    this.pro_category_sub_products_id = PRODUCT_CODE.PACKAGE_TOUR.SUB;
    this.status = null;

    this.pro_products_id = null;
    this.detail = null;
    this.description = null;
    this.start_at = new Date();
    this.end_at = new Date();

    this.productFiles = null;
    this.banner_path = null;
  }

  public get totalQuantity(): number {
    return this.quantity;
  }

  public get numberOfPassenger(): number {
    return this.quantity;
  }

  public get totalPrice(): number {
    const rawValue = this.quantity * this.price;
    const price = Math.round(rawValue * 100) / 100;
    return price;
  }

  public get periodDate(): string {
    let result = '';
    const thaiMonthsAbbreviation = ['ม.ค.', 'ก.พ.', 'มี.ค.', 'เม.ย.', 'พ.ค.', 'มิ.ย.', 'ก.ค.', 'ส.ค.', 'ก.ย.', 'ต.ค.', 'พ.ย.', 'ธ.ค.'];
    const date1Obj = moment(this.start_at);
    const date2Obj = moment(this.end_at);
    if (!date1Obj.isValid() || !date2Obj.isValid()) {
      return result;
    }

    if (date1Obj.date() === date2Obj.date() &&  date1Obj.month() === date2Obj.month() && date1Obj.year() === date2Obj.year()) {
      result = `${date2Obj.format('DD')} ${thaiMonthsAbbreviation[date1Obj.month()]} ${this.convertToBuddhistYear(date1Obj)}`;
    } else if(date1Obj.month() === date2Obj.month() && date1Obj.year() === date2Obj.year()) {
      result = `${date1Obj.format('DD')} - ${date2Obj.format('DD')} ${thaiMonthsAbbreviation[date1Obj.month()]} ${this.convertToBuddhistYear(date1Obj)}`;
    } else if (date1Obj.month() !== date2Obj.month() && date1Obj.year() !== date2Obj.year()) {
      result = `${date1Obj.format('DD')} ${thaiMonthsAbbreviation[date1Obj.month()]} ${this.convertToBuddhistYear(date1Obj)} - ${date2Obj.format('DD')} ${thaiMonthsAbbreviation[date2Obj.month()]} ${this.convertToBuddhistYear(date2Obj)}`;
    } else {
      result = `${date1Obj.format('DD')} ${thaiMonthsAbbreviation[date1Obj.month()]} - ${date2Obj.format('DD')} ${thaiMonthsAbbreviation[date2Obj.month()]} ${this.convertToBuddhistYear(date2Obj)}`;
    }
    return result;
  }

  public getCountryName(prefix: string = ''): string {
      if (this.country) {
        return prefix + this.country.name_th;
      }

      return '';
  }

  public getAirlineName(prefix?: string): string {
      // Check object airline is not empty.
      if (this.airline) {
        return prefix + this.airline.name;
      }

      return '';
  }

  public serialize(): any {
    return {
      id: this.id,
      pro_category_products_id: this.pro_category_products_id,
      pro_category_sub_products_id: this.pro_category_sub_products_id,
      pro_products_id: this.pro_products_id,
      go_date: this.start_at !== null ? moment(this.start_at).format() : null,
      back_date: this.end_at !== null ? moment(this.end_at).format() : null,
      order_detail: HelperUtil.n2br(this.detail),
      quantity: this.quantity,
      price: +this.price,
      transportations_id: this.airline ?  this.airline.id : null,
      is_delete: this.status === 'delete' ? true : undefined
    }
  }

  public deserialize(input: any): any {
    Object.assign(this, input);

    if (input.country) {
      this.country = new Country().deserialize(input.country);
    }

    if (input.countries && input.countries.length > 0) {
      this.country = new Country().deserialize(input.countries[0]);
    }

    if (input.transportation) {
      this.airline = new Airline().deserialize(input.transportation);
    }

    return this;
  }

  private convertToBuddhistYear(moment: moment.Moment): string {
    return (+moment.format('YYYY') + 543).toString().slice(2);
  }
}
