import { Injectable } from '@angular/core';
import { Http, RequestOptions, Headers, Response, Request } from '@angular/http';
import { catchError, retry, retryWhen, timeout } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

import { VARIABLES } from 'app/variables/variables';
import { environment } from 'environments/environment';

import { AuthService } from 'app/services/auth.service';

@Injectable()
export class HttpService {
    constructor(private http: HttpClient, private authService: AuthService) {}

    // get
    public get(url: string, hasAuthorization: boolean = true): Observable<Response> {
        return Observable.create((observer) => {
            this.getRequestOptions(hasAuthorization).subscribe((headers) => {
                this.http
                    .get(environment.apiUrl + url, headers)
                    .pipe(timeout(10000), retry(5))
                    .subscribe((result) => {
                        observer.next(result);
                    }, this.onHttpRequestFail);
            }, this.onCannotCreateRequestHeader);
        });
    }

    // post
    public post(url: string, body: any, hasAuthorization: boolean = true): Observable<Response> {
        return Observable.create((observer) => {
            this.getRequestOptions(hasAuthorization).subscribe((headers) => {
                this.http
                    .post(environment.apiUrl + url, body, headers)
                    .pipe(retry(5))
                    .subscribe((result) => {
                        observer.next(result);
                    }, this.onHttpRequestFail);
            }, this.onCannotCreateRequestHeader);
        });
    }

    // put
    public put(url: string, body: any, hasAuthorization: boolean = true): Observable<Response> {
        return Observable.create((observer) => {
            this.getRequestOptions(hasAuthorization).subscribe((headers) => {
                this.http
                    .put(environment.apiUrl + url, body, headers)
                    .pipe(retry(5))
                    .subscribe((result) => {
                        observer.next(result);
                    }, this.onHttpRequestFail);
            }, this.onCannotCreateRequestHeader);
        });
    }

    // delete
    public delete(url: string, hasAuthorization: boolean = true): Observable<Response> {
        return Observable.create((observer) => {
            this.getRequestOptions(hasAuthorization).subscribe((headers) => {
                this.http
                    .delete(environment.apiUrl + url, headers)
                    .pipe(retry(5))
                    .subscribe((result) => {
                        observer.next(result);
                    }, this.onHttpRequestFail);
            }, this.onCannotCreateRequestHeader);
        });
    }

    // custom request
    public request(request: Request, headers: any): Observable<any> {
        request.url = environment.apiUrl + request.url;
        headers.responseType = 'json';
        return this.http.request(request.method.toString(), request.url, headers);
    }

    public getRequestOptions(hasAuthorization: boolean): Observable<any> {
        return Observable.create((observer) => {
            let httpHeaders = new HttpHeaders({
                Accept: 'application/json',
                'Content-Type': 'application/json',
            });

            let requestOptions = {
                headers: httpHeaders,
                responseType: 'json',
            };

            if (hasAuthorization) {
                requestOptions.headers = requestOptions.headers.set('Authorization', 'tokenIdtesting');
                observer.next(requestOptions);
                // firebase.auth().currentUser.getIdToken().then(tokenId => {
                //   requestOptions.headers = requestOptions.headers.set('Authorization', tokenId);
                //   observer.next(requestOptions);
                // });
            } else {
                observer.next(requestOptions);
            }
        });
    }

    private onHttpRequestFail(reason: any): Observable<any> {
        return Observable.throw(reason);
    }

    private onCannotCreateRequestHeader(reason: any): Observable<any> {
        return Observable.throw(reason);
    }

    getQueryString(obj: any): string {
        let params = new URLSearchParams();
        for (let key in obj) {
            let param = obj[key];
            if (typeof param === 'object') {
                param = JSON.stringify(param);
            }
            params.set(key, param);
        }
        return params.toString();
    }
}
