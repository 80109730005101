export const ENDPOINTS_ORDER_v3: any = {
    GET_LAST: 'v3/professional/orders/{order_id}',
    GET_ACTIVE: 'v3/professional/orders/{order_id}/invoice',
    GET_SUMMARY: 'v3/professional/orders/{order_id}/summary',
    GET_FILES: 'v3/professional/orders/{order_id}/files',
    GET_INVOICE_VERSIONS:'v3/professional/orders/{order_id}/invoice_versions',
    POST: 'v3/professional/orders',
    PUT_ACTION: 'v3/professional/orders/{order_id}/invoices/actions/{action}',
    PUT_LAST: 'v3/professional/orders/{order_id}',
    GET_LISTING: 'v3/professional/orders/listing',
    GET_PDF: 'v3/professional/orders/{order_id}/invoice/file',
    GET_PDF_BEFORE_UPDATE: 'v3/professional/orders/{order_id}/invoice/file/before_update',
    INVOICE: {
        GET_LISTING: 'v3/professional/orders/invoice/listing',
        GET_PDF: 'v3/professional/orders/{order_id}/invoice/{invoice_id}/file',
        EMAIL_FORM: 'v3/professional/orders/{order_id}/invoices/email_form'
    },
    CANCEL_ORDER: 'v3/professional/orders/{order_id}/cancel',
    BATCH_CANCEL_ORDER_ALL: 'v3/professional/orders/cancel/all',
    BATCH_CANCEL_ORDER: 'v3/professional/orders/cancel',
    POST_FILE: 'v3/professional/orders/{order_id}/files/upload',
    DELETE_FILE: 'v3/professional/orders/{order_id}/files/remove/{file_id}',
    PUT_NOTE: 'v3/professional/orders/{order_id}/note',
    GET_VERIFY: 'v3/professional/orders/{order_id}/verify_orders',
    EMAIL_CONFIRM: 'v3/professional/orders/{order_id}/email_confirm',
    PUT_ORDER_ACTION: 'v3/professional/orders/{order_id}/actions/{action}',
}

export const ENDPOINTS_PAYMENT_v3: any = {
    GET_INFO: 'v3/professional/orders/{order_id}/payments',
    GET_LIST: 'v3/professional/orders/payments/list',
    TRANSACTION: {        
        POST: 'v3/professional/orders/{order_id}/payments/transections',
        APPROVE: 'v3/professional/orders/{order_id}/payments/transections/{payment_id}/actions/approved',
        PENDING: 'v3/professional/orders/{order_id}/payments/transections/{payment_id}/actions/pending',
        DELETE: 'v3/professional/orders/{order_id}/payments/transections/{payment_id}',
        LISTING: '/v3/professional/orders/transections/listing',
        REPORT: {
            STAT: '/v3/professional/orders/transections/stat/monthly',
            DATA: '/v3/professional/orders/transections/reports'
        },
        ACTION: {
            WAITING_FOR_PAYMENT: 'v3/professional/orders/{order_id}/payments/actions/waiting_paid',
            WAITING_FOR_CONFIRMATION_DEPOSIT: 'v3/professional/orders/{order_id}/payments/actions/pending_approve_paid_1',
            PAID: 'v3/professional/orders/{order_id}/payments/actions/approved_paid_1',
            PAID_FULL: 'v3/professional/orders/{order_id}/payments/actions/approved_paid_full',
        }
    }
}