
export class HelperUtil {

  constructor() { }

  public static n2br(text: string): string {
    if (this.isNullOrEmpty(text)) {
      return text;
    }

    return text.replace(/<[^>]*>/g, '').replace(/\n/g, '<br />');
  }

  public static br2n(text: string): string {
    if (this.isNullOrEmpty(text)) {
      return text;
    }
    
    return text.replace(/<br\s*[\/]?>/gi, "\n")
  }

  public static isNullOrEmpty(value: string) {
    return value == null || value.trim() === '' || typeof value === 'undefined';
  }

  public static integerToKUnit(value: number): string {
    return `${Math.floor(value / 1000).toString().replace(/(\d)(?=(\d{3})+$)/g, "$1,")}K`;
  }

}
