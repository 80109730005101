import { Pipe, PipeTransform } from '@angular/core';
import { ROOM_TYPE } from 'app/constants/room-type.constant';
import { REGISTER_STATUS } from 'app/constants/agency-online-booking.constant';


@Pipe({
  name: 'orderStatus'
})
export class OrderStatusPipe implements PipeTransform {
  transform(value: any): any {
    switch (value) {
      case 11: {
        return 'จอง';
      }
      case 24: {
        return 'ยกเลิก';
      }
      default: {
        return '';
      }
    }
  }
}

@Pipe({
  name: 'programStatus'
})
export class ProgramStatusPipe implements PipeTransform {
  transform(value: any): any {
    switch (value) {
      case 0: {
        return 'แบบร่าง';
      }
      case 1: {
        return 'เปิดขาย';
      }
      case 4: {
        return 'ยกเลิก';
      }
      default: {
        return '';
      }
    }
  }
}

@Pipe({
  name: 'periodStatus'
})
export class PeriodStatusPipe implements PipeTransform {
  transform(value: any, inventory?: any, forExcel?: any): any {
    switch (value) {
      case 0: {
        return 'แบบร่าง';
      }
      case 1: {
        if (forExcel) {
          return inventory > 0 ? 'ว่าง' : 'เต็ม';
        }else {
          return inventory > 0 ? `ว่าง (${inventory})` : 'เต็ม';
        }
      }
      case 2: {
        return 'ยังไม่เปิดขาย';
      }
      case 3: {
        return 'ปิดกรุ๊ป';
      }
      case 4: {
        return 'ยกเลิก';
      }
      default: {
        return '';
      }
    }
  }
}

@Pipe({
  name: 'roomStatus'
})
export class RoomStatusPipe implements PipeTransform {
  transform(roomType: any): any {
    let result = '';
    if (roomType === ROOM_TYPE.SINGLE_BEDDED_ROOM) {
      result = 'พักเดี่ยว';
    } else if (roomType === ROOM_TYPE.TWIN_BEDDED_ROOM || roomType === ROOM_TYPE.DOUBLE_BEDDED_ROOM) {
      result = 'พักคู่';
    } else if (roomType === ROOM_TYPE.TRIPLE_BEDDED_ROOM) {
      result = 'พักสาม';
    } else if (roomType === ROOM_TYPE.QUADRUBLE) {
      result = 'พักสี่';
    }
    return result;
  }
}


@Pipe({
  name: 'paymentStatus'
})
export class PaymentStatusPipe implements PipeTransform {
  transform(value: any): any {
    switch (value) {
      case 11: {
        return 'ชำระเงินงวดมัดจำแล้ว';
      }
      case 12: {
        return 'ชำระเงินงวดเต็มแล้ว';
      }
      default: {
        return 'รอการชำระเงิน';
      }
    }
  }
}

@Pipe({
  name: 'paymentInstallmentStatus'
})
export class PaymentInstallmentStatusPipe implements PipeTransform {
  transform(value: any): any {
    switch (value) {
      case 'paid': {
        return 'ชำระเงินแล้ว';
      }
      case 'delay': {
        return 'เลยกำหนด';
      }
      case 'almost_delay': {
        return 'ใกล้ถึงกำหนด';
      }
      default: {
        return '';
      }
    }
  }
}

@Pipe({
  name: 'agencyObStatus'
})
export class AgencyObStatusPipe implements PipeTransform {
  transform(value: any): any {
    switch (value) {
      case REGISTER_STATUS.AVAILABLE: {
        return 'เปิดใช้งาน';
      }
      case REGISTER_STATUS.PENDING_APPROVE: {
        return 'รออนุมัติ';
      }
      case REGISTER_STATUS.REJECTED: {
        return 'ปิดใช้งาน';
      }
      default: {
        return '';
      }
    }
  }
}
