import { Deserializable } from './deserializable';
import { User } from './user';

export class Wholesale extends User implements IWholesale, Deserializable {
    public roles_slug: string[];
    public supplier: ISupplier;

    deserialize(input: any) {
        if (input == null) {
            return null;
        }

        this.first_name = input.user.first_name;
        this.last_name = input.user.last_name;
        this.nick_name = input.user.nick_name;
        this.roles_slug = input.user.roles_slug;

        this.supplier = {
            id: input.supplier.id,
            is_channel_ob: input.supplier.is_channel_ob,
            name_th: input.supplier.name_th,
            product_twp_slug: input.supplier.product_twp_slug,
        };

        return this;
    }
}

export interface IWholesale {
    roles_slug: string[];
    supplier: ISupplier;
}

export interface ISupplier {
    id: number;
    is_channel_ob: number;
    name_th: string;
    product_twp_slug: string;
}
