import { Injectable } from '@angular/core';
import { HttpProService } from 'app/services/base/http-pro.service';
import { Observable } from 'rxjs/Observable';
import { ENDPOINTS } from 'app/variables/endpoints';

@Injectable()
export class OrderStatusService {

  constructor(
    private http: HttpProService
  ) { }

  put(orderId: number, body: any): Observable<any> {
    return this.http.put(ENDPOINTS.ORDER.STATUS.PUT.replace('{order_id}', orderId), body);
  } 

}
