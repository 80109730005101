import { Component, OnInit, Input } from '@angular/core';
import { LoadingService } from 'app/core/services/loading.service';

@Component({
  selector: 'app-loading',
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.css']
})
export class LoadingComponent implements OnInit {

  @Input('loading') public hasLoading = false;

  constructor(
    private _loadingService: LoadingService
  ) { }

  ngOnInit() {
    this._loadingService.loadingStatus.subscribe(isLoading => {
      this.hasLoading = isLoading;
    });
  }

}
