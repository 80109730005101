declare var ga: Function;

import { Component, ViewEncapsulation, HostListener } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';

import { DateAdapter } from '@angular/material';
import { LogService } from './services/log.service';

import { VARIABLES } from 'app/variables/variables';
import { environment } from 'environments/environment';

@Component({
  selector: 'app-root',
  template: '<router-outlet></router-outlet>',
  encapsulation: ViewEncapsulation.None
})
export class AppComponent {

    constructor(
        private router: Router,
        private _dateAdapter: DateAdapter<Date>,
        private logService: LogService
    ) {
        this._dateAdapter.setLocale('th-TH');
        // Access Log
        this.logService.loadRouting();
        // Google Analytics Tracking Page.
        this.router.events.subscribe(event => {
            if (event instanceof NavigationEnd) {
                ga('create', VARIABLES.GOOGLE_ANALYTICS.trackingId, 'auto', 'clientTracker');
                ga('clientTracker.set', 'page', event.urlAfterRedirects);
                ga('clientTracker.send', 'pageview');
            }
        });
        this.initialiseMsClarity();
    }

    @HostListener('window:popstate', ['$event'])
    onPopState(event) {
        this.hideActivateModal();        
    }
    
    hideActivateModal() {
        let modal = $('.modal .close');
        if (modal.length) {
            modal.click();
        }
    }

    private initialiseMsClarity(): void {
        let script = document.createElement('script');
        script.innerHTML = `(function(c,l,a,r,i,t,y){
            c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
            t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
            y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
        })(window, document, "clarity", "script", "${environment.msClarityId}");`;
        script.type = 'text/javascript';
        script.async = true;
        document.getElementsByTagName('head')[0].appendChild(script);
    }
}
