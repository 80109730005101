import { Deserializable } from './deserializable';

export class SKU implements Deserializable {

  public id: number;
  public pro_products_sku_id: number;
  public sku_name: string;
  public quantity: number;
  public price?: number;
  public goDate?: Date;
  public backDate?: Date;
  public transportaionId?: number;
  public countriesId?: number[];

  public quantityRemaining: number;

  constructor() {
    this.quantity = 0;
  }

  public get totalPrice(): number {
    return this.quantity * this.price;
  }

  public deserialize(input: any): any {
    input.price = +input.price;
    Object.assign(this, input);
    return this;
  }
}
