
export const MENU_SALE = {
    state: '',
    name: 'งานขาย',
    type: 'sub',
    icon: 'handshake-o',
    children: [
        { state: '/sale/booking', name: 'ขายสินค้า' },
        { state: '/order/new-list', name: 'ข้อมูล Order' },
        { state: '/order/overdue-list', name: 'ข้อมูล Order ที่เกินกำหนดชำระเงิน' },
        { state: '/program/share-link', name: 'ส่งลิงค์ Online Booking' }
    ]
};
export const MENU_FINANCE = {
    state: '',
    name: 'งานบัญชี-การเงิน',
    type: 'sub',
    icon: 'money',
    children: [
        //{ state: '/order/new-accounting', name: 'ข้อมูล Invoice' },
        //{ state: '/order/new-invoice-approval', name: 'อนุมัติ Invoice' },
        //{ state: '/order/new-send-invoice', name: 'ส่งเมล Invoice' },
        { state: '/order/payment-list', name: 'ข้อมูล Payment' },
        { state: '/order/transaction-list', name: 'ข้อมูลการรับเงิน' }
    ]
};
export const MENU_PRODUCT = {
    state: '',
    name: 'จัดการข้อมูลสินค้า',
    type: 'sub',
    icon: 'suitcase',
    children: [
        { state: '/v2/product/management', name: 'จัดการข้อมูลสินค้า', externalLink: true },
        { state: '/product/join-tour', name: 'ข้อมูล Join Tour' },
        { state: '/product/package-tour', name: 'ข้อมูล Package Tour' },
        { state: '/program/open-sale-list', name: 'อนุมัติ Join Tour' },
        { state: '/sale/config-channel', name: 'เปิดขายสินค้า' },
        // { state: '/promotion/join-tour', name: 'จัดการโปรโมชั่น' },
    ]
}
export const MENU_AGENCY = {
    state: '',
    name: 'ลูกค้า/Agency',
    type: 'sub',
    icon: 'users',
    children: [
        { state: '/agency/list', name: 'ข้อมูลลูกค้า/Agency' },
        { state: '/agency/add', name: 'เพิ่ม Agency' },
        { state: '/agency/member/list', name: 'สมาชิก Online Booking' },
    ]
};
export const MENU_REPORT = {
    state: '',
    name: 'ดาวน์โหลดข้อมูล',
    type: 'sub',
    icon: 'table',
    children: [
        { state: '/program/list', name: 'ข้อมูล Join Tour', showCart: true, showBreadcrumb: true },
        { state: '/report/order/download', name: 'ข้อมูล Order', showCart: true, showBreadcrumb: true },
        { state: '/report/invoice/download', name: 'ข้อมูล Invoice', showCart: true, showBreadcrumb: true },
        { state: '/report/transaction/download', name: 'ข้อมูลการชำระเงิน', showCart: true, showBreadcrumb: true }
    ]
};

export const MENU = {
    ROLE_OPT: {
        ITEM: [
            {
                state: '',
                name: 'จัดการข้อมูลสินค้า',
                type: 'sub',
                icon: 'suitcase',
                children: [
                    { state: '/v2/product/management', name: 'จัดการข้อมูลสินค้า', outsideLink: true },
                    { state: '/product/join-tour', name: 'ข้อมูล Join Tour' },
                    { state: '/product/package-tour', name: 'ข้อมูล Package Tour' },
                    { state: '/sale/config-channel', name: 'เปิดขายสินค้า' },
                    // { state: '/promotion/join-tour', name: 'จัดการโปรโมชั่น' },
                ]
            },
            MENU_AGENCY,
        ],
        FAVORITE: [
            { state: '/dashboard', name: 'หน้าแรก' },
        ]
    },
    ROLE_FINANCE: {
        ITEM: [
            {   //MENU_SALE
                state: '',
                name: 'งานขาย',
                type: 'sub',
                icon: 'handshake-o',
                children: [
                    { state: '/order/new-list', name: 'ข้อมูล Order' },
                ]
            },
            {   //MENU_FINANCE
                state: '',
                name: 'งานบัญชี-การเงิน',
                type: 'sub',
                icon: 'money',
                children: [
                    { state: '/order/new-accounting', name: 'ข้อมูล Invoice' },
                    { state: '/order/new-send-invoice', name: 'ส่งเมล Invoice' },
                    { state: '/order/payment-list', name: 'ข้อมูล Payment' },
                    { state: '/order/transaction-list', name: 'ข้อมูลการรับเงิน' }
                ]
            },
            { //MENU_AGENCY,
                state: '',
                name: 'ลูกค้า/Agency',
                type: 'sub',
                icon: 'users',
                children: [
                    { state: '/agency/list', name: 'ข้อมูล ลูกค้า/Agency' },
                ]
            },
        ],
        FAVORITE: [
            { state: '/dashboard', name: 'หน้าแรก' },
        ]
    },
    ROLE_FINANCE_MNGR: {
        ITEM: [
            {   //MENU_SALE
                state: '',
                name: 'งานขาย',
                type: 'sub',
                icon: 'handshake-o',
                children: [
                    { state: '/order/new-list', name: 'ข้อมูล Order' },
                ]
            },
            MENU_FINANCE,
            {   //MENU_AGENCY,
                state: '',
                name: 'ลูกค้า/Agency',
                type: 'sub',
                icon: 'users',
                children: [
                    { state: '/agency/list', name: 'ข้อมูล ลูกค้า/Agency' },
                ]
            },
            {   //MENU_REPORT
                state: '',
                name: 'ดาวน์โหลดข้อมูล',
                type: 'sub',
                icon: 'table',
                children: [
                    { state: '/report/order/download', name: 'ข้้อมูล Order', showCart: true, showBreadcrumb: true },
                    { state: '/report/invoice/download', name: 'ข้อมููล Invoice', showCart: true, showBreadcrumb: true },
                    { state: '/report/transaction/download', name: 'ข้อมูลการชำระเงิน', showCart: true, showBreadcrumb: true }
                ]
            }
        ],
        FAVORITE: [
            { state: '/dashboard', name: 'หน้าแรก' },
            //{ state: '/order/new-invoice-approval', name: 'อนุมัติ Invoice ({total})' },
        ]
    },
    ROLE_SALE: {
        ITEM: [
            MENU_SALE,
            { //MENU_FINANCE,
                state: '',
                name: 'งานบัญชี-การเงิน',
                type: 'sub',
                icon: 'money',
                children: [
                    { state: '/order/new-accounting', name: 'ข้อมูล Invoice' },
                    { state: '/order/new-send-invoice', name: 'ส่งเมล Invoice' },
                    { state: '/order/payment-list', name: 'ข้อมูล Payment' },
                    { state: '/order/transaction-list', name: 'ข้อมูลการรับเงิน' }
                ]
            },
            {   //MENU_PRODUCT,
                state: '',
                name: 'จัดการข้อมูลสินค้า',
                type: 'sub',
                icon: 'suitcase',
                children: [
                    { state: '/v2/product/management', name: 'จัดการข้อมูลสินค้า', outsideLink: true },
                    { state: '/product/join-tour', name: 'ข้อมูล Join Tour' },
                    { state: '/product/package-tour', name: 'ข้อมูล Package Tour' },
                    { state: '/sale/config-channel', name: 'เปิดขายสินค้า' },
                    // { state: '/promotion/join-tour', name: 'จัดการโปรโมชั่น' },
                ]
            },
            { //MENU_AGENCY,
                state: '',
                name: 'ลูกค้า/Agency',
                type: 'sub',
                icon: 'users',
                children: [
                    { state: '/agency/list', name: 'ข้อมูล ลูกค้า/Agency' },
                    { state: '/agency/add', name: 'เพิ่ม Agency' },
                    { state: '/agency/member/list', name: 'สมาชิก Online Booking' },
                ]
            },
        ],
        FAVORITE: [
            { state: '/dashboard', name: 'หน้าแรก' },
        ]
    },
    ROLE_SALE_MNGR: {
        ITEM: [
            MENU_SALE,
            {   //MENU_FINANCE
                state: '',
                name: 'งานบัญชี-การเงิน',
                type: 'sub',
                icon: 'money',
                children: [
                    { state: '/order/new-accounting', name: 'ข้อมูล Invoice' },
                    { state: '/order/new-send-invoice', name: 'ส่งเมล Invoice' },
                    { state: '/order/payment-list', name: 'ข้อมูล Payment' },
                    { state: '/order/transaction-list', name: 'ข้อมูลการรับเงิน' }
                ]
            },
            MENU_PRODUCT,
            MENU_AGENCY,
            MENU_REPORT
        ],
        FAVORITE: [
            { state: '/dashboard', name: 'หน้าแรก' },
        ]
    },
    ROLE_DIRECTOR: {
        ITEM: [
            MENU_SALE,
            MENU_FINANCE,
            MENU_PRODUCT,
            MENU_AGENCY,
            MENU_REPORT
        ],
        FAVORITE: [
            { state: '/dashboard', name: 'หน้าแรก' },
            //{ state: '/order/new-invoice-approval', name: 'อนุมัติ Invoice ({total})' },
        ]
    },
    ROLE_ADMIN: {
        ITEM: [
            MENU_SALE,
            MENU_FINANCE,
            MENU_PRODUCT,
            MENU_AGENCY,
            MENU_REPORT
        ],
        FAVORITE: [
            { state: '/dashboard', name: 'หน้าแรก' },
            //{ state: '/order/new-invoice-approval', name: 'อนุมัติ Invoice ({total})' },
        ]
    },
};
