import * as moment from "moment";

import {ThaiMonthDatePipe, TourWowProThaiDateRangePipe} from 'app/pipes/thaidate.pipe';

import { DecimalPipe } from "@angular/common";
import { ExcelService } from "app/services/excel.service";
import { INVOICE_STATUS } from 'app/constants/payment.constant';
import { Injectable } from "@angular/core";
import { OrderStatusPipe } from "app/pipes/status.pipe";

@Injectable()
export class OrderListAsExcelService {

    private _excelCellStyle: any;
    private _excelColumnsOption: any;
    private _orderStatusPipe = new OrderStatusPipe();

    constructor(
        private _excelService: ExcelService,
        private _thaiMonthDatePipe: ThaiMonthDatePipe,
        private _decimalPipe: DecimalPipe,
        private _thaiDateRange: TourWowProThaiDateRangePipe
    ) { }


    start(orders: any, form: any, total: any): void {
        this._excelService.FONT_NAME = 'Tahoma';
        this._excelCellStyle = this._excelService.getCellStyleCollection();
        this._excelColumnsOption = this.getExcelColumnsOption();

        const orderList = orders.data.orders;
        let json = [];
        const title = {
            A: `สรุปรายการจองของวันที่ ${this._thaiMonthDatePipe.transform(
                form.created_start_at,
                true,
                false
            )} - ${this._thaiMonthDatePipe.transform(
                form.created_end_at,
                true,
                false
            )} จำนวน ${total} รายการ`
        };
        const header = {
            A: "ลำดับ",
            B: "รหัสรายการจอง",
            C: "สถานะ",
            D: "รหัสโปรแกรมทัวร์",
            E: "ชื่อโปรแกรมทัวร์",
            F: "ช่วงเวลาเดินทาง",
            G: "วันไป",
            H: "วันกลับ",
            I: "ประเภททัวร์",
            J: "ประเทศ",
            K: "จำนวนผู้เดินทาง",
            L: "ผู้ใหญ่ (พักคู่)",
            M: "ผู้ใหญ่ (พักเดี่ยว)",
            N: "ผู้ใหญ่ (พักสาม)",
            O: "เด็กมีเตียง",
            P: "เด็กไม่มีเตียง",
            Q: "ทารก",
            R: "ราคาผู้ใหญ่ (พักคู่)/คน",
            S: "ราคาผู้ใหญ่ (พักเดี่ยว)/คน",
            T: "ราคาผู้ใหญ่ (พักสาม)/คน",
            U: "ราคาเด็กมีเตียง/คน",
            V: "ราคาเด็กไม่มีเตียง/คน",
            W: "ราคาทารก/คน",
            X: "ราคารวม",
            Y: "คอมบริษัท/คน",
            Z: "คอมเซลล์/คน",
            AA: "คอมบริษัทรวม",
            AB: "คอมเซลล์รวม",
            AC: "ผู้ทำรายการจอง",
            AD: "ชื่อ Agency/ผู้จอง",
            AE: "ชื่อ - สกุล (ชื่อเล่น) เซลล์ของ Agency",
            AF: "เบอร์โทร เซลล์ของ Agency",
            AG: "อีเมล เซลล์ของ Agency",
            AH: "วันที่จอง",
            AI: "เวลาที่จอง",
            AJ: "วัน เวลาที่จอง",
            AK: "หมายเหตุ/ข้อมูลเพิ่มเติม",
            AL: "วันและเวลาที่ยกเลิก",
            AM: "เหตุผลการยกเลิก",
            AN: "ยกเลิกโดย",
            AO: "รหัส Invoice",
            AP: "งวด 1",
            AQ: "งวด 2",
        };
        json.push(title);
        json.push(header);
        orderList.forEach((order: any, index: number) => {
            const row = {
                A: index + 1 + "",
                B: order.code,
                C: INVOICE_STATUS[order.payment.invoice_status_code],
                D: order.program.tour_code,
                E: order.program.name,
                F: `${this.transformPeriodDate(order.program.period)}`,
                G: this._thaiMonthDatePipe.transform(
                    order.program.period.start_at,
                    true,
                    false
                ),
                H: this._thaiMonthDatePipe.transform(
                    order.program.period.end_at,
                    true,
                    false
                ),
                I: order.program.sub_product.name,
                J: this.renderCountriesCellText(order),
                K: order.quantity + "",
                L: this.renderCellTextIfNotEmpty(order.quantity_adult_double),
                M: this.renderCellTextIfNotEmpty(order.quantity_adult_single),
                N: this.renderCellTextIfNotEmpty(order.quantity_adult_triple),
                O: this.renderCellTextIfNotEmpty(order.quantity_child_bed),
                P: this.renderCellTextIfNotEmpty(order.quantity_child_no_bed),
                Q: this.renderCellTextIfNotEmpty(order.quantity_infant),
                R: this.renderCellTextIfNotEmpty(
                    this._decimalPipe.transform(order.price_adult_double)
                ),
                S: this.renderCellTextIfNotEmpty(
                    this._decimalPipe.transform(order.price_adult_single)
                ),
                T: this.renderCellTextIfNotEmpty(
                    this._decimalPipe.transform(order.price_adult_triple)
                ),
                U: this.renderCellTextIfNotEmpty(
                    this._decimalPipe.transform(order.price_child_bed)
                ),
                V: this.renderCellTextIfNotEmpty(
                    this._decimalPipe.transform(order.price_child_no_bed)
                ),
                W: this.renderCellTextIfNotEmpty(
                    this._decimalPipe.transform(order.price_infant)
                ),
                X: this._decimalPipe.transform(order.total_products),
                Y: `${this._decimalPipe.transform(order.commission_company)}`,
                Z: this.renderCellTextIfNotEmpty(
                    this._decimalPipe.transform(order.commission_seller)
                ),
                AA: this._decimalPipe.transform(order.total_commission_company),
                AB: this.renderCellTextIfNotEmpty(
                    this._decimalPipe.transform(order.total_commission_seller)
                ),
                AC: `${order.seller.first_name} ${order.seller.last_name} (${
                    order.seller.nick_name
                    })`,
                AD: order.agency.company_name,
                AE: order.agency.agency_contact ? `${order.agency.agency_contact.first_name || ''} ${order.agency.agency_contact.last_name || ''} ${order.agency.agency_contact.nick_name ? `(${order.agency.agency_contact.nick_name})` : ``}` : ``,
                AF: this.renderCellTextIfNotEmpty(
                    (
                        order.agency.agency_contact
                            ? order.agency.agency_contact.tel
                            : '-'
                    )
                ),
                AG: this.renderCellTextIfNotEmpty(
                    (
                        order.agency.agency_contact
                            ? order.agency.agency_contact.email
                            : '-'
                    )
                ),
                AH: this._thaiMonthDatePipe.transform(
                    order.created.created_at,
                    true,
                    false
                ),
                AI: moment(order.created.created_at).format("HH:mm"),
                AJ: moment(order.created.created_at).format("DD/MM/YY HH:mm"),
                AK: this.renderCellTextIfNotEmpty(order.note),
                AL: this.renderCellTextIfOrderIsCancel(
                    order,
                    this._thaiMonthDatePipe.transform(
                        order.updated.updated_at,
                        true,
                        true,
                        false
                    )
                ),
                AM: this.renderCellTextIfOrderIsCancel(
                    order,
                    order.order_status_reason
                ),
                AN: this.renderCellTextIfOrderIsCancel(
                    order,
                    `${order.updated.first_name} ${order.updated.last_name} (${
                    order.updated.nick_name
                    })`
                ),
                AO: this.renderCellTextIfNotEmpty(order.payment.payment_code),
                AP: this.rendertotalPaymentInstallmentPaidCellText(order.payment.payment_installments[0]),
                AQ: this.rendertotalPaymentInstallmentPaidCellText(order.payment.payment_installments[1]),
            };
            json.push(row);
        });

        this._excelService.createWorksheet(json);
        this.adjustExcelLayout();
        this._excelService.exportAsExcelFile("orders");

    }

    private renderCountriesCellText(order: any): any {
        return order &&
            order.program &&
            order.program.countries &&
            order.program.countries.length
            ? order.program.countries.map(country => country.name_th).join(", ")
            : "-";
    }

    private renderCellTextIfNotEmpty(cellText: any): any {
        return cellText ? cellText + "" : "-";
    }

    private renderCellTextIfOrderIsCancel(order: any, cellText: any): any {
        const isOrderCancel = order.order_status_code === 24;
        return isOrderCancel ? this.renderCellTextIfNotEmpty(cellText) : "-";
    }

    private rendertotalPaymentInstallmentPaidCellText(object: any): any {
        let result;
        if(object) {
            result = this._decimalPipe.transform(object['total_paid']) || null;
        }
        return this.renderCellTextIfNotEmpty(result);
    }

    private rendertotalPaymentInstallmentStatusCellText(object: any): any {
        let result;
        if(object) {
            result = object['installmentStatusTextTh'] || null;
        }
        return this.renderCellTextIfNotEmpty(result);
    }

    private adjustExcelLayout(): void {
        Object.keys(this._excelService.worksheet).forEach((key: string) => {
            // not cell object
            if (key === "!ref") return true;
            this.setExcelColumnWidth(key);
            this.setExcelCellTextStyle(key);
        });
        this._excelService.worksheetCols = Object.keys(this._excelColumnsOption).map(
            x => this._excelColumnsOption[x]
        );
    }

    private setExcelColumnWidth(key: string): void {
        const cellColumnLabel = key.match(/[a-zA-Z]+/g)[0];
        if (key === "A1") return;
        const textLength = this._excelService.worksheet[key].v
            ? (this._excelService.worksheet[key].v + "").length
            : 0;
        const currentColumnWidth = this._excelColumnsOption[cellColumnLabel].wch;
        if (currentColumnWidth < textLength)
            this._excelColumnsOption[cellColumnLabel].wch = textLength;
    }

    private setExcelCellTextStyle(key: string): void {
        if (key === "A1") {
            // sheet title
            this._excelService.setCellStyle(
                this._excelService.worksheet[key],
                this._excelCellStyle.headerBoderStyle
            );
        } else {
            const cellRowNumber = key.match(/\d+/g)[0];
            if (cellRowNumber === "2") {
                // table title
                this._excelService.setCellStyle(
                    this._excelService.worksheet[key],
                    this._excelCellStyle.headerBoderStyle
                );
            } else {
                const cellColumnLabel = key.match(/[a-zA-Z]+/g)[0];
                if (cellColumnLabel === "C") {
                    // order status cell
                    /*
                    this._excelService.setCellStyle(
                        this._excelService.worksheet[key],
                        this.getOrderStatusStyleAndTransformToText(key)
                    );
                    */
                } else {
                    // general cell
                    this._excelService.setCellStyle(
                        this._excelService.worksheet[key],
                        this._excelCellStyle.cellBorderStyle
                    );
                }
            }
        }
    }

    private getOrderStatusStyleAndTransformToText(key: string): any {
        let orderStatusStyle = {};
        const orderStatusNumber = this._excelService.worksheet[key].v;
        this._excelService.worksheet[key].v = this._orderStatusPipe.transform(
            this._excelService.worksheet[key].v
        );
        this._excelService.worksheet[key].t = "s";
        if (orderStatusNumber === 11)
            orderStatusStyle = this._excelCellStyle.cellBorderStyle;
        else orderStatusStyle = this._excelCellStyle.cellBorderStyleWithFontRed;

        return orderStatusStyle;
    }

    private getExcelColumnsOption(): any {
        return {
            A: { wch: 10 },
            B: { wch: 20 },
            C: { wch: 10 },
            D: { wch: 10 },
            E: { wch: 10 },
            F: { wch: 10 },
            G: { wch: 10 },
            H: { wch: 10 },
            I: { wch: 10 },
            J: { wch: 10 },
            K: { wch: 10 },
            L: { wch: 10 },
            M: { wch: 10 },
            N: { wch: 10 },
            O: { wch: 10 },
            P: { wch: 10 },
            Q: { wch: 10 },
            R: { wch: 10 },
            S: { wch: 10 },
            T: { wch: 10 },
            U: { wch: 10 },
            V: { wch: 10 },
            W: { wch: 10 },
            X: { wch: 10 },
            Y: { wch: 10 },
            Z: { wch: 10 },
            AA: { wch: 10 },
            AB: { wch: 10 },
            AC: { wch: 10 },
            AD: { wch: 10 },
            AE: { wch: 10 },
            AF: { wch: 10 },
            AG: { wch: 10 },
            AH: { wch: 10 },
            AI: { wch: 10 },
            AJ: { wch: 10 },
            AK: { wch: 10 },
            AL: { wch: 10 },
            AM: { wch: 10 },
            AN: { wch: 10 },
            AO: { wch: 10 },
            AP: { wch: 10 },
            AQ: { wch: 10 },
            AR: { wch: 10 },
            AS: { wch: 10 },
            AT: { wch: 10 },
            AU: { wch: 10 },
            AV: { wch: 10 },
            AW: { wch: 10 }
        };
    }

  transformPeriodDate(period) {
    return this._thaiDateRange.transform(period.start_at, period.end_at);
  }

}
