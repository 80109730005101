import { Injectable } from '@angular/core';
import { ListItem } from 'app/shared/models/list-item';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable()
export class HeaderProductSearchService {

  // settings
  private _templateSettings$ = new Subject<IHeaderProductSearchTemplateSettings>();
  public templateSettings$ = this._templateSettings$.asObservable();

  // datasouces (view)
  private _countriesChanged$ = new BehaviorSubject<ListItem[]>([]);
  private _programsChanged$ = new BehaviorSubject<ListItem[]>([]);
  public countriesChanged$ = this._countriesChanged$.asObservable();
  public programsChanged$ = this._programsChanged$.asObservable();

  // broadcaster (output)
  private _countrySelected$ = new Subject<string>();
  private _programSelected$ = new Subject<string>();
  public countrySelected$ = this._countrySelected$.asObservable();
  public programSelected$ = this._programSelected$.asObservable();

  // search result label
  private _searchResultLabel$ = new Subject<string>();
  public searchResultLabel$ = this._searchResultLabel$.asObservable();

  private _countries: ListItem[];
  private _programs: ListItem[];

  private _selectedCountry: ListItem;
  private _selectedProgram: ListItem;

  constructor() { }

  public addCountries(listView: ListItem[]): void {
    let preValue = new ListItem().deserialize({ id: null, name: 'ทุกประเทศ' });
    this._countries = [preValue].concat(listView);
    this._countriesChanged$.next(this._countries.slice());
  }

  public addPrograms(listView: ListItem[]): void {
    let preValue = new ListItem().deserialize({ id: null, name: `โปรแกรมทัวร์${this._selectedCountry.id ? this._selectedCountry.name : ''}ทั้งหมด` });
    this._programs = [preValue].concat(listView);
    this._programsChanged$.next(this._programs.slice());
  }

  public setTemplate(settings: IHeaderProductSearchTemplateSettings): void {
    this._templateSettings$.next(settings);
  }

  public broadcastCountryId(country: ListItem): void {
    this._selectedCountry = country;
    this._selectedProgram = null;
    this._countrySelected$.next(country.id + '');
    this._broadcastSearchResultLabel();
  }

  public broadcastProgramId(program: ListItem): void {
    this._selectedProgram = program;
    this._programSelected$.next(program.id + '');
    this._broadcastSearchResultLabel();
  }

  public broadcastSearchResultLabel(): void {
    this._selectedCountry = null;
    this._selectedProgram = null;
    this._broadcastSearchResultLabel();
  }

  private _broadcastSearchResultLabel(): void {
    this._searchResultLabel$.next(
      `จากเงื่อนไขการค้นหา ทัวร์${this._selectedCountry ? this._selectedCountry.name : 'ทุกประเทศ'} ${this._selectedProgram ? this._selectedProgram.name : ''}`
    );
  }

}

export interface IHeaderProductSearchTemplateSettings {
  searchLabelName?: string;
}