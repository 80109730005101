import { Injectable } from '@angular/core';
import { HttpProService } from 'app/services/base/http-pro.service';
import { Observable } from 'rxjs';
import { ENDPOINTS } from 'app/variables/endpoints';

@Injectable()
export class PaymentInvoiceStatService {
    constructor(private http: HttpProService) {}

    get(): Observable<any> {
        return this.http.get(ENDPOINTS.PAYMENT.INVOICE.STAT.GET);
    }

    getDaily(filters: any): Observable<any> {
        const queries = this.http.getQueryString(filters);
        return this.http.get(ENDPOINTS.PAYMENT.INVOICE.STAT.DAILY.GET + '?' + queries);
    }

}
