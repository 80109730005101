import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Component, OnDestroy, OnInit, ViewChild, ViewEncapsulation, AfterViewInit } from '@angular/core';
import { MediaChange, ObservableMedia } from '@angular/flex-layout';
import { DecimalPipe } from '@angular/common';
import { Subscription } from 'rxjs/Subscription';

import { AuthService } from 'app/services/auth.service';
import { CartService } from 'app/core/services/cart.service';
import { MenuItems } from '../core/menu/menu-items/menu-items';
import { PageTitleService } from '../core/page-title/page-title.service';
import { DashboardService } from 'app/services/dashboard.service';
import { LoadingService } from 'app/core/services/loading.service';
import { Wholesale } from 'app/shared/models/wholesale';
import { environment } from 'environments/environment';

@Component({
    selector: 'force-layout',
    templateUrl: './main-material.html',
    styleUrls: ['./main-material.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class MainComponent implements OnInit, OnDestroy, AfterViewInit {
    public version = environment.appVersion;

    // theme configurations
    header: string;
    dark: boolean;
    boxed: boolean;
    collapseSidebar: boolean;
    compactSidebar: boolean;
    customizerIn = false;
    root = 'ltr';
    isMobile = false;
    sidenavOpen = true;
    sidenavMode = 'side';
    @ViewChild('sidenav') sidenav;

    mainMenus: any[];
    favoriteMenus: any[];
    private _mediaSubscription: Subscription;
    private _routerEventsSubscription: Subscription;

    public isShowCart: boolean;
    public isShowProductSelect: boolean;
    public isShowProductSearch: boolean;
    public userProfile: Wholesale;

    public get username(): string {
        return this.userProfile && this.userProfile.supplier
            ? this.userProfile.supplier.name_th + ' (' + this.userProfile.nick_name + ')'
            : '';
    }

    constructor(
        private _menuItems: MenuItems,
        private _pageTitleService: PageTitleService,
        private _router: Router,
        private _media: ObservableMedia,
        private _authService: AuthService,
        private _decimalPipe: DecimalPipe,
        private cartService: CartService,
        private activeteRouteService: ActivatedRoute,
        private _dashboardService: DashboardService,
        private _loadingService: LoadingService
    ) {}

    ngOnInit() {
        this.initialComponents();
        this.initSitenav();
        this.registerPageEvent();
        this.subscribeRouteNavigationEnd();
    }

    ngAfterViewInit(): void {
        this.setHeightToContentContainer();
    }

    ngOnDestroy() {
        if (this._mediaSubscription) {
            this._mediaSubscription.unsubscribe();
        }

        if (this._routerEventsSubscription) {
            this._routerEventsSubscription.unsubscribe();
        }
    }

    initialComponents(): void {
        this.userProfile = this._authService.getUserProfile();
        this.mainMenus = this._menuItems.getAll();
        this.favoriteMenus = this._menuItems.getFavorite();
        this.isMobile = this._media.isActive('xs') || this._media.isActive('sm');
    }

    onActivate(scrollContainer) {
        scrollContainer.scrollTop = 0;
        const scroll = document.getElementsByClassName('force-base-container')[0];
        scroll.scrollTop = 0;
    }

    private registerPageEvent(): void {
        // on title changed
        this._pageTitleService.title.subscribe((val: string) => {
            this.header = val;
        });

        // on resize the page
        this._mediaSubscription = this._media.asObservable().subscribe((change: MediaChange) => {
            this.isMobile = change.mqAlias == 'xs' || change.mqAlias == 'sm';
            this.initSitenav();
        });

        // on change route
        this._routerEventsSubscription = this._router.events.subscribe((event) => {
            if (event instanceof NavigationEnd) {
                this.setHeightToContentContainer();

                if (this.isMobile) this.sidenav.close();
            }
        });
    }

    private setHeightToContentContainer(): void {
        setTimeout(() => {
            const totalHeaderHeight =
                $('.force-header-toolbar').outerHeight() +
                $('.productSelectWrapper').outerHeight() +
                $('.productSearchWrapper').outerHeight();
            $('.force-base-container').height(`calc(100% - ${totalHeaderHeight}px)`);
        }, 100);
    }

    private initSitenav(): void {
        this.sidenavMode = this.isMobile ? 'over' : 'side';
        this.sidenavOpen = !this.isMobile;
    }

    private subscribeRouteNavigationEnd(): void {
        this._router.events
            .filter((e) => e instanceof NavigationEnd)
            .map(() => {
                let route = this.activeteRouteService.firstChild;
                let child = route;

                while (child) {
                    if (child.firstChild) {
                        child = child.firstChild;
                        route = child;
                    } else {
                        child = null;
                    }
                }

                return route;
            })
            .mergeMap((route) => route.data)
            .subscribe((data) => this.setHeaderDisplay(data));

        // Get data on first time route.
        this.getDataActivateRoute();
    }

    private getDataActivateRoute(): void {
        let route = this.activeteRouteService.firstChild;
        let child = route;

        while (child) {
            if (child.firstChild) {
                child = child.firstChild;
                route = child;
            } else {
                child = null;
            }
        }

        // Reset cart on refresh.
        this.cartService.clearCart();

        this.setHeaderDisplay(route.data['value']);
    }

    private setHeaderDisplay(data: any): void {
        const headerConfig = data['header'];
        const cartConfig = data['cart'];

        if (cartConfig === undefined || cartConfig.clear) {
            this.cartService.clearCart();
        }

        if (headerConfig === undefined) {
            this.isShowCart = false;
            this.isShowProductSelect = false;
            this.isShowProductSearch = false;
            return;
        }

        this.isShowCart = headerConfig.showCart || false;
        this.isShowProductSelect = headerConfig.isShowProductSelect || false;
        this.isShowProductSearch = headerConfig.isShowProductSearch || false;
    }

    private updateTotalOrderMenu(menuState: string, total: number): void {
        const menu = this.favoriteMenus.find((m) => m.state === menuState);
        if (menu) {
            const totalValueFormatted = this._decimalPipe.transform(total);
            menu.name = menu.name.replace('{total}', totalValueFormatted);
        }
    }
    //#endregion
}
