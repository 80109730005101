export const ROOM_TYPE = {
    SINGLE_BEDDED_ROOM: 1,
    TWIN_BEDDED_ROOM: 2,
    DOUBLE_BEDDED_ROOM: 3,
    TRIPLE_BEDDED_ROOM: 4,
    QUADRUBLE: 5
};

export const ROOM_TYPE_LIST = [
    {
        id: ROOM_TYPE.SINGLE_BEDDED_ROOM,
        name: {
            en: 'Single Bedded Room',
            th: 'พักเดี่ยว'
        },
        short_name: 'SGL'
    },
    {
        id: ROOM_TYPE.TWIN_BEDDED_ROOM,
        name: {
            en: 'Twin Bedded Room',
            th: 'พักคู่แบบเตียงเดี่ยว 2 เตียง'
        },
        short_name: 'TWN'
    },
    {
        id: ROOM_TYPE.DOUBLE_BEDDED_ROOM,
        name: {
            en: 'Double Bedded Room	',
            th: 'พักคู่แบบเตียง 1 เตียง'
        },
        short_name: 'DBL'
    },
    {
        id: ROOM_TYPE.TRIPLE_BEDDED_ROOM,
        name: {
            en: 'Triple Bedded Room',
            th: 'พักสามคน'
        },
        short_name: 'TRP'
    },
    {
        id: ROOM_TYPE.QUADRUBLE,
        name: {
            en: 'Quadruple',
            th: 'พัก 4 คน ที่เป็นเกสเฮ้า'
        },
        short_name: 'QUAD'
    }
];
