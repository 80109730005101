import { Injectable } from "@angular/core";
import { HttpProService } from "./base/http-pro.service";
import { Observable } from "rxjs/Observable";
import { ENDPOINTS } from "app/variables/endpoints";
import { IPromotionConsumer } from "app/shared/models/promotion";

@Injectable()
export class PromotionService {

  constructor(
    private _httpProService: HttpProService  
  ) {}

  get(id: string): Observable<any> {
    let url = ENDPOINTS.PROMOTION.GET.replace('{id}', id);
    return this._httpProService.get(url);
  }

  post(body: IPromotionConsumer): Observable<any> {
    let url = ENDPOINTS.PROMOTION.POST;
    return this._httpProService.post(url, body);
  }

  put(id: string, body: IPromotionConsumer): Observable<any> {
    let url = ENDPOINTS.PROMOTION.PUT.UPDATE.replace('{id}', id);
    return this._httpProService.put(url, body);
  }

  activePromotion(id: string): Observable<any> {
    let url = ENDPOINTS.PROMOTION.PUT.ACTIVE.replace('{id}', id);
    return this._httpProService.put(url, null);
  }

  inActivePromotion(id: string): Observable<any> {
    let url = ENDPOINTS.PROMOTION.PUT.INACTIVE.replace('{id}', id);
    return this._httpProService.put(url, null);
  }

}