import { Injectable } from "@angular/core";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import * as XLSXStyle from "xlsx-style";

const EXCEL_TYPE =
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
const EXCEL_EXTENSION = ".xlsx";

@Injectable()
export class ExcelService {
  public worksheet: XLSX.WorkSheet;
  public worksheetCols: Array<XLSX.ColInfo>;
  public worksheetRows: Array<XLSX.RowInfo>;

  public mergeOption: any[];

  public FONT_NAME = "Tahoma";
  public FONT_SIZE = "10";

  constructor() {}

  public createWorksheet(json: any[]): void {
    this.worksheet = XLSX.utils.json_to_sheet(json, { skipHeader: true });
    this.worksheetCols = new Array<XLSX.ColInfo>();
    this.mergeOption = [];
  }

  public setMergeOption(startColumn: number, startRow: number, endColumn: number, endRow: number): void {
    this.mergeOption.push({
      s: { r:startRow, c:startColumn },
      e: { r:endRow, c:endColumn }
    });
  }

  public applyMergeOptionToWorksheet(): void {
    this.worksheet["!merges"] =  this.mergeOption;
  }

  public setCellStyle(cell: XLSX.CellObject, style: {}) {
    cell.s = style;
  }

  public getCellStyleCollection(): any {
    const cellBorder = {
      border: {
        top: { style: "thin", color: { auto: 1 } },
        bottom: { style: "thin", color: { auto: 1 } },
        left: { style: "thin", color: { auto: 1 } },
        right: { style: "thin", color: { auto: 1 } }
      }
    };

    const cellNormal = {
      font: { name: this.FONT_NAME, sz: this.FONT_SIZE }
    };
    const cellBold = {
      font: { name: this.FONT_NAME, sz: this.FONT_SIZE, bold: true }
    };
    const cellNormalFontRed = {
      font: { name: this.FONT_NAME, sz: this.FONT_SIZE, color: { rgb: "FFFF0000" } }
    };

    const cellFgColorRed = {
      fill: { fgColor: { rgb: "FF5869" } },
      font: { name: this.FONT_NAME, sz: this.FONT_SIZE }
    };
    const cellFgColorGreen = {
      fill: { fgColor: { rgb: "79DA7C" } },
      font: { name: this.FONT_NAME, sz: this.FONT_SIZE }
    };
    const cellFgColorOrange = {
      fill: { fgColor: { rgb: "FFA200" } },
      font: { name: this.FONT_NAME, sz: this.FONT_SIZE }
    };

    return {
      titleStyle: { font: { name: this.FONT_NAME, sz: this.FONT_SIZE, bold: true } },
      cellFgColorRed: Object.assign(cellFgColorRed, cellBorder),
      cellFgColorGreen: Object.assign(cellFgColorGreen, cellBorder),
      cellFgColorOrange: Object.assign(cellFgColorOrange, cellBorder),
      headerBoderStyle: Object.assign(cellBold, cellBorder),
      cellBorderStyle: Object.assign(cellNormal, cellBorder),
      cellBorderStyleWithFontRed: Object.assign(cellNormalFontRed, cellBorder)
    };
  }

  public exportAsExcelFile(excelFileName: string): void {
    if (this.worksheetCols && this.worksheetCols.length) {
        this.worksheet['!cols'] = this.worksheetCols;
    }

    if (this.worksheetRows && this.worksheetRows.length) {
        this.worksheet['!rows'] = this.worksheetRows;
    }


    const workbook: XLSX.WorkBook = {
      Sheets: { data: this.worksheet },
      SheetNames: ["data"]
    };
    const excelBuffer: any = XLSXStyle.write(workbook, {
      bookType: "xlsx",
      type: "buffer"
    });
    this.saveAsExcelFile(excelBuffer, excelFileName);
  }

  public deSerializeToDataRowAsKeyValue(datarow: string[]): any {
    let dataRowResult: any = {};
    let currentColumnIndex = 0;

    const totalAlphabets: number = 24; // A - Z
    const totalColumns: number = datarow.length;
    const totalLoopPerAlphabetSet: number = Math.ceil(Math.max(0, totalColumns / totalAlphabets));
    for (let timeOfRecursive = 0; timeOfRecursive <= totalLoopPerAlphabetSet; timeOfRecursive++) {
        if (totalColumns === currentColumnIndex) {
          break;
        }
        currentColumnIndex = this.buildDataRowOnDemand(dataRowResult, datarow, totalColumns, currentColumnIndex, timeOfRecursive);
    }

    return dataRowResult;
  }

  private buildDataRowOnDemand(dataRowResult: any, rawDatarow: string[], totalColumns: number, currentColumnIndex: number, timeOfRecursive: number): number {
    let prefix: string = '';
    const startCharCodeAt: number = 65; // A
    const endChartCodeAt: number = 90; // Z

    if (timeOfRecursive) {
        prefix = String.fromCharCode(startCharCodeAt + timeOfRecursive - 1);
    }

    for (let key = startCharCodeAt; key <= endChartCodeAt; key++) {
        if (totalColumns === currentColumnIndex) {
          break;
        }
        const dataValue = rawDatarow[currentColumnIndex];
        const alphabetKey: string = String.fromCharCode(key);
        dataRowResult[prefix + alphabetKey] = dataValue;
        currentColumnIndex++;
    }

    return currentColumnIndex;
  }

  private saveAsExcelFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], {
      type: EXCEL_TYPE
    });
    FileSaver.saveAs(
      data,
      fileName + "_export_" + new Date().getTime() + EXCEL_EXTENSION
    );
    this.disposed();
  }

  private disposed(): void {
    this.worksheet = null;
    this.worksheetCols = null;
  }
}
