import * as decode from 'jwt-decode';

import { Inject, Injectable, PLATFORM_ID } from '@angular/core';

import { isPlatformBrowser } from '@angular/common';
import { Wholesale } from 'app/shared/models/wholesale';

@Injectable()
export class AuthService {
    private jwtToken = 'jwt_token';
    constructor(@Inject(PLATFORM_ID) private platformId: Object) {}

    public saveJWTToken(token: string): void {
        if (isPlatformBrowser(this.platformId)) {
            localStorage.setItem(this.jwtToken, token);
        }
    }

    public getJWTToken(): string {
        if (isPlatformBrowser(this.platformId)) {
            return localStorage.getItem(this.jwtToken);
        }
    }

    public deleteJWTToken(): void {
        if (isPlatformBrowser(this.platformId)) {
            localStorage.removeItem(this.jwtToken);
        }
    }

    public getUserProfile(): Wholesale {
        const jwtToken = this.getJWTToken();
        const tokenPayload = jwtToken ? decode(jwtToken) : null;
        return new Wholesale().deserialize(tokenPayload);
    }

    public get isChannelOb(): boolean {
        const user = this.getUserProfile();
        return user.supplier && user.supplier.is_channel_ob === 1;
    }

    public isSale(): boolean {
        return this.hasRole('sale');
    }

    public hasRole(roleId: string): boolean {
        let hasRole = false;
        const userProfile = this.getUserProfile();
        if (userProfile.roles_slug && userProfile.roles_slug.find((item) => item == roleId)) {
            hasRole = true;
        }
        return hasRole;
    }

    public isAuthenticated(): boolean {
        const token = this.getJWTToken();
        if (token) {
            return true;
        }
        return false;
    }

    public hasAuthorized(expectedRole: string[]): boolean {
        let hasAuthorized = true;
        const userRole = this.getUserProfile().roles_slug[0];
        if (expectedRole) {
            hasAuthorized = expectedRole.indexOf(userRole) !== -1;
        }
        return hasAuthorized;
    }
}
