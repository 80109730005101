'use strict';

import { Injectable, OnInit } from '@angular/core';
import { AuthService } from 'app/services/auth.service';
import { ROLE } from 'app/constants/role.constant';
import { MENU } from 'app/constants/menu.constant';
import { InfoService } from 'app/services/info.service';

export interface ChildrenItems {
    state: string;
    name: string;
    type?: string;
    expectedRole?: string[];
}

export interface Menu {
    state: string;
    name: string;
    type: string;
    icon: string;
    children?: ChildrenItems[];
}

@Injectable()
export class MenuItems {
    private all_menu = null;
    private all_favorite = null;

    constructor(
        private _authService: AuthService,
        private _infoService: InfoService,
    ) {
        if (this._authService.hasAuthorized(ROLE.GROUP.DIRECTOR)) {
            this.all_menu = MENU.ROLE_DIRECTOR.ITEM;
            this.all_favorite = MENU.ROLE_DIRECTOR.FAVORITE;
            this.initFavoriteMenu();
        } else if (this._authService.hasAuthorized(['sale_mngr'])) {
            this.all_menu = MENU.ROLE_SALE_MNGR.ITEM;
            this.all_favorite = MENU.ROLE_SALE_MNGR.FAVORITE;
        } else if (this._authService.hasAuthorized(['fin_mngr'])) {
            this.all_menu = MENU.ROLE_FINANCE_MNGR.ITEM;
            this.all_favorite = MENU.ROLE_FINANCE_MNGR.FAVORITE;
            this.initFavoriteMenu();
        } else if (this._authService.hasAuthorized(ROLE.GROUP.SALE)) {
            this.all_menu = MENU.ROLE_SALE.ITEM;
            this.all_favorite = MENU.ROLE_SALE.FAVORITE;
        } else if (this._authService.hasAuthorized(ROLE.GROUP.FINANCE)) {
            this.all_menu = MENU.ROLE_FINANCE.ITEM;
            this.all_favorite = MENU.ROLE_FINANCE.FAVORITE;
        } else if (this._authService.hasAuthorized(ROLE.GROUP.OPT)) {
            this.all_menu = MENU.ROLE_OPT.ITEM;
            this.all_favorite = MENU.ROLE_OPT.FAVORITE;
        }

        
    }


    initFavoriteMenu() {
        this._infoService.getConfigOrder().subscribe(responses => {
            if (responses.isOk) {
                let autoApprove = responses.data.auto_approve;
                if (!autoApprove.is_auto_approve_invoice && !autoApprove.is_auto_send_invoice) {
                    this.all_favorite.push({ state: '/order/new-invoice-approval', name: 'อนุมัติ Invoice ({total})' })
                }
            }
        });
    }

    getAll(): Menu[] {

        return this.all_menu;
    }

    getFavorite(): ChildrenItems[] {
        return this.all_favorite;
    }



}
